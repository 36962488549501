function PaymentDetail({ data: orderStatus }) {
  const paymentStatus = () => {
    if (orderStatus.order_payment_detail[0].STATUS === "TXN_SUCCESS") {
      return (
        <Typography variant="h6" color="primary">
          Successful
        </Typography>
      );
    }
    if (orderStatus.order_payment_detail[0].STATUS === "TXN_FAILURE") {
      return (
        <Typography variant="h6" color="error">
          Failed
        </Typography>
      );
    }
  };

  // *********************************************************************************************************
  if (orderStatus.order_payment_detail && orderStatus.order_payment_detail.length) {
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Typography variant="p">
              <b>Payment Status</b>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            {paymentStatus()}
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Typography variant="p">
              <b>Order ID</b>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="p">
              {orderStatus.order_payment_detail[0].ORDERID}
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Typography variant="p">
              <b>Payment Mode</b>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="p">
              {orderStatus.order_payment_detail[0].PAYMENTMODE
                ? orderStatus.order_payment_detail[0].PAYMENTMODE
                : "Online"}
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Typography variant="p">
              <b>Bank Name</b>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="p">
              {orderStatus.order_payment_detail[0].BANKNAME
                ? orderStatus.order_payment_detail[0].BANKNAME
                : "Unknown"}
            </Typography>
          </Grid>
        </Grid>

        {orderStatus.order_payment_detail[0].STATUS === "TXN_FAILURE" && (
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Typography variant="p">
                <b>Rsponse Message</b>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="p">
                {orderStatus.order_payment_detail[0].RESPMSG}
              </Typography>
            </Grid>
          </Grid>
        )}
      </>
    );
  }

  return null;
}

export default PaymentDetail;
