import { Step, Stepper, StepLabel } from "@mui/material";
import { Typography } from "@material-ui/core";

// ----------------------------------------------------------------------

const steps = [
  "Received.",
  "Confirmed.",
  "Preparing",
  "Served"
  // "Cancelled"
  //   "Order has been Completed"
];

function HorizontalLinearStepper({ orderStatus }) {
  const activeStep = orderStatus.state - 1;

  if (orderStatus.state !== 5) {
    return (
      <Stepper
        activeStep={activeStep}
        style={{
          overflowX: "scroll",
          paddingBottom: "12px",
          paddingTop: ".2rem"
        }}
      >
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    );
  }
  return (
    <Typography
      style={{
        animation: "blinker 2s linear infinite"
      }}
      variant="p"
      color="error"
    >
      Order Cancelled
    </Typography>
  );
}

export default HorizontalLinearStepper;
