import * as React from "react";
import PhoneCall from "./PhoneCall";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import CssBaseline from "@mui/material/CssBaseline";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import OtherSocialMediaModal from "./OtherSocialMediaModal";

export default function BottomNavigationMenu({
  storeContactProfile,
  primaryColor
}) {
  const socialMediaDisplay = () => {
    // Menu Case index 0
    const socailMediaArray = [
      <BottomNavigationAction
        sx={{ color: `#${primaryColor}` }}
        label="Menu"
        icon={<RestaurantMenuIcon />}
      />
    ];
    for (let index = 0; index < storeContactProfile.length; index++) {
      const element = storeContactProfile[index];
      // WhatsApp Case index 1
      if (element.type === "WhatsApp") {
        const whatsappIcon = (
          <BottomNavigationAction
            label="WhatsApp"
            icon={<WhatsAppIcon />}
            onClick={() => {
              window.open(`https://wa.me/${element.url}`, "_blank");
            }}
          />
        );
        socailMediaArray.splice(1, 0, whatsappIcon);
      }

      // Phone Call Case
      if (element.type === "Phone") {
        const phoneCallIcon = (
          <BottomNavigationAction
            label="Call"
            icon={<PhoneInTalkIcon />}
            onClick={() => {
              window.open(`tel:${element.url}`, "_blank");
            }}
          />
        );
        socailMediaArray.splice(2, 0, phoneCallIcon);
      }

      // Location/Map Case index 2
      if (element.type === "Location") {
        const locationIcon = (
          <BottomNavigationAction
            label="Location"
            icon={<LocationOnIcon />}
            onClick={() => {
              window.open(`${element.url}`, "_blank");
            }}
          />
        );
        socailMediaArray.splice(3, 0, locationIcon);
      }
    }

    return socailMediaArray;
  };

  return (
    <Box sx={{ pb: 7 }}>
      <CssBaseline />
      <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
        elevation={3}
      >
        <BottomNavigation showLabels>
          {socialMediaDisplay()}
          {/* {phoneCallDisplay()} */}
          <OtherSocialMediaModal storeContactProfile={storeContactProfile} />
        </BottomNavigation>
      </Paper>
    </Box>
  );
}
