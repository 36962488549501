import clsx from "clsx";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Card, Typography, Grid, Tooltip } from "@material-ui/core";
import CartIncrement from "~/views/menu/MenuView/CartIncrement";
import placeholder_item from "../../../images/placeholder_item.png";

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    padding: ".8rem"
    // "&:hover": {
    //   boxShadow:
    //     "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px"
    // }
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    "&:not(:last-child)": {
      marginBottom: theme.spacing(2)
    }
  },
  divider: {
    marginBottom: theme.spacing(2)
  },
  discount: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    marginTop: theme.spacing(3),
    "& button": { position: "absolute", right: theme.spacing(1) }
  },
  savingsText: {
    position: "absolute",
    top: 10,
    right: ".8rem",
    color: "#FFF",
    backgroundColor: theme.palette.primary.main,
    padding: "0 .4rem .2rem .4rem",
    borderRadius: ".5rem 0",
    opacity: 1
  }
}));

function MenuCartItemTile({ cartItem, currency, onRemove, onChange }) {
  const classes = useStyles();
  return (
    <Card className={clsx(classes.root)}>
      <Grid container spacing={2}>
        <Grid item xs={8} md={9}>
          <Box
            className="lazyload blur-up"
            sx={{
              mt: 1,
              mb: 1,
              width: 128,
              height: 64,
              borderRadius: 1.5
            }}
            component="img"
            alt="item image"
            src={cartItem.images.length ? cartItem.images[0] : placeholder_item}
            data-src={
              cartItem.images.length ? cartItem.images[0] : placeholder_item
            }
          />

          <Typography variant="h5">
            {cartItem.itemLabel}
            <br />
          </Typography>

          <Typography variant="span">
            <strong>Price:</strong>&nbsp;
            {` ${currency} ${cartItem.price}`}
            <br />
          </Typography>

          {cartItem.itemSelectedConfigs.map((config, index) => {
            if (config.contents.length) {
              return (
                <Typography key={index} variant="span">
                  <strong>{config.label}:</strong>&nbsp;
                  {config.contents.toString()}
                  <br />
                </Typography>
              );
            }
          })}
        </Grid>

        <Grid item xs={4} md={3} style={{ alignSelf: "center" }}>
          {cartItem.savings === 0 ? null : (
            <Tooltip
              title={`You will save ${currency} ${cartItem.savings} on ${cartItem.itemLabel}`}
              placement="left"
            >
              <Typography
                // color="primary"
                variant="span"
                className={classes.savingsText}
              >
                Savings:&nbsp;
                {currency} {cartItem.savings.toFixed(2)}
                <br />
              </Typography>
            </Tooltip>
          )}
          <CartIncrement
            price={cartItem.price}
            quantity={cartItem.quantity}
            onRemove={onRemove}
            onChange={value => onChange(value)}
          />
          <Typography
            variant="subtitle2"
            sx={{ display: "flex", justifyContent: "center" }}
          >
            {`${currency} ${(cartItem.price * cartItem.quantity).toFixed(2)}`}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
}

export default MenuCartItemTile;
