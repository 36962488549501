import { useContext, useState } from "react";
import {
  getCartItemsFromLocalDB,
  setCartItemsInLocalDB
} from "~/services/LocalServices";
import SingleConfigAddToCart from "./SingleConfigAddToCart";
import MultiConfigAddToCart from "./MultiConfigAddToCart";
import DispatchContext from "./menuContext/dispatchContext";
import { useSnackbar } from "notistack";
import Capitalize from "~/views/common/Capitalize";

function AddToCartButton({
  category,
  item,
  profile,
  isOpen,
  isViewMode,
  isSelected,
  calculatedPrice,
  handleSheetState,
  handleAddToCart,
  handleSomeData
}) {
  const [render, setRender] = useState(false);
  const dispatch = useContext(DispatchContext);
  const { enqueueSnackbar } = useSnackbar();
  const { slug } = profile;

  const conditionForButton = () => {
    const localCartObject = getCartItemsFromLocalDB(slug);
    if (localCartObject) {
      const cartItemIds = [];
      for (let index = 0; index < localCartObject.cartItems.length; index++) {
        const element = localCartObject.cartItems[index];
        cartItemIds.push(element.itemId);
      }

      return cartItemIds.includes(item._id);
    }
    return;
  };

  const handleIncrement = () => {
    const localCartObject = getCartItemsFromLocalDB(slug);
    if (localCartObject) {
      const cartItems = localCartObject.cartItems;
      const newList = cartItems.map(element =>
        element.itemId === item._id
          ? { ...element, quantity: element.quantity + 1 }
          : element
      );
      const newCartObject = {};
      newCartObject.cartItems = newList;
      dispatch({ type: "UpdateCartLength", cartItemsList: newList });
      setCartItemsInLocalDB(slug, newCartObject);
      setRender(!render);
    }
  };

  const handleDecrement = () => {
    const localCartObject = getCartItemsFromLocalDB(slug);
    if (localCartObject) {
      const cartItems = localCartObject.cartItems;
      const newList = cartItems.map(element =>
        element.itemId === item._id
          ? { ...element, quantity: element.quantity - 1 }
          : element
      );
      const newCartObject = {};
      newCartObject.cartItems = newList;
      dispatch({ type: "UpdateCartLength", cartItemsList: newList });
      setCartItemsInLocalDB(slug, newCartObject);
      setRender(!render);
    }
  };

  const handleRemove = () => {
    const localCartObject = getCartItemsFromLocalDB(slug);
    if (localCartObject) {
      const cartItems = localCartObject.cartItems;
      const newList = cartItems.filter(element => element.itemId !== item._id);
      dispatch({ type: "UpdateCartLength", cartItemsList: newList });
      enqueueSnackbar(`${Capitalize(item.label)} removed from cart`, {
        variant: "error"
      });
      const newCartObject = {};
      newCartObject.cartItems = newList;
      setCartItemsInLocalDB(slug, newCartObject);
      setRender(!render);
    }
  };

  let needToShowButton = item.configs.length > 1;
  if (!needToShowButton) {
    needToShowButton = item.configs[0].contents.length > 1;
  }
  if (!needToShowButton) {
    if (isViewMode) {
      return null;
    }
    if (item.state === "Available") {
      return (
        <SingleConfigAddToCart
          name="quantity"
          itemId={item._id}
          slug={slug}
          conditionForButton={conditionForButton()}
          onIncrement={handleIncrement}
          onDecrement={handleDecrement}
          onRemove={handleRemove}
          handleAddCart={handleAddToCart}
        />
      );
    }
  }

  return (
    <MultiConfigAddToCart
      category={category}
      item={item}
      profile={profile}
      isOpen={isOpen}
      calculatedPrice={calculatedPrice}
      handleSheetState={handleSheetState}
      isViewMode={isViewMode}
      isSelected={isSelected}
      handleAddToCart={handleAddToCart}
      handleSomeData={handleSomeData}
    />
  );
}

export default AddToCartButton;
