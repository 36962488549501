import { useEffect, useState, useReducer, memo } from "react";
import { Helmet } from "react-helmet";
import menuReducer from "../MenuView/menuReducers/menu.reducer";
import CartAction from "../cart/CartAction";
import MenuProfile from "./MenuProfile";
import SearchBar from "./SearchBar";
import DispatchContext from "./menuContext/dispatchContext";
import SubscriptionModeContext from "./menuContext/subscriptionModeContext";
import {
  getCartItemsFromLocalDB,
  setPrimaryColorInLocalDB,
} from "~/services/LocalServices";
import MenuCategoryView from "./MenuCategoryView";
import CartDetailsBottomLine from "./CartDetailsBottomLine";
import BottomNavigationMenu from "./BottomNavigationMenu";
import ThemeConfig from "~/theme";
import PlaceFoodOrder from "~/views/placeOrder/PlaceOrderView";
import Page from "~/components/Page";
import pureVeg1 from "../../../images/pureVeg1.png";
import vegetarian from "../../../images/vegetarian.png";

function MenuChildViewNew({
  slugResponseData,
  menu,
  conf,
  iden,
  idenl,
  showCartPage,
  handleShowCart,
}) {
  const [newMenuConfig, setNewMenuConfig] = useState([]);
  const [filteredMenu, setFilteredMenu] = useState(menu);

  const { viewMode, profile } = slugResponseData;
  const storeContactProfile = slugResponseData.profile.links;
  const orderConfigs = slugResponseData.profile.orderConfigs;

  const initialCartItemsList = () => {
    let localCartObject = getCartItemsFromLocalDB(`${profile.slug}`);
    if (localCartObject) {
      return localCartObject.cartItems;
    }
    return [];
  };

  const [cartItemsList, dispatch] = useReducer(
    menuReducer,
    initialCartItemsList()
  );

  useEffect(() => {
    getMenuApiCall();
  }, []);

  const setMyMenuConfigsForMenu = () => {
    if (orderConfigs.length) {
      const menuConfigList = [];
      for (let index = 0; index < orderConfigs.length; index++) {
        const element = orderConfigs[index];
        element.iden = null;
        element.idenl = null;
        if (conf) {
          if (element.label === conf) {
            element.iden = iden;
            element.idenl = idenl;
            menuConfigList.push(element);
          }
        } else if (element.active) {
          menuConfigList.push(element);
        }
      }
      setNewMenuConfig(menuConfigList);
    }
  };

  //********************************** Menu API Call ***********************************
  const getMenuApiCall = () => {
    if (orderConfigs.length) {
      setMyMenuConfigsForMenu(menu);
    }

    setFilteredMenu(menu);
    setPrimaryColorInLocalDB("primaryColor", menu.primaryColor);
  };

  // **************************************************************************************
  const CartAndMenuDisplay = () => {
    if (showCartPage) {
      return (
        <DispatchContext.Provider value={dispatch}>
          <PlaceFoodOrder
            primaryColor={menu.primaryColor}
            placeOrder={true}
            profile={profile}
            config={newMenuConfig}
            handleShowCart={handleShowCart}
          />
        </DispatchContext.Provider>
      );
    }
    return (
      <Page>
        <SearchBar
          menu={menu}
          primaryColor={menu.primaryColor}
          handleSearch={(searchedMenu) => setFilteredMenu(searchedMenu)}
        />

        <DispatchContext.Provider value={dispatch}>
          <SubscriptionModeContext.Provider value={profile.subscriptionMode}>
            {menu.pureVeg ? (
              <span
                style={{
                  display: "flex",
                  // justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={vegetarian} width={18} /> &nbsp;&nbsp;&nbsp;
                <p style={{ color: "#999",fontSize:".9rem" }}>Pure Veg</p>
              </span>
            ) : null}
            <MenuCategoryView
              menu={menu}
              profile={profile}
              filteredMenu={filteredMenu}
              isViewMode={viewMode}
              newMenuConfig={newMenuConfig}
            />
          </SubscriptionModeContext.Provider>
        </DispatchContext.Provider>

        {viewMode ? null : (
          <CartAction
            cartLength={cartItemsList.length}
            primaryColor={menu.primaryColor}
            handleShowCart={handleShowCart}
          />
        )}

        {!viewMode ? (
          <CartDetailsBottomLine
            cartItems={cartItemsList}
            profile={profile}
            storeContactProfile={storeContactProfile}
            handleShowCart={handleShowCart}
          />
        ) : null}

        {storeContactProfile && storeContactProfile.length ? (
          <BottomNavigationMenu
            storeContactProfile={storeContactProfile}
            primaryColor={menu.primaryColor}
          />
        ) : null}
      </Page>
    );
  };
  // **************************************************************************************
  const rootStyle = {
    boxShadow: "none",
    marginTop: "5rem",
    marginBlockEnd: "3rem",
  };

  return (
    <div style={!showCartPage ? rootStyle : null}>
      <Helmet>
        <meta name="theme-color" content={`#${menu.primaryColor}`} />
      </Helmet>
      <ThemeConfig primaryColor={menu.primaryColor} darkMode={true}>
        <MenuProfile profile={profile} />

        {CartAndMenuDisplay()}
        {/* <ExampleMenuFooter /> */}
      </ThemeConfig>
    </div>
  );
}

export default memo(MenuChildViewNew);
