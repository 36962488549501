import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Skeleton, Grid, Typography } from "@material-ui/core";
import Page from "~/components/Page";
import OrderItem from "./OrderItem";
import ThemeConfig from "~/theme";
import { Helmet } from "react-helmet";
import {
  getOrdersListFromLocalDB,
  getPrimaryColorFromLocalDB,
} from "~/services/LocalServices";
import { Icon } from "@iconify/react";
import arrowBackFill from "@iconify-icons/eva/arrow-back-fill";

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: { marginBottom: "2rem" },
}));

// ----------------------------------------------------------------------

const SkeletonLoad = (
  <Grid container spacing={2}>
    {[...Array(8)].map((item, index) => (
      <Grid item xs={6} sm={3} key={index}>
        <Skeleton
          component={Box}
          variant="rectangular"
          sx={{ width: "100%", paddingTop: "115%", borderRadius: 2 }}
        />
      </Grid>
    ))}
  </Grid>
);

function OrdersList(props) {
  const classes = useStyles();
  const [ordersList, setOrdersList] = useState(null);
  const [loading, setLoading] = useState(true);
  const primaryColorinLocalDB = getPrimaryColorFromLocalDB("primaryColor");
  const primaryColor = props.location.state
    ? props.location.state.primaryColor
    : primaryColorinLocalDB
    ? primaryColorinLocalDB
    : "00AB55";

  useEffect(() => {
    const ordersListFromLocalStorage = getOrdersListFromLocalDB("ordersList");
    setOrdersList(
      ordersListFromLocalStorage ? JSON.parse(ordersListFromLocalStorage) : null
    );
    setLoading(false);
  }, []);

  if (loading) {
    return <div style={{ marginTop: "5rem" }}>{SkeletonLoad}</div>;
  }

  const orderItem = () => {
    if (!ordersList) {
      return (
        <>
          <Typography
            align="center"
            variant="h3"
            sx={{
              py: 1,
              mb: 4,
              borderRadius: "0 0 .4rem .4rem",
            }}
          >
            No Orders Found
          </Typography>
          {SkeletonLoad}
        </>
      );
    }
    return (
      <Grid container spacing={3}>
        {ordersList.reverse().map((orderItem, index) => (
          <Grid key={index} item xs={12} sm={6}>
            <OrderItem orderItem={orderItem} primaryColor={primaryColor} />
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <ThemeConfig primaryColor={primaryColor}>
      <Helmet>
        <meta name="theme-color" content={`#${primaryColor}`} />
      </Helmet>
      <Page className={classes.root}>
        <Typography
          variant="h3"
          sx={{
            py: 1,
            px: 1,
            mb: 4,
            color: "#FFF",
            backgroundColor: `#${primaryColor}`,
            borderRadius: "0 0 .4rem .4rem",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Icon
            onClick={() => {
              history.back()
            }}
            icon={arrowBackFill}
            width={24}
            height={24}
          />
          <span>Order History</span>
          <span></span>
        </Typography>
        {orderItem()}
      </Page>
    </ThemeConfig>
  );
}

export default OrdersList;
