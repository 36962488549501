import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Card, Typography, Grid } from "@material-ui/core";
import placeholder_item from "../../../images/placeholder_item.png";

const useStyles = makeStyles(theme => ({
  itemTile: {
    padding: "0 0 1rem .8rem"
  }
}));
function OrderItemTile({ orderItem, currency }) {
  const classes = useStyles();
  const { images, itemLabel, quantity, value, configs } = orderItem;
  return (
    <Grid item xs={6} md={3}>
      <Card className={clsx(classes.itemTile)}>
        <Box
          component="img"
          alt="product image"
          src={images.length ? images[0] : placeholder_item}
          data-src={images.length ? images[0] : placeholder_item}
          className="lazyload blur-up"
          sx={{
            mt: 1,
            mb: 1,
            width: 128,
            height: 64,
            borderRadius: 1.5
          }}
        />

        <Typography variant="subtitle1" sx={{ py: 1, lineHeight: 1.2 }}>
          <strong>{itemLabel}</strong>
          <br />
        </Typography>

        <Typography variant="subtitle2">
          Quantity:&nbsp;
          {quantity}
          <br />
        </Typography>

        {configs.map((config, index) => {
          if (config.contents.length) {
            return (
              <Typography key={index} variant="subtitle2">
                {config.label}:&nbsp;
                {config.contents.toString()}
                <br />
              </Typography>
            );
          }
        })}

        <Typography variant="subtitle2" color="primary">
          Amount:&nbsp;
          {currency} {value}
          <br />
        </Typography>
      </Card>
    </Grid>
  );
}
export default OrderItemTile;
