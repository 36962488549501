import clsx from "clsx";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { MButton } from "~/@material-extend";
import placeholder_item from "../../../images/placeholder_item.png";

// ----------------------------------------------------------------------

const useStyles = makeStyles(theme => ({
  logo: {
    borderRadius: ".5rem",
    width: "74px",
    height: "74px",
    objectFit: "cover"
  },
  profileContainer: {
    display: "flex"
  },
  profileDetails: {
    marginLeft: "1rem"
  },
  profileAddress: {
    lineHeight: "1.4"
  },
  viewMenuButton: {
    whiteSpace: "nowrap",
    boxShadow: "none",
    position: "absolute",
    top: "56px",
    right: "24px"
  }
}));

function ProfileDetails({ profile }) {
  const classes = useStyles();
  const { name, slug, logo } = profile;
  const { street, city } = profile.address;

  return (
    <>
      <div className={classes.profileContainer}>
        <img
          src={logo ? logo : placeholder_item}
          className={clsx(classes.logo, "lazyload blur-up")}
        />
        <div className={classes.profileDetails}>
          <Typography variant="h5">{name}</Typography>
          <Typography variant="body2" className={classes.profileAddress}>
            {street}
            <br />
            {city}
          </Typography>
        </div>
      </div>
      <MButton
        className={classes.viewMenuButton}
        onClick={() => {
          window.open(`${slug}`, "_blank");
        }}
        type="button"
        color="primary"
        size="small"
      >
        View Menu
      </MButton>
    </>
  );
}

export default ProfileDetails;
