import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Slide from "@material-ui/core/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ReturnRefundPolicy({ menuSlug, profile }) {
  const [open, setOpen] = React.useState(false);
  const { legalName } = profile;
  const { street, city, pinCode, state, country } = profile.address;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button onClick={handleClickOpen}>
        Refund & Cancellation/Return Policy
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
      >
        <DialogContent>
          <div title="English" style={{ opacity: 0.7 }}>
            <div className="row">
              <div className="col-12">
                <div>
                  <h1>Return and Refund Policy</h1>
                  <p>Last updated: February 22, 2022</p>
                  <p>{`Thank you for shopping at ${legalName}.`}</p>
                  <p>
                    If, for any reason, You are not completely satisfied with a
                    purchase We invite You to review our policy on refunds and
                    returns.
                  </p>
                  <p>
                    The following terms are applicable for any product(s) that
                    You purchased with Us.
                  </p>
                  <h2>Interpretation and Definitions</h2>
                  <h2>Interpretation</h2>
                  <p>
                    The words of which the initial letter is capitalized have
                    meanings defined under the following conditions. The
                    following definitions shall have the same meaning regardless
                    of whether they appear in singular or in plural.
                  </p>
                  <h2>Definitions</h2>
                  <p>For the purposes of this Return and Refund Policy:</p>
                  <ul>
                    <li>
                      <p>
                        <strong>Company</strong> (referred to as either "the
                        Company", "We", "Us" or "Our" in this Agreement) refers
                        to
                        <br />
                        {legalName}
                        <br />
                        {street}
                        <br /> {city}, {pinCode}, {state}, {country}.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Goods</strong> refer to the items offered for
                        sale on the Service.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Orders</strong> mean a request by You to
                        purchase Goods from Us.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Service</strong> refers to the Website.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Website</strong> refers to Food Order Online,
                        accessible from{" "}
                        <a
                          href={`https://food-order.in/${menuSlug}`}
                          rel="external nofollow noopener"
                          target="_blank"
                        >
                          {`https://food-order.in/${menuSlug}`}
                        </a>
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>You</strong> means the individual accessing or
                        using the Service, or the company, or other legal entity
                        on behalf of which such individual is accessing or using
                        the Service, as applicable.
                      </p>
                    </li>
                  </ul>
                  <h2>Your Order Cancellation Rights</h2>
                  <p>
                    You are entitled to cancel Your Order within 7 days without
                    giving any reason for doing so.
                  </p>
                  <p>
                    The deadline for cancelling an Order is 7 days from the date
                    on which You received the Goods or on which a third party
                    you have appointed, who is not the carrier, takes possession
                    of the product delivered.
                  </p>
                  <p>
                    In order to exercise Your right of cancellation, You must
                    inform Us of your decision by means of a clear statement.
                  </p>

                  <p>
                    We will reimburse You no later than 14 days from the day on
                    which We receive the returned Goods. We will use the same
                    means of payment as You used for the Order, and You will not
                    incur any fees for such reimbursement.
                  </p>
                  <h2>Conditions for Returns</h2>
                  <p>
                    In order for the Goods to be eligible for a return, please
                    make sure that:
                  </p>
                  <ul>
                    <li>The Goods were purchased in the last 7 days</li>
                    <li>The Goods are in the original packaging</li>
                  </ul>
                  <p>The following Goods cannot be returned:</p>
                  <ul>
                    <li>
                      The supply of Goods made to Your specifications or clearly
                      personalized.
                    </li>
                    <li>
                      The supply of Goods which according to their nature are
                      not suitable to be returned, deteriorate rapidly or where
                      the date of expiry is over.
                    </li>
                    <li>
                      The supply of Goods which are not suitable for return due
                      to health protection or hygiene reasons and were unsealed
                      after delivery.
                    </li>
                    <li>
                      The supply of Goods which are, after delivery, according
                      to their nature, inseparably mixed with other items.
                    </li>
                  </ul>
                  <p>
                    We reserve the right to refuse returns of any merchandise
                    that does not meet the above return conditions in our sole
                    discretion.
                  </p>
                  <p>
                    Only regular priced Goods may be refunded. Unfortunately,
                    Goods on sale cannot be refunded. This exclusion may not
                    apply to You if it is not permitted by applicable law.
                  </p>
                  <h2>Returning Goods</h2>
                  <p>
                    You are responsible for the cost and risk of returning the
                    Goods to Us. You should send the Goods at the following
                    address:
                  </p>
                  <p>
                    {legalName}
                    <br />
                    {street}
                    <br /> {city}, {pinCode}, {state}, {country}.
                  </p>
                  <p>
                    We cannot be held responsible for Goods damaged or lost in
                    return shipment. Therefore, We recommend an insured and
                    trackable mail service. We are unable to issue a refund
                    without actual receipt of the Goods or proof of received
                    return delivery.
                  </p>
                  <h2>Gifts</h2>
                  <p>
                    If the Goods were marked as a gift when purchased and then
                    shipped directly to you, You'll receive a gift credit for
                    the value of your return. Once the returned product is
                    received, a gift certificate will be mailed to You.
                  </p>
                  <p>
                    If the Goods weren't marked as a gift when purchased, or the
                    gift giver had the Order shipped to themselves to give it to
                    You later, We will send the refund to the gift giver.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="error">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default ReturnRefundPolicy;
