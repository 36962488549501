import clsx from "clsx";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import veg from "../../../images/veg.png";
import nonveg from "../../../images/nonveg.png";

// ----------------------------------------------------------------------

const useStyles = makeStyles(theme => ({
  itemsList: {},
  itemTile: {
    display: "flex",
    alignItems: "center",
    lineHeight: 2
  },
  itemConfig: {
    lineHeight: "0.4",
    marginLeft: "1.7rem",
    marginBottom: ".7rem"
  },
  greySubheading: {
    opacity: 0.6
  },
  placeHolderDiv: {
    width: "22px"
  }
}));

// ----------------------------------------------------------------------

function ItemTile({ itemsList }) {
  const classes = useStyles();
  return (
    <div className={classes.itemsList}>
      {itemsList.map(item => (
        <React.Fragment key={item._id}>
          <Typography variant="body1" className={classes.itemTile}>
            {/* {item.item.veg ? (
              <img
                src={item.item.veg ? veg : nonveg}
                width={22}
                height={22}
                style={{ borderRadius: "5px", marginTop: "3px" }}
              />
            ) : (
            <div className={classes.placeHolderDiv}></div>
            )} */}
            <span className={classes.placeHolderDiv}></span>
            &nbsp;
            <span className={classes.greySubheading}>
              {item.quantity}&nbsp;x
            </span>
            &nbsp;
            {item.itemLabel}&nbsp;
          </Typography>

          {item.configs.map((config, index) => {
            if (config.contents.length) {
              return (
                <Typography
                  key={index}
                  variant="subtitle2"
                  className={clsx(classes.itemConfig, classes.greySubheading)}
                >
                  {config.label}:&nbsp;
                  {config.contents.toString()}
                  <br />
                </Typography>
              );
            }
          })}
        </React.Fragment>
      ))}
    </div>
  );
}

export default ItemTile;
