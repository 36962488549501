import { useState, useContext, memo, useEffect } from "react";
import { useSnackbar } from "notistack";

// Internal Imports
import Capitalize from "~/views/common/Capitalize";
import DispatchContext from "./menuContext/dispatchContext";
import {
  setCartItemsInLocalDB,
  getCartItemsFromLocalDB,
} from "~/services/LocalServices";
import MenuItem from "./MenuItem";

function MenuItemTile({ category, item, isViewMode, profile, index }) {
  const [isOpen, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useContext(DispatchContext);
  const [render, setRender] = useState(false);
  const [someData, setSomeData] = useState({});
  const { slug } = profile;

  // *****************************************************************************************************
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  // *****************************************************************************************************

  const handleSheetState = (value) => {
    setOpen(value);
  };
  // *****************************************************************************************************

  const handleSomeData = (value) => {
    setSomeData(value);
  };
  // *****************************************************************************************************

  const storeCartItemsInLocalDB = (itemObjectData) => {
    const localCartObject = getCartItemsFromLocalDB(slug);
    if (localCartObject) {
      localCartObject.cartItems.push(itemObjectData);
      localCartObject.timestamp = new Date().getTime();
      dispatch({
        type: "UpdateCartLength",
        cartItemsList: localCartObject.cartItems,
      });
      setCartItemsInLocalDB(slug, localCartObject);
      return;
    }
    const newLocalCartObject = {
      cartItems: [itemObjectData],
      timestamp: new Date().getTime(),
    };
    dispatch({
      type: "UpdateCartLength",
      cartItemsList: newLocalCartObject.cartItems,
    });
    setCartItemsInLocalDB(slug, newLocalCartObject);
  };

  // *******************************Adding Items to LocalStorage*******************************

  const handleAddToCart = (quantity) => {
    setOpen(false);
    setRender(!render);

    const itemObjectData = {
      itemId: item._id,
      itemLabel: item.label,
      value: getCalculatedPrice().toFixed(2),
      savings: Number(getSavings()),
      quantity: quantity,
      images: item.images,
      itemSelectedConfigs: itemConfigsForCart(),
      itemTaxes: item.taxes,
      // For Calculation Purpose
      // selectedContents: getConfigContent(),
      itemDiscount: item.discount,
      categoryDiscount: category.discount,
      price: getCalculatedPrice().toFixed(2),
    };

    enqueueSnackbar(`${Capitalize(itemObjectData.itemLabel)} added to cart`, {
      variant: "info",
    });
    storeCartItemsInLocalDB(itemObjectData);
  };

  // *****************************************************************************************************

  const itemConfigsForCart = () => {
    const configsArray = [];
    for (let index = 0; index < item.configs.length; index++) {
      const config = item.configs[index];
      const contentsArray = [];
      for (let index = 0; index < config.contents.length; index++) {
        const content = config.contents[index];
        if (content.added) {
          contentsArray.push(content.label);
        }
      }
      configsArray.push({
        label: config.label,
        contents: contentsArray,
      });
    }
    return configsArray;
  };

  // *****************************************************************************************************

  const getBasePrice = () => {
    let valueBeforeDiscounts = 0;
    for (var i = 0; i < item.configs.length; i++) {
      const configgg = item.configs[i];
      for (var y = 0; y < configgg.contents.length; y++) {
        const content = configgg.contents[y];
        if (isSelected(content) && content.added) {
          valueBeforeDiscounts += Number(content.value);
        }
      }
    }
    return Number(valueBeforeDiscounts);
  };
  // *****************************************************************************************************

  const getCalculatedPrice = () => {
    let value = getBasePrice();
    if (category.discount !== 0) {
      value = value - (value * category.discount) / 100;
    }
    if (item.discount !== 0) {
      value = value - (value * item.discount) / 100;
    }
    if (value === 0) {
      return 0.0;
    }
    return Number(value);
  };

  // *****************************************************************************************************
  const getSavings = () => getBasePrice() - getCalculatedPrice();

  // *****************************************************************************************************

  const isSelected = (content) => {
    if (typeof content.added === "undefined") {
      return false;
    }
    return content.added;
  };
  // *****************************************************************************************************

  return (
    <MenuItem
      index={index}
      category={category}
      item={item}
      profile={profile}
      isOpen={isOpen}
      isViewMode={isViewMode}
      isSelected={isSelected}
      itemBasePrice={getBasePrice()}
      handleSheetState={handleSheetState}
      calculatedPrice={getCalculatedPrice()}
      handleAddToCart={handleAddToCart}
      handleSomeData={handleSomeData}
    />
  );
}

export default memo(MenuItemTile);
