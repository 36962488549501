import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Divider } from "@material-ui/core";
import ProfileDetails from "./ProfileDetails";
import ItemTile from "./ItemTile";
import DateSection from "./DateSection";
import ViewAndRepeatOrder from "./ViewAndRepeatOrder";

// ----------------------------------------------------------------------

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    padding: ".5rem",
    borderRadius: ".5rem",
    boxShadow:
      "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px"
    // "&:hover": {}
  },
  divider: {
    marginTop: ".5rem",
    marginBottom: ".5rem"
  }
}));

// ----------------------------------------------------------------------

function OrderItem({ orderItem, primaryColor }) {
  const classes = useStyles();
  const { profile, itemsList, config } = orderItem;

  return (
    <Card className={classes.root}>
      <ProfileDetails profile={profile} />
      <Divider className={classes.divider} />
      <ItemTile itemsList={itemsList} />
      <Divider className={classes.divider} />
      <DateSection orderItem={orderItem} />
      <ViewAndRepeatOrder
        slug={orderItem.orderSlug}
        profile={profile}
        config={config}
        primaryColor={primaryColor}
      />
    </Card>
  );
}

export default OrderItem;
