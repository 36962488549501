import React, { memo } from "react";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import Tooltip from "@mui/material/Tooltip";
import phoneBadgeLogo from "../../../images/phoneBadge.png";

// ----------------------------------------------------------------------

const useStyles = makeStyles(theme => ({
  root: {
    right: 0,
    display: "flex",
    position: "fixed",
    alignItems: "center",
    top: theme.spacing(11),
    height: theme.spacing(5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(3),
    boxShadow: theme.shadows[25].z20,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    borderTopLeftRadius: theme.shape.borderRadiusMd,
    borderBottomLeftRadius: theme.shape.borderRadiusMd,
    transition: theme.transitions.create("opacity"),
    "&:hover": { opacity: 0.72 }
  }
}));

// ----------------------------------------------------------------------

function PhoneCall({ phoneNumber }) {
  const classes = useStyles();

  return (
    <Tooltip title="Call Restaurant" placement="top-start">
      <Box
        component={RouterLink}
        className={classes.root}
        onClick={() => {
          window.open(`tel:${phoneNumber}`, "_blank");
        }}
      >
        <img src={phoneBadgeLogo} width={30} height={30} />
      </Box>
    </Tooltip>
  );
}

export default memo(PhoneCall);
