// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS = {
  auth: "/auth",
  app: "/app",
  docs: "/docs",
  menu: "/menu",
  demo: "/demo",
  thankyou: "/thankyou",
  exampleMenu: "/example",
  // signup : "/signup",
  placeOrder:"/place-order"
};

export const PATH_MENU = {
  root: ROOTS.menu,
  menu: {
    list: path(ROOTS.menu, "/"),
    // list: path(ROOTS.menu, '/:branch/:tenant'),
    view: path(ROOTS.menu, "/:menuId"),
    order:path(ROOTS.menu, "/order/:orderId"),
  }
};
export const PATH_DEMO = {
  root: ROOTS.demo,
  demo: {
    form: path(ROOTS.demo, "/")
  }
};
export const PATH_PLACE_ORDER = {
  root: ROOTS.order,
  order: {
    order: path(ROOTS.placeOrder, "/")
  }
};
// export const PATH_SIGNUP = {
//   root: ROOTS.signup,
//   signup: {
//     signup: path(ROOTS.signup, "/")
//   }
// };
export const PATH_THANKYOU = {
  root: ROOTS.thankyou,
  thankyou: {
    thankyou: path(ROOTS.thankyou, "/"),
    cancel: path(ROOTS.thankyou, "/cancel")
  }
};
export const PATH_EXAMPLEMENU = {
  root: ROOTS.exampleMenu,
  example: {
    exampleMenu: path(ROOTS.exampleMenu, "/")
  }
};

// export const PATH_SIGNUP = {
//   root: ROOTS.SignUp,
//   SignUp: {
//     form: path(ROOTS.SignUp, "/")
//   }
// };

export const PATH_PAGE = {
  auth: {
    root: ROOTS.auth,
    login: path(ROOTS.auth, "/login"),
    loginUnprotected: path(ROOTS.auth, "/login-unprotected"),
    register: path(ROOTS.auth, "/register"),
    registerUnprotected: path(ROOTS.auth, "/register-unprotected"),
    resetPassword: path(ROOTS.auth, "/reset-password"),
    verify: path(ROOTS.auth, "/verify")
  },
  comingSoon: "/coming-soon",
  maintenance: "/maintenance",
  pricing: "/pricing",
  payment: "/payment"
};

export const PATH_HOME = {
  components: "/components",
  cloud: "https://www.sketch.com/s/0fa4699d-a3ff-4cd5-a3a7-d851eb7e17f0",
  purchase: "https://material-ui.com/store/items/minimal-dashboard/",
  dashboard: ROOTS.app
};

export const PATH_APP = {
  root: ROOTS.app,

  main: {
    root: path(ROOTS.app, "/dashboard"),
    dashboard: path(ROOTS.app, "/dashboard"),
    ecommerce: path(ROOTS.app, "/dashboard/ecommerce")
  },
  app: {
    mail: {
      root: path(ROOTS.app, "/mail"),
      all: path(ROOTS.app, "/mail/all"),
      labels: [
        path(ROOTS.app, "/mail/label/:customLabel/:mailId?"),
        path(ROOTS.app, "/mail/:systemLabel/:mailId?")
      ]
    },
    chat: {
      root: path(ROOTS.app, "/chat"),
      new: path(ROOTS.app, "/chat/new"),
      conversation: [
        path(ROOTS.app, "/chat/new"),
        path(ROOTS.app, "/chat/:conversationKey")
      ]
    },
    calendar: path(ROOTS.app, "/calendar")
  },
  management: {
    root: path(ROOTS.app, "/management"),
    user: {
      root: path(ROOTS.app, "/management/user"),
      profile: path(ROOTS.app, "/management/user/profile"),
      cards: path(ROOTS.app, "/management/user/card"),
      list: path(ROOTS.app, "/management/user/list"),
      account: path(ROOTS.app, "/management/user/account")
    },
    eCommerce: {
      root: path(ROOTS.app, "/management/e-commerce"),
      products: path(ROOTS.app, "/management/e-commerce/products"),
      product: path(ROOTS.app, "/management/e-commerce/product/:name"),
      productById: path(
        ROOTS.app,
        "/management/e-commerce/product/nike-air-force-1-ndestrukt"
      ),
      list: path(ROOTS.app, "/management/e-commerce/list"),
      checkout: path(ROOTS.app, "/management/e-commerce/checkout"),
      invoice: path(ROOTS.app, "/management/e-commerce/invoice")
    },
    blog: {
      root: path(ROOTS.app, "/management/blog"),
      post: path(ROOTS.app, "/management/blog/post/:title"),
      postById: path(
        ROOTS.app,
        "/management/blog/post/portfolio-review-is-this-portfolio-too-creative"
      ),
      newPost: path(ROOTS.app, "/management/blog/new-post")
    }
  },
  foundations: {
    root: path(ROOTS.app, "/foundations"),
    colors: path(ROOTS.app, "/foundations/colors"),
    typography: path(ROOTS.app, "/foundations/typography"),
    shadows: path(ROOTS.app, "/foundations/shadows"),
    grid: path(ROOTS.app, "/foundations/grid"),
    icons: path(ROOTS.app, "/foundations/icons")
  },
  components: {
    root: path(ROOTS.app, "/components"),

    // Extra
    chart: {
      root: path(ROOTS.app, "/extra-components/chart"),
      apexcharts: path(ROOTS.app, "/extra-components/chart/apexcharts"),
      recharts: path(ROOTS.app, "/extra-components/chart/recharts")
    },
    map: {
      root: path(ROOTS.app, "/extra-components/map"),
      google: path(ROOTS.app, "/extra-components/map/googlemap"),
      mapbox: path(ROOTS.app, "/extra-components/map/mapbox")
    },
    editor: path(ROOTS.app, "/extra-components/editor"),
    copyToClipboard: path(ROOTS.app, "/extra-components/copy-to-clipboard"),
    upload: path(ROOTS.app, "/extra-components/upload"),
    carousel: path(ROOTS.app, "/extra-components/carousel"),
    multiLanguage: path(ROOTS.app, "/extra-components/multi-language")
  }
};

export const PATH_DOCS = {
  root: ROOTS.docs,
  introduction: path(ROOTS.docs, "/introduction"),
  started: path(ROOTS.docs, "/getting-started"),
  // Theme UI
  color: path(ROOTS.docs, "/color"),
  typography: path(ROOTS.docs, "/typography"),
  icon: path(ROOTS.docs, "/icon"),
  shadows: path(ROOTS.docs, "/shadows"),
  components: path(ROOTS.docs, "/components"),
  important: path(ROOTS.docs, "/important"),

  // Development
  routing: path(ROOTS.docs, "/routing"),
  environmentVariables: path(ROOTS.docs, "/environment-variables"),
  stateManagement: path(ROOTS.docs, "/state-management"),
  apiCalls: path(ROOTS.docs, "/api-calls"),
  analytics: path(ROOTS.docs, "/analytics"),
  authentication: path(ROOTS.docs, "/authentication"),
  multiLanguage: path(ROOTS.docs, "/multi-language"),
  formHelper: path(ROOTS.docs, "/form-helper"),

  // Changelog
  support: path(ROOTS.docs, "/support"),
  changelog: path(ROOTS.docs, "/changelog")
};
