import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function PrivacyPolicy({ menuSlug, profile }) {
  const [open, setOpen] = React.useState(false);
  const { legalName } = profile;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button onClick={handleClickOpen}>Privacy Policy</Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <article>
            <div data-custom-class="body">
              <div>
                <strong>
                  <span style={{ fontSize: 26, opacity: 0.5 }}>
                    <span data-custom-class="title">PRIVACY NOTICE</span>
                  </span>
                </strong>
              </div>
              <div>
                <br />
              </div>
              <div>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <strong>
                    <span style={{ fontSize: 15 }}>
                      <span data-custom-class="subtitle">
                        Last updated{" "}
                        <div className="question">February 27, 2022</div>
                      </span>
                    </span>
                  </strong>
                </span>
              </div>
              <div>
                <br />
              </div>
              <div>
                <br />
              </div>
              <div>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      Thank you for choosing to be part of our community at{" "}
                      <div className="question">{legalName}</div>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          <div className="block-component" />
                        </span>
                      </span>{" "}
                      ("
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          <div className="block-component" />
                        </span>
                      </span>
                      <strong>Company</strong>
                    </span>
                    <span style={{ color: "rgb(127, 127, 127)" }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              <div className="statement-end-if-in-editor">
                                <span data-custom-class="body_text" />
                              </div>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                    <span data-custom-class="body_text">
                      ," "<strong>we</strong>," "<strong>us</strong>," or "
                      <strong>our</strong>"). We are committed to protecting
                      your personal information and your right to privacy.
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15 }}>
                  <span style={{ color: "rgb(127, 127, 127)" }}>
                    <span data-custom-class="body_text">
                      This privacy notice describes how we might use your
                      information if you:
                    </span>
                  </span>
                </span>
                <span style={{ fontSize: 15 }}>
                  <span style={{ color: "rgb(127, 127, 127)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          <div className="block-component" />
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <ul>
                <li style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        Visit our website
                        <div className="block-component" /> at{" "}
                        <div className="question">
                          <a
                            href={`https://food-order.in/${menuSlug}`}
                            target="_blank"
                            data-custom-class="link"
                          >
                            {`https://food-order.in/${menuSlug}`}
                          </a>
                        </div>
                        <span style={{ fontSize: 15 }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              <span style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <div className="statement-end-if-in-editor" />
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
              <div>
                <div className="block-component">
                  <span style={{ fontSize: 15 }}>
                    <span style={{ fontSize: 15 }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span data-custom-class="body_text">
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              <div className="block-component" />
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                  <div>
                    <div className="block-component">
                      <span style={{ fontSize: 15 }}>
                        <span style={{ fontSize: 15 }}>
                          <span style={{ color: "rgb(127, 127, 127)" }}>
                            <span data-custom-class="body_text">
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                  <div className="block-component" />
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                      <div style={{ lineHeight: "1.5" }}>
                        <span style={{ fontSize: 15 }}>
                          <span style={{ color: "rgb(127, 127, 127)" }}>
                            <span data-custom-class="body_text">
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                  <div className="block-component" />
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </div>
                      <ul>
                        <li style={{ lineHeight: "1.5" }}>
                          <span
                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                          >
                            <span
                              style={{
                                fontSize: 15,
                                color: "rgb(89, 89, 89)"
                              }}
                            >
                              <span data-custom-class="body_text">
                                Engage with us in other related ways ― including
                                any sales, marketing, or events
                                <span style={{ fontSize: 15 }}>
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                      <span style={{ fontSize: 15 }}>
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <div className="statement-end-if-in-editor" />
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </li>
                      </ul>
                      <div style={{ lineHeight: "1.5" }}>
                        <span style={{ fontSize: 15 }}>
                          <span style={{ color: "rgb(127, 127, 127)" }}>
                            <span data-custom-class="body_text">
                              In this privacy notice, if we refer to:
                            </span>
                          </span>
                        </span>
                        <span style={{ fontSize: 15 }}>
                          <span style={{ color: "rgb(127, 127, 127)" }}>
                            <span data-custom-class="body_text">
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                  <div className="block-component" />
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </div>
                      <ul>
                        <li style={{ lineHeight: "1.5" }}>
                          <span
                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                          >
                            <span
                              style={{
                                fontSize: 15,
                                color: "rgb(89, 89, 89)"
                              }}
                            >
                              <span data-custom-class="body_text">
                                "<strong>Website</strong>," we are referring to
                                any website of ours that references or links to
                                this policy
                                <span style={{ fontSize: 15 }}>
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                      <span style={{ fontSize: 15 }}>
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <div className="statement-end-if-in-editor" />
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </li>
                      </ul>
                      <div>
                        <div className="block-component">
                          <span style={{ fontSize: 15 }}>
                            <span style={{ fontSize: 15 }}>
                              <span style={{ color: "rgb(127, 127, 127)" }}>
                                <span data-custom-class="body_text">
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                      <div className="block-component" />
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                          <div>
                            <div className="block-component">
                              <span style={{ fontSize: 15 }}>
                                <span style={{ fontSize: 15 }}>
                                  <span style={{ color: "rgb(127, 127, 127)" }}>
                                    <span data-custom-class="body_text">
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        <span data-custom-class="body_text">
                                          <div className="block-component" />
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </div>
                          </div>
                          <ul>
                            <li style={{ lineHeight: "1.5" }}>
                              <span
                                style={{
                                  fontSize: 15,
                                  color: "rgb(89, 89, 89)"
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: 15,
                                    color: "rgb(89, 89, 89)"
                                  }}
                                >
                                  <span data-custom-class="body_text">
                                    "<strong>Services</strong>," we are
                                    referring to our
                                    <span style={{ fontSize: 15 }}>
                                      <span
                                        style={{
                                          color: "rgb(127, 127, 127)"
                                        }}
                                      >
                                        <span data-custom-class="body_text">
                                          <span
                                            style={{
                                              color: "rgb(89, 89, 89)"
                                            }}
                                          >
                                            <span data-custom-class="body_text">
                                              <div className="block-component" />
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>{" "}
                                    Website,
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)"
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)"
                                        }}
                                      >
                                        <span data-custom-class="body_text">
                                          <span style={{ fontSize: 15 }}>
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)"
                                              }}
                                            >
                                              <span data-custom-class="body_text">
                                                <span style={{ fontSize: 15 }}>
                                                  <span
                                                    style={{
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <div className="statement-end-if-in-editor">
                                                      <div className="block-component" />
                                                    </div>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                              <span style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(127, 127, 127)" }}>
                                  <span data-custom-class="body_text">
                                    &nbsp;and other related services, including
                                    any sales, marketing, or events
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)"
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)"
                                        }}
                                      >
                                        <span data-custom-class="body_text">
                                          <span style={{ fontSize: 15 }}>
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)"
                                              }}
                                            >
                                              <span data-custom-class="body_text">
                                                <span style={{ fontSize: 15 }}>
                                                  <span
                                                    style={{
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <div className="statement-end-if-in-editor" />
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </li>
                          </ul>
                          <div style={{ lineHeight: "1.5" }}>
                            <span style={{ fontSize: 15 }}>
                              <span style={{ color: "rgb(127, 127, 127)" }}>
                                <span data-custom-class="body_text">
                                  The purpose of this privacy notice is to
                                  explain to you in the clearest way possible
                                  what information we collect, how we use it,
                                  and what rights you have in relation to it. If
                                  there are any terms in this privacy notice
                                  that you do not agree with, please discontinue
                                  use of our Services immediately.
                                </span>
                              </span>
                            </span>
                          </div>
                          <div style={{ lineHeight: "1.5" }}>
                            <br />
                          </div>
                          <div style={{ lineHeight: "1.5" }}>
                            <span style={{ fontSize: 15 }}>
                              <span style={{ color: "rgb(127, 127, 127)" }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <span data-custom-class="body_text">
                                    <strong>
                                      Please read this privacy notice carefully,
                                      as it will help you understand what we do
                                      with the information that we collect.
                                    </strong>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </div>
                          <div style={{ lineHeight: "1.5" }}>
                            <br />
                          </div>

                          <div style={{ lineHeight: "1.5" }}>
                            <br />
                          </div>
                          <div id="infocollect" style={{ lineHeight: "1.5" }}>
                            <span style={{ color: "rgb(127, 127, 127)" }}>
                              <span
                                style={{
                                  color: "rgb(89, 89, 89)",
                                  fontSize: 15
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: 15,
                                    color: "rgb(89, 89, 89)"
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: 15,
                                      color: "rgb(89, 89, 89)"
                                    }}
                                  >
                                    <span
                                      id="control"
                                      style={{ color: "rgb(0, 0, 0)" }}
                                    >
                                      <strong>
                                        <span
                                          data-custom-class="heading_1"
                                          style={{ opacity: 0.7 }}
                                        >
                                          1. WHAT INFORMATION DO WE COLLECT?
                                        </span>
                                      </strong>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </div>
                          <div style={{ lineHeight: "1.5" }}>
                            <br />
                          </div>
                          <div style={{ lineHeight: "1.5" }}>
                            <span
                              data-custom-class="heading_2"
                              style={{ color: "rgb(0, 0, 0)" }}
                            >
                              <span style={{ fontSize: 15, opacity: 0.7 }}>
                                <strong>
                                  Personal information you disclose to us
                                </strong>
                              </span>
                            </span>
                          </div>
                          <div>
                            <div>
                              <br />
                            </div>
                            <div style={{ lineHeight: "1.5" }}>
                              <span style={{ color: "rgb(127, 127, 127)" }}>
                                <span
                                  style={{
                                    color: "rgb(89, 89, 89)",
                                    fontSize: 15
                                  }}
                                >
                                  <span data-custom-class="body_text">
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)"
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)"
                                        }}
                                      >
                                        <span data-custom-class="body_text">
                                          <strong>
                                            <em>In Short:&nbsp;</em>
                                          </strong>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                  <span data-custom-class="body_text">
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)"
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)"
                                        }}
                                      >
                                        <span data-custom-class="body_text">
                                          <strong>
                                            <em>&nbsp;</em>
                                          </strong>
                                          <em>
                                            We collect personal information that
                                            you provide to us.
                                          </em>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </div>
                          </div>
                          <div style={{ lineHeight: "1.5" }}>
                            <br />
                          </div>
                          <div style={{ lineHeight: "1.5" }}>
                            <span
                              style={{
                                fontSize: 15,
                                color: "rgb(89, 89, 89)"
                              }}
                            >
                              <span
                                style={{
                                  fontSize: 15,
                                  color: "rgb(89, 89, 89)"
                                }}
                              >
                                <span data-custom-class="body_text">
                                  We collect personal information that you
                                  voluntarily provide to us when you{" "}
                                  <span style={{ fontSize: 15 }}>
                                    <div className="block-component" />
                                  </span>
                                  register on the&nbsp;
                                </span>
                                <span style={{ fontSize: 15 }}>
                                  <span data-custom-class="body_text">
                                    <div className="block-component" />
                                    Website, <div className="block-component" />
                                  </span>
                                  <span data-custom-class="body_text">
                                    <span style={{ fontSize: 15 }}>
                                      <div className="statement-end-if-in-editor" />
                                    </span>
                                  </span>
                                  <span data-custom-class="body_text">
                                    express an interest in obtaining information
                                    about us or our products and Services, when
                                    you participate in activities on the{" "}
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)"
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)"
                                        }}
                                      >
                                        <span style={{ fontSize: 15 }}>
                                          <span data-custom-class="body_text">
                                            <div className="block-component" />
                                            Website
                                            <div className="block-component" />
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                    <div className="block-component" />
                                  </span>
                                </span>
                                <span data-custom-class="body_text">
                                  &nbsp;or otherwise when you contact us.
                                </span>
                              </span>
                            </span>
                          </div>
                          <div style={{ lineHeight: "1.5" }}>
                            <br />
                          </div>
                          <div style={{ lineHeight: "1.5" }}>
                            <span
                              style={{
                                fontSize: 15,
                                color: "rgb(89, 89, 89)"
                              }}
                            >
                              <span
                                style={{
                                  fontSize: 15,
                                  color: "rgb(89, 89, 89)"
                                }}
                              >
                                <span data-custom-class="body_text">
                                  The personal information that we collect
                                  depends on the context of your interactions
                                  with us and the{" "}
                                  <span
                                    style={{
                                      fontSize: 15,
                                      color: "rgb(89, 89, 89)"
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)"
                                      }}
                                    >
                                      <span style={{ fontSize: 15 }}>
                                        <span data-custom-class="body_text">
                                          <span
                                            style={{
                                              fontSize: 15,
                                              color: "rgb(89, 89, 89)"
                                            }}
                                          >
                                            <span
                                              style={{
                                                fontSize: 15,
                                                color: "rgb(89, 89, 89)"
                                              }}
                                            >
                                              <span style={{ fontSize: 15 }}>
                                                <span data-custom-class="body_text">
                                                  <div className="block-component" />
                                                  Website
                                                  <div className="block-component" />
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                  <span style={{ fontSize: 15 }}>
                                    , the choices you make and the products and
                                    features you use. The personal information
                                    we collect may include the following:
                                    <div className="block-component" />
                                  </span>
                                </span>
                              </span>
                            </span>
                          </div>
                          <div style={{ lineHeight: "1.5" }}>
                            <br />
                          </div>
                          <div style={{ lineHeight: "1.5" }}>
                            <span
                              style={{
                                fontSize: 15,
                                color: "rgb(89, 89, 89)"
                              }}
                            >
                              <span
                                style={{
                                  fontSize: 15,
                                  color: "rgb(89, 89, 89)"
                                }}
                              >
                                <span data-custom-class="body_text">
                                  <strong>
                                    Personal Information Provided by You.
                                  </strong>{" "}
                                  We collect{" "}
                                  <span style={{ fontSize: 15 }}>
                                    <span data-custom-class="body_text">
                                      <div className="forloop-component" />
                                      <div className="question">
                                        names,&nbsp;
                                      </div>
                                    </span>
                                    <span data-custom-class="body_text">
                                      <div className="forloop-component" />
                                      <div className="question">
                                        phone numbers
                                      </div>
                                    </span>
                                    <span data-custom-class="body_text">
                                      <div className="forloop-component" />
                                      and other similar information.
                                    </span>
                                    <span data-custom-class="body_text">
                                      <div className="statement-end-if-in-editor" />
                                      <div className="block-component" />
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </div>
                          <div style={{ lineHeight: "1.5" }}>
                            <br />
                          </div>

                          <div style={{ lineHeight: "1.5" }}>
                            <span
                              style={{
                                fontSize: 15,
                                color: "rgb(89, 89, 89)"
                              }}
                            >
                              <span
                                style={{
                                  fontSize: 15,
                                  color: "rgb(89, 89, 89)"
                                }}
                              >
                                <span data-custom-class="body_text">
                                  All personal information that you provide to
                                  us must be true, complete and accurate, and
                                  you must notify us of any changes to such
                                  personal information.
                                  <div className="block-component" />
                                  <span data-custom-class="body_text">
                                    <span style={{ fontSize: 15 }}>
                                      <div className="statement-end-if-in-editor">
                                        <div className="statement-end-if-in-editor">
                                          <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="body_text">
                                              <span style={{ fontSize: 15 }}>
                                                <span data-custom-class="body_text">
                                                  <div className="statement-end-if-in-editor">
                                                    <div className="statement-end-if-in-editor">
                                                      <div className="block-component" />
                                                    </div>
                                                  </div>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </div>
                          <div style={{ lineHeight: "1.5" }}>
                            <br />
                          </div>
                          <div style={{ lineHeight: "1.5" }}>
                            <span
                              data-custom-class="heading_2"
                              style={{ color: "rgb(0, 0, 0)" }}
                            >
                              <span style={{ fontSize: 15, opacity: 0.7 }}>
                                <strong>
                                  Information collected through our App
                                </strong>
                              </span>
                            </span>
                          </div>
                          <div>
                            <div>
                              <br />
                            </div>
                            <div style={{ lineHeight: "1.5" }}>
                              <span style={{ color: "rgb(127, 127, 127)" }}>
                                <span
                                  style={{
                                    color: "rgb(89, 89, 89)",
                                    fontSize: 15
                                  }}
                                >
                                  <span data-custom-class="body_text">
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)"
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)"
                                        }}
                                      >
                                        <span data-custom-class="body_text">
                                          <strong>
                                            <em>In Short:&nbsp;</em>
                                          </strong>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                  <span data-custom-class="body_text">
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)"
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)"
                                        }}
                                      >
                                        <span data-custom-class="body_text">
                                          <strong>
                                            <em>&nbsp;</em>
                                          </strong>
                                          <em>
                                            We collect information regarding
                                            your
                                            <span
                                              data-custom-class="body_text"
                                              style={{ fontSize: 15 }}
                                            >
                                              <div className="block-component" />
                                              <div className="block-component" />{" "}
                                              mobile device,
                                              <div className="statement-end-if-in-editor" />
                                              <div className="block-component" />{" "}
                                              push notifications,
                                              <div className="statement-end-if-in-editor">
                                                <div className="block-component" />
                                              </div>
                                            </span>{" "}
                                            when you use our App.
                                          </em>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </div>
                          </div>
                          <div style={{ lineHeight: "1.5" }}>
                            <br />
                          </div>
                          <div style={{ lineHeight: "1.5" }}>
                            <span
                              style={{
                                fontSize: 15,
                                color: "rgb(89, 89, 89)"
                              }}
                            >
                              <span
                                style={{
                                  fontSize: 15,
                                  color: "rgb(89, 89, 89)"
                                }}
                              >
                                <span data-custom-class="body_text">
                                  If you use our App, we also collect the
                                  following information:
                                  <div className="block-component" />
                                </span>
                              </span>
                            </span>
                            <div>
                              <div className="block-component">
                                <span style={{ fontSize: 15 }} />
                              </div>
                            </div>
                            <ul>
                              <li style={{ lineHeight: "1.5" }}>
                                <span
                                  style={{
                                    fontSize: 15,
                                    color: "rgb(89, 89, 89)"
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: 15,
                                      color: "rgb(89, 89, 89)"
                                    }}
                                  >
                                    <span data-custom-class="body_text">
                                      <em>Mobile Device Access.</em> We may
                                      request access or permission to certain
                                      features from your mobile device,
                                      including your mobile device's{" "}
                                      <span style={{ fontSize: 15 }}>
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <span data-custom-class="body_text">
                                            <span style={{ fontSize: 15 }}>
                                              <span
                                                style={{
                                                  color: "rgb(89, 89, 89)"
                                                }}
                                              >
                                                <span data-custom-class="body_text">
                                                  <span
                                                    style={{
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <div className="forloop-component" />
                                                        <div className="question">
                                                          camera
                                                        </div>
                                                        ,{" "}
                                                        <div className="forloop-component" />
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>{" "}
                                      and other features. If you wish to change
                                      our access or permissions, you may do so
                                      in your device's settings.
                                      <span style={{ fontSize: 15 }}>
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <span data-custom-class="body_text">
                                            <span style={{ fontSize: 15 }}>
                                              <span
                                                style={{
                                                  color: "rgb(89, 89, 89)"
                                                }}
                                              >
                                                <div className="statement-end-if-in-editor" />
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </li>
                            </ul>
                            <div>
                              <div className="block-component">
                                <span style={{ fontSize: 15 }} />
                              </div>
                              <div>
                                <div className="block-component">
                                  <span style={{ fontSize: 15 }} />
                                </div>
                              </div>
                              <ul>
                                <li style={{ lineHeight: "1.5" }}>
                                  <span
                                    style={{
                                      fontSize: 15,
                                      color: "rgb(89, 89, 89)"
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)"
                                      }}
                                    >
                                      <span data-custom-class="body_text">
                                        <em>Push Notifications.&nbsp;</em>We may
                                        request to send you push notifications
                                        regarding your account or certain
                                        features of the App. If you wish to
                                        opt-out from receiving these types of
                                        communications, you may turn them off in
                                        your device's settings.
                                        <span style={{ fontSize: 15 }}>
                                          <span
                                            style={{
                                              color: "rgb(89, 89, 89)"
                                            }}
                                          >
                                            <span data-custom-class="body_text">
                                              <span style={{ fontSize: 15 }}>
                                                <span
                                                  style={{
                                                    color: "rgb(89, 89, 89)"
                                                  }}
                                                >
                                                  <div className="statement-end-if-in-editor" />
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </li>
                              </ul>
                              <div>
                                <div className="block-component">
                                  <span style={{ fontSize: 15 }}>
                                    <div className="block-component" />
                                  </span>
                                  <div style={{ lineHeight: "1.5" }}>
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)"
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)"
                                        }}
                                      >
                                        <span data-custom-class="body_text">
                                          This information is primarily needed
                                          to maintain the security and operation
                                          of our App, for troubleshooting and
                                          for our internal analytics and
                                          reporting purposes.
                                          <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="body_text">
                                              <span style={{ fontSize: 15 }}>
                                                <span data-custom-class="body_text">
                                                  <div className="statement-end-if-in-editor">
                                                    <div className="statement-end-if-in-editor" />
                                                  </div>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                          <span
                                            style={{
                                              color: "rgb(89, 89, 89)",
                                              fontSize: 15
                                            }}
                                          >
                                            <span data-custom-class="body_text">
                                              <span
                                                style={{
                                                  color: "rgb(89, 89, 89)",
                                                  fontSize: 15
                                                }}
                                              >
                                                <span data-custom-class="body_text">
                                                  <div className="statement-end-if-in-editor">
                                                    <div className="block-component" />
                                                  </div>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                      <div className="block-component" />
                                    </span>
                                  </div>
                                  <div style={{ lineHeight: "1.5" }}>
                                    <br />
                                  </div>
                                  <div
                                    id="infouse"
                                    style={{ lineHeight: "1.5" }}
                                  >
                                    <span
                                      style={{ color: "rgb(127, 127, 127)" }}
                                    >
                                      <span
                                        style={{
                                          color: "rgb(89, 89, 89)",
                                          fontSize: 15
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontSize: 15,
                                            color: "rgb(89, 89, 89)"
                                          }}
                                        >
                                          <span
                                            style={{
                                              fontSize: 15,
                                              color: "rgb(89, 89, 89)"
                                            }}
                                          >
                                            <span
                                              id="control"
                                              style={{
                                                color: "rgb(0, 0, 0)"
                                              }}
                                            >
                                              <strong>
                                                <span
                                                  data-custom-class="heading_1"
                                                  style={{ opacity: 0.7 }}
                                                >
                                                  2. HOW DO WE USE YOUR
                                                  INFORMATION?
                                                </span>
                                              </strong>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </div>
                                  <div>
                                    <div style={{ lineHeight: "1.5" }}>
                                      <br />
                                    </div>
                                    <div style={{ lineHeight: "1.5" }}>
                                      <span
                                        style={{
                                          color: "rgb(127, 127, 127)"
                                        }}
                                      >
                                        <span
                                          style={{
                                            color: "rgb(89, 89, 89)",
                                            fontSize: 15
                                          }}
                                        >
                                          <span data-custom-class="body_text">
                                            <span
                                              style={{
                                                fontSize: 15,
                                                color: "rgb(89, 89, 89)"
                                              }}
                                            >
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                  color: "rgb(89, 89, 89)"
                                                }}
                                              >
                                                <span data-custom-class="body_text">
                                                  <strong>
                                                    <em>In Short: &nbsp;</em>
                                                  </strong>
                                                  <em>
                                                    We process your information
                                                    for purposes based on
                                                    legitimate business
                                                    interests, the fulfillment
                                                    of our contract with you,
                                                    compliance with our legal
                                                    obligations, and/or your
                                                    consent.
                                                  </em>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                  <div style={{ lineHeight: "1.5" }}>
                                    <br />
                                  </div>
                                  <div style={{ lineHeight: "1.5" }}>
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)"
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)"
                                        }}
                                      >
                                        <span data-custom-class="body_text">
                                          We use personal information collected
                                          via our{" "}
                                          <span
                                            style={{
                                              fontSize: 15,
                                              color: "rgb(89, 89, 89)"
                                            }}
                                          >
                                            <span
                                              style={{
                                                fontSize: 15,
                                                color: "rgb(89, 89, 89)"
                                              }}
                                            >
                                              <span style={{ fontSize: 15 }}>
                                                <span data-custom-class="body_text">
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: "rgb(89, 89, 89)"
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <div className="block-component" />
                                                          Website
                                                          <div className="block-component" />
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>{" "}
                                          for a variety of business purposes
                                          described below. We process your
                                          personal information for these
                                          purposes in reliance on our legitimate
                                          business interests, in order to enter
                                          into or perform a contract with you,
                                          with your consent, and/or for
                                          compliance with our legal obligations.
                                          We indicate the specific processing
                                          grounds we rely on next to each
                                          purpose listed below.
                                          <div className="block-component" />
                                        </span>
                                      </span>
                                    </span>
                                  </div>
                                  <div style={{ lineHeight: "1.5" }}>
                                    <br />
                                  </div>
                                  <div style={{ lineHeight: "1.5" }}>
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)"
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)"
                                        }}
                                      >
                                        <span data-custom-class="body_text">
                                          We use the information we collect or
                                          receive:
                                          <div className="block-component" />
                                        </span>
                                      </span>
                                    </span>
                                  </div>
                                  <ul>
                                    <li style={{ lineHeight: "1.5" }}>
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)"
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontSize: 15,
                                            color: "rgb(89, 89, 89)"
                                          }}
                                        >
                                          <span data-custom-class="body_text">
                                            <strong>
                                              To facilitate account creation and
                                              login process.
                                            </strong>{" "}
                                            If you choose to link your account
                                            with us to a third-party account
                                            (such as your Google or Facebook
                                            account), we use the information you
                                            allowed us to collect from those
                                            third parties to facilitate account
                                            creation and login process for the
                                            performance of the contract.
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                                fontSize: 15
                                              }}
                                            >
                                              <span style={{ fontSize: 15 }}>
                                                <span
                                                  style={{
                                                    color: "rgb(89, 89, 89)"
                                                  }}
                                                >
                                                  <span data-custom-class="body_text">
                                                    <div className="block-component">
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)"
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)"
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(89, 89, 89)"
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              <div className="statement-end-if-in-editor" />
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </li>
                                  </ul>
                                  <div>
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)"
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)"
                                        }}
                                      >
                                        <span data-custom-class="body_text">
                                          <div className="block-component" />
                                        </span>
                                      </span>
                                    </span>
                                  </div>
                                  <ul>
                                    <li style={{ lineHeight: "1.5" }}>
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)"
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontSize: 15,
                                            color: "rgb(89, 89, 89)"
                                          }}
                                        >
                                          <span data-custom-class="body_text">
                                            <strong>
                                              To post testimonials.
                                            </strong>{" "}
                                            We post testimonials on our{" "}
                                            <span
                                              style={{
                                                fontSize: 15,
                                                color: "rgb(89, 89, 89)"
                                              }}
                                            >
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                  color: "rgb(89, 89, 89)"
                                                }}
                                              >
                                                <span style={{ fontSize: 15 }}>
                                                  <span data-custom-class="body_text">
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: "rgb(89, 89, 89)"
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)"
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <div className="block-component" />
                                                            Website
                                                            <div className="block-component" />
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>{" "}
                                            that may contain personal
                                            information. Prior to posting a
                                            testimonial, we will obtain your
                                            consent to use your name and the
                                            content of the testimonial.
                                            <span
                                              style={{
                                                fontSize: 15,
                                                color: "rgb(89, 89, 89)"
                                              }}
                                            >
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                  color: "rgb(89, 89, 89)"
                                                }}
                                              >
                                                <span data-custom-class="body_text">
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: "rgb(89, 89, 89)"
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <span
                                                          style={{
                                                            fontSize: 15
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <div className="statement-end-if-in-editor" />
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </li>
                                  </ul>
                                  <div>
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)"
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)"
                                        }}
                                      >
                                        <span data-custom-class="body_text">
                                          <div className="block-component" />
                                        </span>
                                      </span>
                                    </span>
                                  </div>
                                  <ul>
                                    <li style={{ lineHeight: "1.5" }}>
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)"
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontSize: 15,
                                            color: "rgb(89, 89, 89)"
                                          }}
                                        >
                                          <span data-custom-class="body_text">
                                            <strong>
                                              Request feedback.&nbsp;
                                            </strong>
                                            We may use your information to
                                            request feedback and to contact you
                                            about your use of our{" "}
                                            <span
                                              style={{
                                                fontSize: 15,
                                                color: "rgb(89, 89, 89)"
                                              }}
                                            >
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                  color: "rgb(89, 89, 89)"
                                                }}
                                              >
                                                <span style={{ fontSize: 15 }}>
                                                  <span data-custom-class="body_text">
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: "rgb(89, 89, 89)"
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)"
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <div className="block-component" />
                                                            Website
                                                            <div className="block-component" />
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                            <span style={{ fontSize: 15 }}>
                                              <span
                                                style={{
                                                  color: "rgb(89, 89, 89)"
                                                }}
                                              >
                                                <span data-custom-class="body_text">
                                                  .
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: "rgb(89, 89, 89)"
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)"
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  "rgb(89, 89, 89)"
                                                                              }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                <div className="statement-end-if-in-editor" />
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </li>
                                  </ul>
                                  <div>
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)"
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)"
                                        }}
                                      >
                                        <span data-custom-class="body_text">
                                          <div className="block-component" />
                                        </span>
                                      </span>
                                    </span>
                                  </div>
                                  <ul>
                                    <li style={{ lineHeight: "1.5" }}>
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)"
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontSize: 15,
                                            color: "rgb(89, 89, 89)"
                                          }}
                                        >
                                          <span data-custom-class="body_text">
                                            <strong>
                                              To enable user-to-user
                                              communications.
                                            </strong>{" "}
                                            We may use your information in order
                                            to enable user-to-user
                                            communications with each user's
                                            consent.
                                            <span
                                              style={{
                                                fontSize: 15,
                                                color: "rgb(89, 89, 89)"
                                              }}
                                            >
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                  color: "rgb(89, 89, 89)"
                                                }}
                                              >
                                                <span data-custom-class="body_text">
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    <span
                                                      style={{
                                                        color: "rgb(89, 89, 89)"
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <span
                                                          style={{
                                                            fontSize: 15
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <div className="statement-end-if-in-editor" />
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </li>
                                  </ul>
                                  <div>
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)"
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)"
                                        }}
                                      >
                                        <span data-custom-class="body_text">
                                          <div className="block-component" />
                                        </span>
                                      </span>
                                    </span>
                                  </div>
                                  <ul>
                                    <li style={{ lineHeight: "1.5" }}>
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)"
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontSize: 15,
                                            color: "rgb(89, 89, 89)"
                                          }}
                                        >
                                          <span data-custom-class="body_text">
                                            <strong>
                                              To manage user accounts.&nbsp;
                                            </strong>
                                            We may use your information for the
                                            purposes of managing our account and
                                            keeping it in working order.
                                            <div className="statement-end-if-in-editor" />
                                          </span>
                                        </span>
                                      </span>
                                    </li>
                                  </ul>
                                  <div>
                                    <div className="block-component" />
                                  </div>
                                  <ul>
                                    <li style={{ lineHeight: "1.5" }}>
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)"
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontSize: 15,
                                            color: "rgb(89, 89, 89)"
                                          }}
                                        >
                                          <span data-custom-class="body_text">
                                            <strong>
                                              To send administrative information
                                              to you.&nbsp;
                                            </strong>
                                            We may use your personal information
                                            to send you product, service and new
                                            feature information and/or
                                            information about changes to our
                                            terms, conditions, and policies.
                                            <span
                                              style={{
                                                fontSize: 15,
                                                color: "rgb(89, 89, 89)"
                                              }}
                                            >
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                  color: "rgb(89, 89, 89)"
                                                }}
                                              >
                                                <span data-custom-class="body_text">
                                                  <div className="statement-end-if-in-editor" />
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </li>
                                  </ul>
                                  <div>
                                    <div className="block-component" />
                                  </div>
                                  <ul>
                                    <li style={{ lineHeight: "1.5" }}>
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)"
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontSize: 15,
                                            color: "rgb(89, 89, 89)"
                                          }}
                                        >
                                          <span data-custom-class="body_text">
                                            <strong>
                                              To protect our Services.&nbsp;
                                            </strong>
                                            We may use your information as part
                                            of our efforts to keep our{" "}
                                            <span
                                              style={{
                                                fontSize: 15,
                                                color: "rgb(89, 89, 89)"
                                              }}
                                            >
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                  color: "rgb(89, 89, 89)"
                                                }}
                                              >
                                                <span style={{ fontSize: 15 }}>
                                                  <span data-custom-class="body_text">
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: "rgb(89, 89, 89)"
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)"
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <div className="block-component" />
                                                            Website
                                                            <div className="block-component" />
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>{" "}
                                            safe and secure (for example, for
                                            fraud monitoring and prevention).
                                            <span
                                              style={{
                                                fontSize: 15,
                                                color: "rgb(89, 89, 89)"
                                              }}
                                            >
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                  color: "rgb(89, 89, 89)"
                                                }}
                                              >
                                                <span data-custom-class="body_text">
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: "rgb(89, 89, 89)"
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <div className="statement-end-if-in-editor" />
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </li>
                                  </ul>
                                  <div>
                                    <div className="block-component" />
                                  </div>
                                  <ul>
                                    <li style={{ lineHeight: "1.5" }}>
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)"
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontSize: 15,
                                            color: "rgb(89, 89, 89)"
                                          }}
                                        >
                                          <span data-custom-class="body_text">
                                            <strong>
                                              To enforce our terms, conditions
                                              and policies for business
                                              purposes, to comply with legal and
                                              regulatory requirements or in
                                              connection with our contract.
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                  color: "rgb(89, 89, 89)"
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)"
                                                  }}
                                                >
                                                  <span data-custom-class="body_text">
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: "rgb(89, 89, 89)"
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)"
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <div className="statement-end-if-in-editor" />
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </strong>
                                          </span>
                                        </span>
                                      </span>
                                    </li>
                                  </ul>
                                  <div>
                                    <div className="block-component" />
                                  </div>
                                  <ul>
                                    <li style={{ lineHeight: "1.5" }}>
                                      <span style={{ fontSize: 15 }}>
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <span
                                            style={{
                                              color: "rgb(89, 89, 89)"
                                            }}
                                          >
                                            <span data-custom-class="body_text">
                                              <strong>
                                                To respond to legal requests and
                                                prevent harm.&nbsp;
                                              </strong>
                                              If we receive a subpoena or other
                                              legal request, we may need to
                                              inspect the data we hold to
                                              determine how to respond.
                                              <span
                                                style={{
                                                  color: "rgb(89, 89, 89)"
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    color: "rgb(89, 89, 89)"
                                                  }}
                                                >
                                                  <span data-custom-class="body_text">
                                                    <span
                                                      style={{
                                                        color: "rgb(89, 89, 89)"
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)"
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <div className="statement-end-if-in-editor" />
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </li>
                                  </ul>

                                  <ul>
                                    <li style={{ lineHeight: "1.5" }}>
                                      <span style={{ fontSize: 15 }}>
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <span
                                            style={{
                                              color: "rgb(89, 89, 89)"
                                            }}
                                          >
                                            <span data-custom-class="body_text">
                                              <strong>
                                                Fulfill and manage your
                                                orders.&nbsp;
                                              </strong>
                                              We may use your information to
                                              fulfill and manage your orders,
                                              payments, returns, and exchanges
                                              made through the{" "}
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                  color: "rgb(89, 89, 89)"
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)"
                                                  }}
                                                >
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)"
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)"
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <div className="block-component" />
                                                              Website
                                                              <div className="block-component" />
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                              <span style={{ fontSize: 15 }}>
                                                <span
                                                  style={{
                                                    color: "rgb(89, 89, 89)"
                                                  }}
                                                >
                                                  <span data-custom-class="body_text">
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)"
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <div className="statement-end-if-in-editor">
                                                            .
                                                          </div>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                              <span
                                                style={{
                                                  color: "rgb(89, 89, 89)"
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    color: "rgb(89, 89, 89)"
                                                  }}
                                                >
                                                  <span data-custom-class="body_text">
                                                    <span
                                                      style={{
                                                        color: "rgb(89, 89, 89)"
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)"
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <div className="statement-end-if-in-editor" />
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </li>
                                  </ul>

                                  <ul>
                                    <li style={{ lineHeight: "1.5" }}>
                                      <span style={{ fontSize: 15 }}>
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <span
                                            style={{
                                              color: "rgb(89, 89, 89)"
                                            }}
                                          >
                                            <span data-custom-class="body_text">
                                              <strong>
                                                Administer prize draws and
                                                competitions.
                                              </strong>{" "}
                                              We may use your information to
                                              administer prize draws and
                                              competitions when you elect to
                                              participate in our competitions.
                                              <span
                                                style={{
                                                  color: "rgb(89, 89, 89)"
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    color: "rgb(89, 89, 89)"
                                                  }}
                                                >
                                                  <span data-custom-class="body_text">
                                                    <span
                                                      style={{
                                                        color: "rgb(89, 89, 89)"
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)"
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <div className="statement-end-if-in-editor" />
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </li>
                                  </ul>

                                  <ul>
                                    <li style={{ lineHeight: "1.5" }}>
                                      <span style={{ fontSize: 15 }}>
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <span
                                            style={{
                                              color: "rgb(89, 89, 89)"
                                            }}
                                          >
                                            <span data-custom-class="body_text">
                                              <strong>
                                                To deliver and facilitate
                                                delivery of services to the
                                                user.
                                              </strong>{" "}
                                              We may use your information to
                                              provide you with the requested
                                              service.
                                              <span
                                                style={{
                                                  color: "rgb(89, 89, 89)"
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    color: "rgb(89, 89, 89)"
                                                  }}
                                                >
                                                  <span data-custom-class="body_text">
                                                    <span
                                                      style={{
                                                        color: "rgb(89, 89, 89)"
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)"
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <div className="statement-end-if-in-editor" />
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </li>
                                  </ul>

                                  <ul>
                                    <li style={{ lineHeight: "1.5" }}>
                                      <span style={{ fontSize: 15 }}>
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <span
                                            style={{
                                              color: "rgb(89, 89, 89)"
                                            }}
                                          >
                                            <span data-custom-class="body_text">
                                              <strong>
                                                To respond to user
                                                inquiries/offer support to
                                                users.
                                              </strong>{" "}
                                              We may use your information to
                                              respond to your inquiries and
                                              solve any potential issues you
                                              might have with the use of our
                                              Services.
                                              <span
                                                style={{
                                                  color: "rgb(89, 89, 89)"
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    color: "rgb(89, 89, 89)"
                                                  }}
                                                >
                                                  <span data-custom-class="body_text">
                                                    <span
                                                      style={{
                                                        color: "rgb(89, 89, 89)"
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)"
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <div className="statement-end-if-in-editor" />
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </li>
                                  </ul>
                                  <div>
                                    <div className="block-component">
                                      <span style={{ fontSize: 15 }} />
                                    </div>
                                    <div>
                                      <div className="block-component">
                                        <span style={{ fontSize: 15 }} />
                                      </div>
                                      <div>
                                        <div className="block-component">
                                          <span style={{ fontSize: 15 }} />
                                        </div>
                                        <div>
                                          <span style={{ fontSize: 15 }}>
                                            <div className="block-component" />
                                          </span>
                                          <div className="statement-end-if-in-editor">
                                            <span style={{ fontSize: 15 }} />
                                          </div>
                                        </div>
                                        <div style={{ lineHeight: "1.5" }}>
                                          <br />
                                        </div>
                                        <div
                                          id="infoshare"
                                          style={{ lineHeight: "1.5" }}
                                        >
                                          <span
                                            style={{
                                              color: "rgb(127, 127, 127)"
                                            }}
                                          >
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                                fontSize: 15
                                              }}
                                            >
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                  color: "rgb(89, 89, 89)"
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)"
                                                  }}
                                                >
                                                  <span
                                                    id="control"
                                                    style={{
                                                      color: "rgb(0, 0, 0)"
                                                    }}
                                                  >
                                                    <strong>
                                                      <span
                                                        data-custom-class="heading_1"
                                                        style={{ opacity: 0.7 }}
                                                      >
                                                        3. WILL YOUR INFORMATION
                                                        BE SHARED WITH ANYONE?
                                                      </span>
                                                    </strong>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </div>
                                        <div style={{ lineHeight: "1.5" }}>
                                          <br />
                                        </div>
                                        <div style={{ lineHeight: "1.5" }}>
                                          <span
                                            style={{
                                              fontSize: 15,
                                              color: "rgb(89, 89, 89)"
                                            }}
                                          >
                                            <span
                                              style={{
                                                fontSize: 15,
                                                color: "rgb(89, 89, 89)"
                                              }}
                                            >
                                              <span data-custom-class="body_text">
                                                <strong>
                                                  <em>In Short:</em>
                                                </strong>
                                                <em>
                                                  &nbsp; We only share
                                                  information with your consent,
                                                  to comply with laws, to
                                                  provide you with services, to
                                                  protect your rights, or to
                                                  fulfill business obligations.
                                                </em>
                                              </span>
                                            </span>
                                          </span>
                                        </div>
                                        <div style={{ lineHeight: "1.5" }}>
                                          <br />
                                        </div>
                                        <div style={{ lineHeight: "1.5" }}>
                                          <span
                                            style={{
                                              fontSize: 15,
                                              color: "rgb(89, 89, 89)"
                                            }}
                                          >
                                            <span
                                              style={{
                                                fontSize: 15,
                                                color: "rgb(89, 89, 89)"
                                              }}
                                            >
                                              <span data-custom-class="body_text">
                                                We may process or share your
                                                data that we hold based on the
                                                following legal basis:
                                                <div className="block-component" />
                                              </span>
                                            </span>
                                          </span>
                                        </div>
                                        <ul>
                                          <li style={{ lineHeight: "1.5" }}>
                                            <span
                                              style={{
                                                fontSize: 15,
                                                color: "rgb(89, 89, 89)"
                                              }}
                                            >
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                  color: "rgb(89, 89, 89)"
                                                }}
                                              >
                                                <span data-custom-class="body_text">
                                                  <strong>Consent:</strong> We
                                                  may process your data if you
                                                  have given us specific consent
                                                  to use your personal
                                                  information for a specific
                                                  purpose.
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: "rgb(89, 89, 89)"
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <div className="statement-end-if-in-editor" />
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </li>
                                        </ul>
                                        <div>
                                          <span
                                            style={{
                                              fontSize: 15,
                                              color: "rgb(89, 89, 89)"
                                            }}
                                          >
                                            <span
                                              style={{
                                                fontSize: 15,
                                                color: "rgb(89, 89, 89)"
                                              }}
                                            >
                                              <span data-custom-class="body_text">
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)"
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      <div className="statement-end-if-in-editor">
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)"
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <div className="block-component" />
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </div>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </div>
                                        <ul>
                                          <li style={{ lineHeight: "1.5" }}>
                                            <span
                                              style={{
                                                fontSize: 15,
                                                color: "rgb(89, 89, 89)"
                                              }}
                                            >
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                  color: "rgb(89, 89, 89)"
                                                }}
                                              >
                                                <span data-custom-class="body_text">
                                                  <strong>
                                                    Legitimate Interests:
                                                  </strong>{" "}
                                                  We may process your data when
                                                  it is reasonably necessary to
                                                  achieve our legitimate
                                                  business interests.
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: "rgb(89, 89, 89)"
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)"
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <div className="statement-end-if-in-editor" />
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </li>
                                        </ul>
                                        <div>
                                          <span
                                            style={{
                                              fontSize: 15,
                                              color: "rgb(89, 89, 89)"
                                            }}
                                          >
                                            <span
                                              style={{
                                                fontSize: 15,
                                                color: "rgb(89, 89, 89)"
                                              }}
                                            >
                                              <span data-custom-class="body_text">
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)"
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      <div className="block-component" />
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </div>
                                        <ul>
                                          <li style={{ lineHeight: "1.5" }}>
                                            <span
                                              style={{
                                                fontSize: 15,
                                                color: "rgb(89, 89, 89)"
                                              }}
                                            >
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                  color: "rgb(89, 89, 89)"
                                                }}
                                              >
                                                <span data-custom-class="body_text">
                                                  <strong>
                                                    Performance of a Contract:
                                                  </strong>{" "}
                                                  Where we have entered into a
                                                  contract with you, we may
                                                  process your personal
                                                  information to fulfill the
                                                  terms of our contract.
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: "rgb(89, 89, 89)"
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)"
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <div className="statement-end-if-in-editor" />
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </li>
                                        </ul>
                                        <div>
                                          <span
                                            style={{
                                              fontSize: 15,
                                              color: "rgb(89, 89, 89)"
                                            }}
                                          >
                                            <span
                                              style={{
                                                fontSize: 15,
                                                color: "rgb(89, 89, 89)"
                                              }}
                                            >
                                              <span data-custom-class="body_text">
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)"
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      <div className="block-component" />
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </div>
                                        <ul>
                                          <li style={{ lineHeight: "1.5" }}>
                                            <span
                                              style={{
                                                fontSize: 15,
                                                color: "rgb(89, 89, 89)"
                                              }}
                                            >
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                  color: "rgb(89, 89, 89)"
                                                }}
                                              >
                                                <span data-custom-class="body_text">
                                                  <strong>
                                                    Legal Obligations:
                                                  </strong>{" "}
                                                  We may disclose your
                                                  information where we are
                                                  legally required to do so in
                                                  order to comply with
                                                  applicable law, governmental
                                                  requests, a judicial
                                                  proceeding, court order, or
                                                  legal process, such as in
                                                  response to a court order or a
                                                  subpoena (including in
                                                  response to public authorities
                                                  to meet national security or
                                                  law enforcement requirements).
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: "rgb(89, 89, 89)"
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)"
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <div className="statement-end-if-in-editor" />
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </li>
                                        </ul>
                                        <div>
                                          <span
                                            style={{
                                              fontSize: 15,
                                              color: "rgb(89, 89, 89)"
                                            }}
                                          >
                                            <span
                                              style={{
                                                fontSize: 15,
                                                color: "rgb(89, 89, 89)"
                                              }}
                                            >
                                              <span data-custom-class="body_text">
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)"
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      <div className="block-component" />
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </div>
                                        <ul>
                                          <li style={{ lineHeight: "1.5" }}>
                                            <span
                                              style={{
                                                fontSize: 15,
                                                color: "rgb(89, 89, 89)"
                                              }}
                                            >
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                  color: "rgb(89, 89, 89)"
                                                }}
                                              >
                                                <span data-custom-class="body_text">
                                                  <strong>
                                                    Vital Interests:
                                                  </strong>{" "}
                                                  We may disclose your
                                                  information where we believe
                                                  it is necessary to
                                                  investigate, prevent, or take
                                                  action regarding potential
                                                  violations of our policies,
                                                  suspected fraud, situations
                                                  involving potential threats to
                                                  the safety of any person and
                                                  illegal activities, or as
                                                  evidence in litigation in
                                                  which we are involved.
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: "rgb(89, 89, 89)"
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)"
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <div className="statement-end-if-in-editor" />
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </li>
                                        </ul>
                                        <div style={{ lineHeight: "1.5" }}>
                                          <span
                                            style={{
                                              fontSize: 15,
                                              color: "rgb(89, 89, 89)"
                                            }}
                                          >
                                            <span
                                              style={{
                                                fontSize: 15,
                                                color: "rgb(89, 89, 89)"
                                              }}
                                            >
                                              <span data-custom-class="body_text">
                                                More specifically, we may need
                                                to process your data or share
                                                your personal information in the
                                                following situations:
                                              </span>
                                            </span>
                                          </span>
                                        </div>
                                        <ul>
                                          <li style={{ lineHeight: "1.5" }}>
                                            <span
                                              style={{
                                                fontSize: 15,
                                                color: "rgb(89, 89, 89)"
                                              }}
                                            >
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                  color: "rgb(89, 89, 89)"
                                                }}
                                              >
                                                <span data-custom-class="body_text">
                                                  <strong>
                                                    Business Transfers.
                                                  </strong>{" "}
                                                  We may share or transfer your
                                                  information in connection
                                                  with, or during negotiations
                                                  of, any merger, sale of
                                                  company assets, financing, or
                                                  acquisition of all or a
                                                  portion of our business to
                                                  another company.
                                                </span>
                                              </span>
                                            </span>
                                          </li>
                                        </ul>
                                        <div>
                                          <span
                                            style={{
                                              fontSize: 15,
                                              color: "rgb(89, 89, 89)"
                                            }}
                                          >
                                            <span
                                              style={{
                                                fontSize: 15,
                                                color: "rgb(89, 89, 89)"
                                              }}
                                            >
                                              <span data-custom-class="body_text">
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)"
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      <div className="block-component" />
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                          <div>
                                            <span style={{ fontSize: 15 }}>
                                              <span
                                                style={{
                                                  color: "rgb(89, 89, 89)"
                                                }}
                                              >
                                                <span data-custom-class="body_text">
                                                  <div className="block-component" />
                                                </span>
                                              </span>
                                            </span>
                                            <div>
                                              <span style={{ fontSize: 15 }}>
                                                <span
                                                  style={{
                                                    color: "rgb(89, 89, 89)"
                                                  }}
                                                >
                                                  <span data-custom-class="body_text">
                                                    <div className="block-component" />
                                                  </span>
                                                </span>
                                              </span>
                                              <div>
                                                <span style={{ fontSize: 15 }}>
                                                  <span
                                                    style={{
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)"
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <div className="block-component" />
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                                <div>
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    <span
                                                      style={{
                                                        color: "rgb(89, 89, 89)"
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <div className="block-component" />
                                                      </span>
                                                    </span>
                                                  </span>
                                                  <div>
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)"
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <div className="block-component" />
                                                        </span>
                                                      </span>
                                                    </span>
                                                    <div>
                                                      <span
                                                        style={{
                                                          fontSize: 15
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)"
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <div className="block-component" />
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                      <div
                                                        style={{
                                                          lineHeight: "1.5"
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)"
                                                          }}
                                                        >
                                                          <div className="block-component" />
                                                        </span>
                                                      </div>
                                                      <div>
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                            fontSize: 15
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <div className="block-component">
                                                                    <span data-custom-class="heading_1" />
                                                                  </div>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)"
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(89, 89, 89)"
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              <div className="block-component" />
                                                                            </span>
                                                                            <div className="block-component">
                                                                              <span data-custom-class="body_text">
                                                                                <div className="block-component">
                                                                                  <span
                                                                                    style={{
                                                                                      color:
                                                                                        "rgb(89, 89, 89)",
                                                                                      fontSize: 15
                                                                                    }}
                                                                                  >
                                                                                    <span
                                                                                      style={{
                                                                                        color:
                                                                                          "rgb(89, 89, 89)",
                                                                                        fontSize: 15
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          color:
                                                                                            "rgb(89, 89, 89)",
                                                                                          fontSize: 15
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            color:
                                                                                              "rgb(89, 89, 89)",
                                                                                            fontSize: 15
                                                                                          }}
                                                                                        >
                                                                                          <span
                                                                                            style={{
                                                                                              color:
                                                                                                "rgb(89, 89, 89)",
                                                                                              fontSize: 15
                                                                                            }}
                                                                                          >
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)"
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15
                                                                                                  }}
                                                                                                >
                                                                                                  <span
                                                                                                    style={{
                                                                                                      color:
                                                                                                        "rgb(89, 89, 89)"
                                                                                                    }}
                                                                                                  >
                                                                                                    <span data-custom-class="body_text">
                                                                                                      <div className="block-component" />
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </div>
                                                                              </span>
                                                                            </div>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </div>
                                                      <div
                                                        style={{
                                                          lineHeight: "1.5"
                                                        }}
                                                      >
                                                        <br />
                                                      </div>
                                                      <div
                                                        id="inforetain"
                                                        style={{
                                                          lineHeight: "1.5"
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(127, 127, 127)"
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                              fontSize: 15
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span
                                                                  id="control"
                                                                  style={{
                                                                    color:
                                                                      "rgb(0, 0, 0)"
                                                                  }}
                                                                >
                                                                  <strong>
                                                                    <span
                                                                      data-custom-class="heading_1"
                                                                      style={{
                                                                        opacity: 0.7
                                                                      }}
                                                                    >
                                                                      4. HOW
                                                                      LONG DO WE
                                                                      KEEP YOUR
                                                                      INFORMATION?
                                                                    </span>
                                                                  </strong>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </div>
                                                      <div
                                                        style={{
                                                          lineHeight: "1.5"
                                                        }}
                                                      >
                                                        <br />
                                                      </div>
                                                      <div
                                                        style={{
                                                          lineHeight: "1.5"
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)"
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <strong>
                                                                <em>
                                                                  In
                                                                  Short:&nbsp;
                                                                </em>
                                                              </strong>
                                                              <em>
                                                                &nbsp;We keep
                                                                your information
                                                                for as long as
                                                                necessary to
                                                                fulfill the
                                                                purposes
                                                                outlined in this
                                                                privacy notice
                                                                unless otherwise
                                                                required by law.
                                                              </em>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </div>
                                                      <div
                                                        style={{
                                                          lineHeight: "1.5"
                                                        }}
                                                      >
                                                        <br />
                                                      </div>
                                                      <div
                                                        style={{
                                                          lineHeight: "1.5"
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)"
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              We will only keep
                                                              your personal
                                                              information for as
                                                              long as it is
                                                              necessary for the
                                                              purposes set out
                                                              in this privacy
                                                              notice, unless a
                                                              longer retention
                                                              period is required
                                                              or permitted by
                                                              law (such as tax,
                                                              accounting or
                                                              other legal
                                                              requirements). No
                                                              purpose in this
                                                              notice will
                                                              require us keeping
                                                              your personal
                                                              information for
                                                              longer than{" "}
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <div className="block-component" />
                                                                  </span>
                                                                </span>
                                                              </span>
                                                              <div className="block-component" />
                                                              the period of time
                                                              in which users
                                                              have an account
                                                              with us
                                                              <div className="block-component" />
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <div className="else-block" />
                                                                  </span>
                                                                </span>
                                                              </span>
                                                              .
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </div>
                                                      <div
                                                        style={{
                                                          lineHeight: "1.5"
                                                        }}
                                                      >
                                                        <br />
                                                      </div>
                                                      <div
                                                        style={{
                                                          lineHeight: "1.5"
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)"
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              When we have no
                                                              ongoing legitimate
                                                              business need to
                                                              process your
                                                              personal
                                                              information, we
                                                              will either delete
                                                              or anonymize such
                                                              information, or,
                                                              if this is not
                                                              possible (for
                                                              example, because
                                                              your personal
                                                              information has
                                                              been stored in
                                                              backup archives),
                                                              then we will
                                                              securely store
                                                              your personal
                                                              information and
                                                              isolate it from
                                                              any further
                                                              processing until
                                                              deletion is
                                                              possible.
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <div className="block-component" />
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </div>
                                                      <div
                                                        style={{
                                                          lineHeight: "1.5"
                                                        }}
                                                      >
                                                        <br />
                                                      </div>
                                                      <div
                                                        id="infosafe"
                                                        style={{
                                                          lineHeight: "1.5"
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(127, 127, 127)"
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                              fontSize: 15
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span
                                                                  id="control"
                                                                  style={{
                                                                    color:
                                                                      "rgb(0, 0, 0)"
                                                                  }}
                                                                >
                                                                  <strong>
                                                                    <span
                                                                      data-custom-class="heading_1"
                                                                      style={{
                                                                        opacity: 0.7
                                                                      }}
                                                                    >
                                                                      5. HOW DO
                                                                      WE KEEP
                                                                      YOUR
                                                                      INFORMATION
                                                                      SAFE?
                                                                    </span>
                                                                  </strong>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </div>
                                                      <div
                                                        style={{
                                                          lineHeight: "1.5"
                                                        }}
                                                      >
                                                        <br />
                                                      </div>
                                                      <div
                                                        style={{
                                                          lineHeight: "1.5"
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)"
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <strong>
                                                                <em>
                                                                  In
                                                                  Short:&nbsp;
                                                                </em>
                                                              </strong>
                                                              <em>
                                                                &nbsp;We aim to
                                                                protect your
                                                                personal
                                                                information
                                                                through a system
                                                                of
                                                                organizational
                                                                and technical
                                                                security
                                                                measures.
                                                              </em>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </div>
                                                      <div
                                                        style={{
                                                          lineHeight: "1.5"
                                                        }}
                                                      >
                                                        <br />
                                                      </div>
                                                      <div
                                                        style={{
                                                          lineHeight: "1.5"
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)"
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              We have
                                                              implemented
                                                              appropriate
                                                              technical and
                                                              organizational
                                                              security measures
                                                              designed to
                                                              protect the
                                                              security of any
                                                              personal
                                                              information we
                                                              process. However,
                                                              despite our
                                                              safeguards and
                                                              efforts to secure
                                                              your information,
                                                              no electronic
                                                              transmission over
                                                              the Internet or
                                                              information
                                                              storage technology
                                                              can be guaranteed
                                                              to be 100% secure,
                                                              so we cannot
                                                              promise or
                                                              guarantee that
                                                              hackers,
                                                              cybercriminals, or
                                                              other unauthorized
                                                              third parties will
                                                              not be able to
                                                              defeat our
                                                              security, and
                                                              improperly
                                                              collect, access,
                                                              steal, or modify
                                                              your information.
                                                              Although we will
                                                              do our best to
                                                              protect your
                                                              personal
                                                              information,
                                                              transmission of
                                                              personal
                                                              information to and
                                                              from our{" "}
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              <div className="block-component" />
                                                                              Website
                                                                              <div className="block-component" />
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>{" "}
                                                              is at your own
                                                              risk. You should
                                                              only access the{" "}
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              <div className="block-component" />
                                                                              Website
                                                                              <div className="block-component" />
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>{" "}
                                                              within a secure
                                                              environment.
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <div className="statement-end-if-in-editor" />
                                                              </span>
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <div className="block-component" />
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </div>
                                                      <div
                                                        style={{
                                                          lineHeight: "1.5"
                                                        }}
                                                      >
                                                        <br />
                                                      </div>
                                                      <div
                                                        id="infominors"
                                                        style={{
                                                          lineHeight: "1.5"
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(127, 127, 127)"
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                              fontSize: 15
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span
                                                                  id="control"
                                                                  style={{
                                                                    color:
                                                                      "rgb(0, 0, 0)"
                                                                  }}
                                                                >
                                                                  <strong>
                                                                    <span
                                                                      data-custom-class="heading_1"
                                                                      style={{
                                                                        opacity: 0.7
                                                                      }}
                                                                    >
                                                                      6. DO WE
                                                                      COLLECT
                                                                      INFORMATION
                                                                      FROM
                                                                      MINORS?
                                                                    </span>
                                                                  </strong>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </div>
                                                      <div
                                                        style={{
                                                          lineHeight: "1.5"
                                                        }}
                                                      >
                                                        <br />
                                                      </div>
                                                      <div
                                                        style={{
                                                          lineHeight: "1.5"
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)"
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <strong>
                                                                <em>
                                                                  In Short:
                                                                </em>
                                                              </strong>
                                                              <em>
                                                                &nbsp; We do not
                                                                knowingly
                                                                collect data
                                                                from or market
                                                                to children
                                                                under 18 years
                                                                of age.
                                                              </em>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </div>
                                                      <div
                                                        style={{
                                                          lineHeight: "1.5"
                                                        }}
                                                      >
                                                        <br />
                                                      </div>
                                                      <div
                                                        style={{
                                                          lineHeight: "1.5"
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)"
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              We do not
                                                              knowingly solicit
                                                              data from or
                                                              market to children
                                                              under 18 years of
                                                              age. By using the{" "}
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              <div className="block-component" />
                                                                              Website
                                                                              <div className="block-component" />
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                              , you represent
                                                              that you are at
                                                              least 18 or that
                                                              you are the parent
                                                              or guardian of
                                                              such a minor and
                                                              consent to such
                                                              minor dependent’s
                                                              use of the{" "}
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              <div className="block-component" />
                                                                              Website
                                                                              <div className="block-component" />
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                              . If we learn that
                                                              personal
                                                              information from
                                                              users less than 18
                                                              years of age has
                                                              been collected, we
                                                              will deactivate
                                                              the account and
                                                              take reasonable
                                                              measures to
                                                              promptly delete
                                                              such data from our
                                                              records. If you
                                                              become aware of
                                                              any data we may
                                                              have collected
                                                              from children
                                                              under age 18,
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <div className="statement-end-if-in-editor" />
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </div>
                                                      <div
                                                        style={{
                                                          lineHeight: "1.5"
                                                        }}
                                                      >
                                                        <br />
                                                      </div>
                                                      <div
                                                        id="privacyrights"
                                                        style={{
                                                          lineHeight: "1.5"
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(127, 127, 127)"
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                              fontSize: 15
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span
                                                                  id="control"
                                                                  style={{
                                                                    color:
                                                                      "rgb(0, 0, 0)"
                                                                  }}
                                                                >
                                                                  <strong>
                                                                    <span
                                                                      data-custom-class="heading_1"
                                                                      style={{
                                                                        opacity: 0.7
                                                                      }}
                                                                    >
                                                                      7. WHAT
                                                                      ARE YOUR
                                                                      PRIVACY
                                                                      RIGHTS?
                                                                    </span>
                                                                  </strong>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </div>
                                                      <div
                                                        style={{
                                                          lineHeight: "1.5"
                                                        }}
                                                      >
                                                        <br />
                                                      </div>
                                                      <div
                                                        style={{
                                                          lineHeight: "1.5"
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)"
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <strong>
                                                                <em>
                                                                  In Short:
                                                                </em>
                                                              </strong>
                                                              <em>
                                                                &nbsp;{" "}
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <em>
                                                                        <div className="block-component" />
                                                                      </em>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                                You may review,
                                                                change, or
                                                                terminate your
                                                                account at any
                                                                time.
                                                              </em>
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <div className="block-component" />
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </div>
                                                      <div
                                                        style={{
                                                          lineHeight: "1.5"
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)"
                                                          }}
                                                        >
                                                          &nbsp;
                                                        </span>
                                                      </div>
                                                      <div
                                                        style={{
                                                          lineHeight: "1.5"
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)"
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              If you are a
                                                              resident in the
                                                              EEA or UK and you
                                                              believe we are
                                                              unlawfully
                                                              processing your
                                                              personal
                                                              information, you
                                                              also have the
                                                              right to complain
                                                              to your local data
                                                              protection
                                                              supervisory
                                                              authority. You can
                                                              find their contact
                                                              details here:{" "}
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(48, 48, 241)"
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <a
                                                                          data-custom-class="link"
                                                                          href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                                                                          rel="noopener noreferrer"
                                                                          target="_blank"
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15
                                                                            }}
                                                                          >
                                                                            https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
                                                                          </span>
                                                                        </a>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                              .
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </div>
                                                      <div
                                                        style={{
                                                          lineHeight: "1.5"
                                                        }}
                                                      >
                                                        <br />
                                                      </div>
                                                      <div
                                                        style={{
                                                          lineHeight: "1.5"
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)"
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              If you are a
                                                              resident in
                                                              Switzerland, the
                                                              contact details
                                                              for the data
                                                              protection
                                                              authorities are
                                                              available here:{" "}
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(48, 48, 241)"
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15
                                                                          }}
                                                                        >
                                                                          <a
                                                                            data-custom-class="link"
                                                                            href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                                                                            rel="noopener noreferrer"
                                                                            target="_blank"
                                                                          >
                                                                            https://www.edoeb.admin.ch/edoeb/en/home.html
                                                                          </a>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                              .
                                                              <div className="block-component" />
                                                              <div className="block-component" />
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </div>
                                                      <div
                                                        style={{
                                                          lineHeight: "1.5"
                                                        }}
                                                      >
                                                        <br />
                                                      </div>
                                                      <div
                                                        style={{
                                                          lineHeight: "1.5"
                                                        }}
                                                      >
                                                        <span
                                                          data-custom-class="heading_2"
                                                          style={{
                                                            color:
                                                              "rgb(0, 0, 0)"
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15
                                                            }}
                                                          >
                                                            <strong>
                                                              Account
                                                              Information
                                                            </strong>
                                                          </span>
                                                        </span>
                                                      </div>
                                                      <div>
                                                        <div>
                                                          <br />
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(127, 127, 127)"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      If you
                                                                      would at
                                                                      any time
                                                                      like to
                                                                      review or
                                                                      change the
                                                                      information
                                                                      in your
                                                                      account or
                                                                      terminate
                                                                      your
                                                                      account,
                                                                      you can:
                                                                      <div className="forloop-component" />
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <ul>
                                                          <li
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(127, 127, 127)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <div className="question">
                                                                          Log in
                                                                          to
                                                                          your
                                                                          account
                                                                          settings
                                                                          and
                                                                          update
                                                                          your
                                                                          user
                                                                          account.
                                                                        </div>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </li>
                                                        </ul>
                                                        <div>
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(127, 127, 127)"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <div className="forloop-component" />
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <ul>
                                                          <li
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(127, 127, 127)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <div className="question">
                                                                          Contact
                                                                          us
                                                                          using
                                                                          the
                                                                          contact
                                                                          information
                                                                          provided.
                                                                        </div>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </li>
                                                        </ul>
                                                        <div>
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(127, 127, 127)"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <div className="forloop-component" />
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                Upon your
                                                                request to
                                                                terminate your
                                                                account, we will
                                                                deactivate or
                                                                delete your
                                                                account and
                                                                information from
                                                                our active
                                                                databases.
                                                                However, we may
                                                                retain some
                                                                information in
                                                                our files to
                                                                prevent fraud,
                                                                troubleshoot
                                                                problems, assist
                                                                with any
                                                                investigations,
                                                                enforce our
                                                                Terms of Use
                                                                and/or comply
                                                                with applicable
                                                                legal
                                                                requirements.
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  "rgb(89, 89, 89)"
                                                                              }}
                                                                            >
                                                                              <div className="statement-end-if-in-editor" />
                                                                              <div className="block-component" />
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                                <span data-custom-class="body_text">
                                                                  <div className="block-component" />
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <strong>
                                                                  <u>
                                                                    Opting out
                                                                    of email
                                                                    marketing:
                                                                  </u>
                                                                </strong>{" "}
                                                                You can
                                                                unsubscribe from
                                                                our marketing
                                                                email list at
                                                                any time by
                                                                clicking on the
                                                                unsubscribe link
                                                                in the emails
                                                                that we send or
                                                                by contacting us
                                                                using the
                                                                details provided
                                                                below. You will
                                                                then be removed
                                                                from the
                                                                marketing email
                                                                list — however,
                                                                we may still
                                                                communicate with
                                                                you, for example
                                                                to send you
                                                                service-related
                                                                emails that are
                                                                necessary for
                                                                the
                                                                administration
                                                                and use of your
                                                                account, to
                                                                respond to
                                                                service
                                                                requests, or for
                                                                other
                                                                non-marketing
                                                                purposes. To
                                                                otherwise
                                                                opt-out, you
                                                                may:
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <div className="forloop-component" />
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <ul>
                                                          <li
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <div className="question">
                                                              <span data-custom-class="body_text">
                                                                Access your
                                                                account settings
                                                                and update your
                                                                preferences.
                                                              </span>
                                                            </div>
                                                          </li>
                                                        </ul>
                                                        <div>
                                                          <div className="forloop-component">
                                                            <span data-custom-class="body_text" />
                                                          </div>
                                                        </div>
                                                        <ul>
                                                          <li
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <div className="question">
                                                              <span data-custom-class="body_text">
                                                                Contact us using
                                                                the contact
                                                                information
                                                                provided.
                                                              </span>
                                                            </div>
                                                          </li>
                                                        </ul>
                                                        <div>
                                                          <div className="forloop-component">
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15
                                                                            }}
                                                                          >
                                                                            <div className="statement-end-if-in-editor" />
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          id="DNT"
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(127, 127, 127)"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    id="control"
                                                                    style={{
                                                                      color:
                                                                        "rgb(0, 0, 0)"
                                                                    }}
                                                                  >
                                                                    <strong>
                                                                      <span
                                                                        data-custom-class="heading_1"
                                                                        style={{
                                                                          opacity: 0.7
                                                                        }}
                                                                      >
                                                                        8.
                                                                        CONTROLS
                                                                        FOR
                                                                        DO-NOT-TRACK
                                                                        FEATURES
                                                                      </span>
                                                                    </strong>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                Most web
                                                                browsers and
                                                                some mobile
                                                                operating
                                                                systems and
                                                                mobile
                                                                applications
                                                                include a
                                                                Do-Not-Track
                                                                ("DNT") feature
                                                                or setting you
                                                                can activate to
                                                                signal your
                                                                privacy
                                                                preference not
                                                                to have data
                                                                about your
                                                                online browsing
                                                                activities
                                                                monitored and
                                                                collected. At
                                                                this stage no
                                                                uniform
                                                                technology
                                                                standard for
                                                                recognizing and
                                                                implementing DNT
                                                                signals has been
                                                                finalized. As
                                                                such, we do not
                                                                currently
                                                                respond to DNT
                                                                browser signals
                                                                or any other
                                                                mechanism that
                                                                automatically
                                                                communicates
                                                                your choice not
                                                                to be tracked
                                                                online. If a
                                                                standard for
                                                                online tracking
                                                                is adopted that
                                                                we must follow
                                                                in the future,
                                                                we will inform
                                                                you about that
                                                                practice in a
                                                                revised version
                                                                of this privacy
                                                                notice.
                                                              </span>
                                                            </span>
                                                            &nbsp;
                                                          </span>
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          id="caresidents"
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(127, 127, 127)"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    id="control"
                                                                    style={{
                                                                      color:
                                                                        "rgb(0, 0, 0)"
                                                                    }}
                                                                  >
                                                                    <strong>
                                                                      <span
                                                                        data-custom-class="heading_1"
                                                                        style={{
                                                                          opacity: 0.7
                                                                        }}
                                                                      >
                                                                        9. DO
                                                                        CALIFORNIA
                                                                        RESIDENTS
                                                                        HAVE
                                                                        SPECIFIC
                                                                        PRIVACY
                                                                        RIGHTS?
                                                                      </span>
                                                                    </strong>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <strong>
                                                                  <em>
                                                                    In
                                                                    Short:&nbsp;
                                                                  </em>
                                                                </strong>
                                                                <em>
                                                                  &nbsp;Yes, if
                                                                  you are a
                                                                  resident of
                                                                  California,
                                                                  you are
                                                                  granted
                                                                  specific
                                                                  rights
                                                                  regarding
                                                                  access to your
                                                                  personal
                                                                  information.
                                                                </em>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                California Civil
                                                                Code Section
                                                                1798.83, also
                                                                known as the
                                                                "Shine The
                                                                Light" law,
                                                                permits our
                                                                users who are
                                                                California
                                                                residents to
                                                                request and
                                                                obtain from us,
                                                                once a year and
                                                                free of charge,
                                                                information
                                                                about categories
                                                                of personal
                                                                information (if
                                                                any) we
                                                                disclosed to
                                                                third parties
                                                                for direct
                                                                marketing
                                                                purposes and the
                                                                names and
                                                                addresses of all
                                                                third parties
                                                                with which we
                                                                shared personal
                                                                information in
                                                                the immediately
                                                                preceding
                                                                calendar year.
                                                                If you are a
                                                                California
                                                                resident and
                                                                would like to
                                                                make such a
                                                                request, please
                                                                submit your
                                                                request in
                                                                writing to us
                                                                using the
                                                                contact
                                                                information
                                                                provided below.
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                If you are under
                                                                18 years of age,
                                                                reside in
                                                                California, and
                                                                have a
                                                                registered
                                                                account with{" "}
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                "rgb(89, 89, 89)"
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15
                                                                              }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                <div className="block-component" />
                                                                                the
                                                                                Website
                                                                                <div className="block-component" />
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                                , you have the
                                                                right to request
                                                                removal of
                                                                unwanted data
                                                                that you
                                                                publicly post on
                                                                the{" "}
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                "rgb(89, 89, 89)"
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15
                                                                              }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                <div className="block-component" />
                                                                                Website
                                                                                <div className="block-component" />
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                                . To request
                                                                removal of such
                                                                data, please
                                                                contact us using
                                                                the contact
                                                                information
                                                                provided below,
                                                                and include the
                                                                email address
                                                                associated with
                                                                your account and
                                                                a statement that
                                                                you reside in
                                                                California. We
                                                                will make sure
                                                                the data is not
                                                                publicly
                                                                displayed on the{" "}
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                "rgb(89, 89, 89)"
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15
                                                                              }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                <div className="block-component" />
                                                                                Website
                                                                                <div className="block-component" />
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                                , but please be
                                                                aware that the
                                                                data may not be
                                                                completely or
                                                                comprehensively
                                                                removed from all
                                                                our systems
                                                                (e.g. backups,
                                                                etc.).
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <div className="block-component" />
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          id="policyupdates"
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(127, 127, 127)"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    id="control"
                                                                    style={{
                                                                      color:
                                                                        "rgb(0, 0, 0)"
                                                                    }}
                                                                  >
                                                                    <strong>
                                                                      <span
                                                                        data-custom-class="heading_1"
                                                                        style={{
                                                                          opacity: 0.7
                                                                        }}
                                                                      >
                                                                        10. DO
                                                                        WE MAKE
                                                                        UPDATES
                                                                        TO THIS
                                                                        NOTICE?
                                                                      </span>
                                                                    </strong>
                                                                    &nbsp;
                                                                  </span>
                                                                  &nbsp;
                                                                </span>
                                                                &nbsp;
                                                              </span>
                                                              &nbsp;
                                                            </span>
                                                            &nbsp;
                                                          </span>
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <em>
                                                                  <strong>
                                                                    In
                                                                    Short:&nbsp;
                                                                  </strong>{" "}
                                                                  Yes, we will
                                                                  update this
                                                                  notice as
                                                                  necessary to
                                                                  stay compliant
                                                                  with relevant
                                                                  laws.
                                                                </em>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                We may update
                                                                this privacy
                                                                notice from time
                                                                to time. The
                                                                updated version
                                                                will be
                                                                indicated by an
                                                                updated
                                                                "Revised" date
                                                                and the updated
                                                                version will be
                                                                effective as
                                                                soon as it is
                                                                accessible. If
                                                                we make material
                                                                changes to this
                                                                privacy notice,
                                                                we may notify
                                                                you either by
                                                                prominently
                                                                posting a notice
                                                                of such changes
                                                                or by directly
                                                                sending you a
                                                                notification. We
                                                                encourage you to
                                                                review this
                                                                privacy notice
                                                                frequently to be
                                                                informed of how
                                                                we are
                                                                protecting your
                                                                information.
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          id="contact"
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(127, 127, 127)"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    id="control"
                                                                    style={{
                                                                      color:
                                                                        "rgb(0, 0, 0)"
                                                                    }}
                                                                  >
                                                                    <strong>
                                                                      <span
                                                                        data-custom-class="heading_1"
                                                                        style={{
                                                                          opacity: 0.7
                                                                        }}
                                                                      >
                                                                        11. HOW
                                                                        CAN YOU
                                                                        CONTACT
                                                                        US ABOUT
                                                                        THIS
                                                                        NOTICE?
                                                                      </span>
                                                                    </strong>
                                                                    &nbsp;
                                                                  </span>
                                                                  &nbsp;
                                                                </span>
                                                                &nbsp;
                                                              </span>
                                                              &nbsp;
                                                            </span>
                                                            &nbsp;
                                                          </span>
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                If you have
                                                                questions or
                                                                comments about
                                                                this notice, you
                                                                may{" "}
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <div className="block-component" />
                                                                  </span>
                                                                </span>
                                                                contact our Data
                                                                Protection
                                                                Officer (DPO)
                                                                <div className="block-component" />
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <div className="question">
                                                                      Suprem
                                                                      Nandal
                                                                    </div>
                                                                  </span>
                                                                </span>
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <div className="statement-end-if-in-editor" />
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      &nbsp;by
                                                                      email
                                                                      at&nbsp;
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <div className="question">
                                                                      suprem@octap.in
                                                                    </div>
                                                                  </span>
                                                                </span>
                                                                <div className="block-component" />
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      &nbsp;by
                                                                      phone at{" "}
                                                                      <div className="question">
                                                                        +919560787007
                                                                      </div>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                                <div className="statement-end-if-in-editor">
                                                                  <span data-custom-class="body_text" />
                                                                </div>
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <div className="else-block" />
                                                                  </span>
                                                                </span>
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      &nbsp;or
                                                                      by post
                                                                      to:
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </div>

                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <div className="question">
                                                                          octopusCO
                                                                          Soft
                                                                          Solutions
                                                                          Pvt.
                                                                          Ltd.
                                                                        </div>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                              <span data-custom-class="body_text">
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <div className="block-component">
                                                                      <div className="block-component" />
                                                                    </div>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <div className="question">
                                                                    Suprem
                                                                    Nandal
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(89, 89, 89)"
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              <div className="block-component">
                                                                                <div className="statement-end-if-in-editor" />
                                                                              </div>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                    <span data-custom-class="body_text">
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <div className="block-component" />
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <div className="question">
                                                                    168 Salahpur
                                                                    Majra Dabas
                                                                    <br />
                                                                    North West
                                                                    Delhi <br />{" "}
                                                                    Delhi
                                                                    <br />
                                                                    110081
                                                                    <span data-custom-class="body_text">
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <div className="block-component" />
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <div className="question"></div>
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <div className="block-component" />

                                                                <div className="question"></div>
                                                                <div className="statement-end-if-in-editor" />
                                                              </span>
                                                              <div className="block-component" />{" "}
                                                              <div className="question"></div>
                                                              <div className="statement-end-if-in-editor" />
                                                              <span data-custom-class="body_text">
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <div className="block-component" />{" "}
                                                                  <div className="block-component" />
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <div className="block-component" />
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                                <div className="question">
                                                                  India
                                                                </div>
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <div className="statement-end-if-in-editor" />
                                                                      <div className="statement-end-if-in-editor" />
                                                                      <div className="statement-end-if-in-editor" />
                                                                    </span>
                                                                  </span>
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <div className="else-block">
                                                                      <span data-custom-class="body_text" />
                                                                    </div>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                          <span
                                                            data-custom-class="body_text"
                                                            style={{
                                                              fontSize: 15
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <div className="else-block" />
                                                            </span>
                                                          </span>
                                                          <span
                                                            style={{
                                                              fontSize: 15
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <div className="statement-end-if-in-editor">
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15
                                                                          }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            <div className="block-component">
                                                                              <div className="block-component" />
                                                                            </div>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </div>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <div className="statement-end-if-in-editor">
                                                                          <div className="block-component" />
                                                                        </div>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          id="request"
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(127, 127, 127)"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    id="control"
                                                                    style={{
                                                                      color:
                                                                        "rgb(0, 0, 0)"
                                                                    }}
                                                                  >
                                                                    <strong>
                                                                      <span
                                                                        data-custom-class="heading_1"
                                                                        style={{
                                                                          opacity: 0.7
                                                                        }}
                                                                      >
                                                                        12. HOW
                                                                        CAN YOU
                                                                        REVIEW,
                                                                        UPDATE,
                                                                        OR
                                                                        DELETE
                                                                        THE DATA
                                                                        WE
                                                                        COLLECT
                                                                        FROM
                                                                        YOU?
                                                                      </span>
                                                                    </strong>
                                                                    &nbsp;
                                                                  </span>
                                                                  &nbsp;
                                                                </span>
                                                                &nbsp;
                                                              </span>
                                                              &nbsp;
                                                            </span>
                                                            &nbsp;
                                                          </span>
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                Based on the
                                                                applicable laws
                                                                of your country,
                                                                you may have the
                                                                right to request
                                                                access to the
                                                                personal
                                                                information we
                                                                collect from
                                                                you, change that
                                                                information, or
                                                                delete it in
                                                                some
                                                                circumstances.
                                                                To request to
                                                                review, update,
                                                                or delete your
                                                                personal
                                                                information,
                                                                please{" "}
                                                                <div className="block-component" />
                                                              </span>
                                                              <span data-custom-class="body_text">
                                                                visit:{" "}
                                                                <div className="question">
                                                                  <a
                                                                    href="https://octap.in"
                                                                    target="_blank"
                                                                    data-custom-class="link"
                                                                    style={{
                                                                      textDecoration:
                                                                        "none"
                                                                    }}
                                                                  >
                                                                    octap.in
                                                                  </a>
                                                                </div>
                                                                <div className="else-block" />
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="error">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default PrivacyPolicy;
