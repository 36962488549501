import clsx from "clsx";
import React, { memo } from "react";
import { Icon } from "@iconify/react";
import { Link as RouterLink } from "react-router-dom";
import shoppingCartFill from "@iconify-icons/eva/shopping-cart-fill";
import { makeStyles } from "@material-ui/core/styles";
import { Badge, Box } from "@material-ui/core";
import Tooltip from "@mui/material/Tooltip";

// ----------------------------------------------------------------------

const useStyles = makeStyles(theme => ({
  root: {
    right: 0,
    display: "flex",
    position: "fixed",
    alignItems: "center",
    top: theme.spacing(21),
    height: theme.spacing(5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(1.25),
    boxShadow: theme.shadows[25].z20,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    borderTopLeftRadius: theme.shape.borderRadiusMd,
    borderBottomLeftRadius: theme.shape.borderRadiusMd,
    transition: theme.transitions.create("opacity"),
    cursor: "pointer",
    "&:hover": { opacity: 0.72 }
  }
}));

// ----------------------------------------------------------------------

function CartAction({ cartLength, handleShowCart }) {
  const classes = useStyles();

  const title =
    cartLength > 1
      ? `${cartLength} items in cart`
      : `${cartLength} item in cart`;

  return (
    <Tooltip title={title} placement="top-start">
      <Box className={classes.root} onClick={() => handleShowCart(true)}>
        <Badge showZero badgeContent={cartLength} color="error" max={99}>
          <Icon icon={shoppingCartFill} width={24} height={24} />
        </Badge>
      </Box>
    </Tooltip>
  );
}

export default memo(CartAction);
