import React from "react";
import { Icon } from "@iconify/react";
import plusFill from "@iconify-icons/eva/plus-fill";
import minusFill from "@iconify-icons/eva/minus-fill";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { MIconButton } from "~/@material-extend";
import trashOutline from "@iconify-icons/eva/trash-outline";

import { experimentalStyled as styled } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  quantityLabel: {
    color: theme.palette.primary.main
  }
}));

const IncrementerStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: theme.spacing(0.5),
  padding: theme.spacing(0.5, 0.75),
  borderRadius: theme.shape.borderRadius,
  border: `solid 1px ${theme.palette.primary.main}`,
  color: "#FFF"
  // backgroundColor: theme.palette.primary.main
}));

function CartIncrement({ quantity, onRemove, onChange }) {
  const classes = useStyles();

  return (
    <Box sx={{ textAlign: "center" }}>
      <IncrementerStyle>
        {quantity === 1 ? (
          <MIconButton size="small" color="primary" onClick={onRemove}>
            <Icon icon={trashOutline} width={16} height={16} />
          </MIconButton>
        ) : (
          <MIconButton
            size="small"
            color="primary"
            onClick={() => {
              onChange(quantity - 1);
            }}
            disabled={quantity <= 1}
          >
            <Icon icon={minusFill} width={16} height={16} />
          </MIconButton>
        )}
        <span className={classes.quantityLabel}>
          <b>{quantity}</b>
        </span>
        <MIconButton
          size="small"
          color="primary"
          onClick={() => {
            onChange(quantity + 1);
          }}
        >
          <Icon icon={plusFill} width={16} height={16} />
        </MIconButton>
      </IncrementerStyle>
    </Box>
  );
}

export default CartIncrement;
