import LocalServices from "~/services/LocalServices";

const BASE_URL = process.env.REACT_APP_API_URL;
const NODE_JS_BASE_URL = process.env.REACT_APP_NODE_JS_BASE_URL;

export default class MyUrlServices {
  static async getAuthUrl() {
    const baseURL = LocalServices.getBaseUrl();
    return `${BASE_URL}/tenant/api/login/`.toString();
  }

  static async getBranchBase(add) {
    const baseURL = await LocalServices.getBaseUrl().toString();
    if (add) {
      return `${BASE_URL}/store/api/store/${add}/`.toString();
    }
    return `${BASE_URL}/store/api/store/`.toString();
  }

  static async getEmployeeBase(add) {
    const baseURL = await LocalServices.getBaseUrl().toString();
    if (add) {
      return `${BASE_URL}/tenant/api/staff/${add}/`.toString();
    }
    return `${BASE_URL}/tenant/api/staff/`.toString();
  }

  static async getFormBase(add) {
    const baseURL = await LocalServices.getBaseUrl().toString();
    if (add) {
      return `${BASE_URL}/feedback/api/form/${add}/`.toString();
    }
    return `${BASE_URL}/tenant/api/staff/`.toString();
  }

  static async getMenusBaseNoAuth(add, tenant) {
    return `${BASE_URL}/restaurant/api/menu/time/${add}`.toString();
  }

  static async getMenusBase(add) {
    const baseURL = await LocalServices.getBaseUrl().toString();
    return `${BASE_URL}/restaurant/api/menu/time/${add}`.toString();
  }

  static async getFoodOrderBase(tenant) {
    // const baseURL = await LocalServices.getBaseUrl().toString();
    // https://sup.we4.io/restaurant/api/menu/order/create/

    return `${BASE_URL}/restaurant/api/menu/order/create/`.toString();
  }

  static async getSignUpBase(tenant) {
    // const baseURL = await LocalServices.getBaseUrl().toString();

    return `${BASE_URL}/tenant/api/menu/signup/`.toString();
  }

  static async getMenuViewBase(add) {
    const baseURL = await LocalServices.getBaseUrl().toString();
    return `${BASE_URL}/restaurant/api/menu/${add}`.toString();
  }

  static async getMenuViewBaseNoAuth(add, tenant) {
    return `${BASE_URL}/restaurant/api/menu/${add}`.toString();
  }

  static async getMenuViewBaseSlugNoAuth(slug, tenant) {
    return `${BASE_URL}/restaurant/api/store/web/${slug}/`.toString();
  }

  static async getOrderStatusBaseNoAuth(add, tenant) {
    return `${BASE_URL}/restaurant/api/menu/store/order/web/new/${add}/`.toString();
  }

  static async getMenuProfileBase(add, tenant) {
    return `${BASE_URL}/store/api/store/retrieve/${add}/`.toString();
  }

  static async postItemAnalyticsBase(tenant) {
    return `${BASE_URL}/restaurant/api/menus/order/item/inc/`.toString();
  }

  static async getSendOtp(otp, phone, branch) {
    return `${BASE_URL}/restaurant/api/send-otp/${phone}/${otp}/${branch}/`.toString();
  }

  // ****************************************************Node.js APIs****************************************************

  static async getMenuViewBaseNoAuthNodeJs(id) {
    return `${NODE_JS_BASE_URL}/restaurant/api/menu/${id}`.toString();
  }

  static async getMenuViewBaseSlugNoAuthNodeJs(slug) {
    const currentDateTime = new Date();
    const currentTime = `${currentDateTime.getHours()}-${currentDateTime.getMinutes()}`;
    return `${NODE_JS_BASE_URL}/profile/web/${slug}/${currentTime}`;
  }

  static async getFoodOrderBaseNodeJs(profileId) {
    return `${NODE_JS_BASE_URL}/order/create/web/${profileId}`.toString();
  }

  static async getOrderStatusBaseNoAuthNodeJs(slug) {
    return `${NODE_JS_BASE_URL}/order/status/${slug}/`;
  }

  static async getSendOtpNodeJs(countryCode, phone, otp) {
    // order/send-otp/:countryCode/:phone/:otp
    return `${NODE_JS_BASE_URL}/order/send-otp/${countryCode}/${phone}/${otp}/`;
  }

  // ****************************************************Node.js APIs****************************************************
}
