import { Grid, Typography } from "@material-ui/core";
import placeholder from "../../../images/placeholder_category.png";
import { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import arrowIosDownwardFill from "@iconify-icons/eva/arrow-ios-downward-fill";
import Capitalize from "~/views/common/Capitalize";
import MenuItemView from "./MenuItemView";

function CategoryTile({ category, index, menuConfig, isViewMode, profile }) {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    setExpanded(!category.collapsed);
  }, [category]);

  const getImageView = () => {
    if (category.image) {
      return category.image;
    }
    return placeholder;
  };

  const getImageHeight = () => {
    const available = window.innerWidth;
    if (available > 500) {
      return 270;
    }
    return ((available - 16) * 9) / 16;
  };

  const getImageWidth = () => {
    if (window.innerWidth > 500) {
      return 500 - 32;
    }
    return window.innerWidth;
  };

  const displayState = (state) => {
    if (state === 2) return "none";
    return "";
  };

  return (
    <div
      style={{
        width: getImageWidth(),
        margin: "auto",
        display: `${displayState(category.state)}`,
      }}
    >
      <div
        className="lazyload blur-up"
        style={{
          background: `url(${getImageView()}) center center / cover no-repeat`,
          height: getImageHeight(),
          borderTopRightRadius: index % 2 == 0 ? "1rem" : "0px",
          borderBottomLeftRadius: index % 2 == 0 ? "1rem" : "0px",
          borderTopLeftRadius: index % 2 != 0 ? "1rem" : "0px",
          borderBottomRightRadius: index % 2 != 0 ? "1rem" : "0px",
          marginBottom: ".7rem",
        }}
        onClick={() => {
          getImageHeight();
          setExpanded(!expanded);
        }}
      >
        <div
          style={{
            backgroundImage: `linear-gradient(120deg, #00000050, #00000000)`,
            height: "100%",
            borderTopRightRadius: index % 2 == 0 ? "1rem" : "0px",
            borderBottomLeftRadius: index % 2 == 0 ? "1rem" : "0px",
            borderTopLeftRadius: index % 2 != 0 ? "1rem" : "0px",
            borderBottomRightRadius: index % 2 != 0 ? "1rem" : "0px",
            cursor: "pointer",
          }}
        >
          <Grid container>
            <Grid item xs={9}>
              <Typography
                style={{
                  color: "#ffffff",
                  marginLeft: "16px",
                  paddingTop: "16px",
                  fontSize: "28px",
                }}
                variant="subtitle1"
              >
                {Capitalize(category.label)}
                {/* hindi name */}
                &nbsp;
                {category.translations && category.translations.hi
                  ? `(${category.translations.hi.label})`
                  : null}
              </Typography>

              {/* {category.translations && category.translations.hi ? (
                <Typography
                  style={{
                    color: "#ffffff",
                    marginLeft: "16px",
                    fontSize: "28px",
                  }}
                  variant="subtitle1"
                >
                  {category.translations.hi.label}
                </Typography>
              ) : null} */}
            </Grid>
            <Grid item xs={3} style={{ marginTop: "16px", textAlign: "left" }}>
              <Icon
                icon={arrowIosDownwardFill}
                rotate={expanded ? 0 : 135}
                width={40}
                height={40}
                style={{
                  color: "#FFF",
                  transform: "translate(100%,0px)",
                }}
              />
            </Grid>
          </Grid>

          <Typography
            color="error"
            style={{ marginLeft: "16px" }}
            variant="subtitle1"
          >
            {category.discount ? `${category.discount}% OFF` : null}
          </Typography>
        </div>
      </div>
      {expanded && (
        <MenuItemView
          profile={profile}
          category={category}
          menuConfig={menuConfig}
          isViewMode={isViewMode}
        />
      )}
    </div>
  );
}

export default CategoryTile;
