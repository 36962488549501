import React, { useState } from "react";
import { Icon } from "@iconify/react";
import plusFill from "@iconify-icons/eva/plus-fill";
import minusFill from "@iconify-icons/eva/minus-fill";
import roundAddShoppingCart from "@iconify-icons/ic/round-add-shopping-cart";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Typography } from "@material-ui/core";
import { MIconButton, MButton } from "~/@material-extend";

const useStyles = makeStyles(theme => ({
  root: {},
  addToCartButtonBottomNavigation: {
    color: "#FFF",
    position: "fixed",
    bottom: "0",
    width: "100%",
    textAlign: "center",
    padding: "6px 0",
    background: theme.palette.primary.main,
    zIndex: 2,
    left: "0",
    borderRadius: ".5rem .5rem 0 0"
  },
  addToCartButton: {
    marginLeft: ".6rem",
    backgroundColor: "#FFF",
    padding: ".3rem .7rem",
    "&:hover": {
      backgroundColor: "#FFF",
      boxShadow:
        "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px"
    }
  },
  incrementButtonContainer: {
    padding: ".2rem 0"
  }
}));

function MyIncrementer({ handleAddCart, price, profile }) {
  const classes = useStyles();
  const [value, setValue] = useState(1);

  const incrementQuantity = () => {
    setValue(value + 1);
  };
  const decrementQuantity = () => {
    if (value === 1) {
      return;
    }
    setValue(value - 1);
  };

  const getCalculatedPrice = () => {
    if (price === 0.0) {
      return "Add to cart";
    }
    return price * value;
  };

  return (
    <Grid container className={classes.addToCartButtonBottomNavigation}>
      <Grid item xs={6} align="right">
        <Box
          className={classes.incrementButtonContainer}
          sx={{
            alignItems: "center"
          }}
        >
          <MIconButton
            size="medium"
            color="inherit"
            onClick={decrementQuantity}
          >
            <Icon icon={minusFill} width={16} height={16} />
          </MIconButton>
          <Box
            component="span"
            sx={{
              width: 40,
              textAlign: "center",
              display: "inline-block"
            }}
          >
            {value}
          </Box>
          <MIconButton
            size="medium"
            color="inherit"
            onClick={incrementQuantity}
          >
            <Icon icon={plusFill} width={16} height={16} />
          </MIconButton>
        </Box>
      </Grid>

      <Grid item xs={6} align="left" sx={{ m: "auto" }}>
        <MButton
          className={classes.addToCartButton}
          size="small"
          type="button"
          startIcon={<Icon icon={roundAddShoppingCart} />}
          onClick={() => handleAddCart(value)}
          sx={{ whiteSpace: "nowrap" }}
        >
          <Typography variant="subtitle1" sx={{ mb: 0.1 }}>
            {`${profile.currency} ${getCalculatedPrice().toFixed(2)}`}
          </Typography>
        </MButton>
      </Grid>
    </Grid>
  );
}

export default MyIncrementer;
