import { memo } from "react";
import CategoryTile from "./CategoryTile";
import StorePolicies from "./StorePolicies";
import { Divider, Typography, Grid } from "@material-ui/core";

function MenuCategoryView({
  menu,
  profile,
  filteredMenu,
  newMenuConfig,
  isViewMode
}) {
  if (!filteredMenu.categories.length && menu.categories.length) {
    return (
      <>
        <Divider style={{ marginBottom: "2rem" }} />
        <Typography align="center" color="textSecondary">
          Oops!
          <br />
          {/* Looks like we do not have the searched item in the menu. */}
          <br />
          Sorry, we could not find the item you are looking for. You may have
          misspelled it. Be sure to check your spelling.
        </Typography>
      </>
    );
  } else {
    if (window.innerWidth > 1080) {
      return (
        <div>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              {filteredMenu.categories.map((category, index) => {
                return index % 2 == 0 ? (
                  <CategoryTile
                    key={index}
                    profile={profile}
                    category={category}
                    isViewMode={isViewMode}
                    index={index}
                    menuConfig={newMenuConfig}
                  />
                ) : null;
              })}
            </Grid>
            <Grid item xs={6}>
              {filteredMenu.categories.map((category, index) => {
                return index % 2 != 0 ? (
                  <CategoryTile
                    key={index}
                    profile={profile}
                    category={category}
                    isViewMode={isViewMode}
                    index={index}
                    menuConfig={newMenuConfig}
                  />
                ) : null;
              })}
            </Grid>
          </Grid>
          <StorePolicies profile={profile} />
        </div>
      );
    }
    return (
      <>
        {filteredMenu.categories.map((category, index) => {
          return (
            <div key={index}>
              <Grid style={{ overflow: "hidden" }}>
                <CategoryTile
                  profile={profile}
                  index={index}
                  category={category}
                  isViewMode={isViewMode}
                  menuConfig={newMenuConfig}
                />
              </Grid>
            </div>
          );
        })}
        <StorePolicies profile={profile} />
      </>
    );
  }
}

export default memo(MenuCategoryView);
