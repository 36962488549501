import { Grid, Typography } from "@material-ui/core";
import { Icon } from "@iconify/react";
import roundAddShoppingCart from "@iconify-icons/ic/round-add-shopping-cart";
import { makeStyles } from "@material-ui/core/styles";

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    left: "0",
    textDecoration: "none",
    cursor: "pointer",
    color: "#FFF",
    width: "100%",
    textAlign: "center",
    padding: "1rem 0",
    backgroundColor: theme.palette.primary.main,
    zIndex: 2,
    borderRadius: ".4rem .4rem 0 0",
  },
}));
// ----------------------------------------------------------------------

function CartDetailsBottomLine({
  cartItems,
  profile,
  storeContactProfile,
  handleShowCart,
}) {
  const classes = useStyles();
  const { currency } = profile;

  const priceForConfigContent = () => {
    let contentPrice = 0;
    for (let index = 0; index < cartItems.length; index++) {
      const { value, quantity } = cartItems[index];
      contentPrice += Number(value) * quantity;
    }
    return contentPrice.toFixed(2);
  };

  return (
    <Grid
      container
      className={classes.root}
      sx={{
        display: cartItems.length === 0 && "none",
        bottom:
          storeContactProfile && storeContactProfile.length ? "56px" : "0",
      }}
      onClick={() => handleShowCart(true)}
    >
      <Grid item xs={6}>
        <Typography variant="subtitle1">
          {`${cartItems.length} ${
            cartItems.length > 1 ? "Items" : "Item"
          } in Cart `}
          <Icon icon={roundAddShoppingCart} />
        </Typography>
      </Grid>

      <Grid item xs={6}>
        <Typography variant="subtitle1">
          {`${currency} ${priceForConfigContent()}`}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default CartDetailsBottomLine;
