import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  BottomNavigation,
  BottomNavigationAction,
  Typography
} from "@material-ui/core";

// Internal Imports
import instagramLogo from "../../../images/instagram.png";
import facebookLogo from "../../../images/facebook.png";
import twitterLogo from "../../../images/twitter.png";
import youtubeLogo from "../../../images/youtube.png";
import linkedInLogo from "../../../images/linkedin.png";
import zomatoLogo from "../../../images/Zomato_logo.png";
import swiggyLogo from "../../../images/swiggy.svg";
import websiteLogo from "../../../images/website.png";
import phoneLogo from "../../../images/phone.png";
import emailLogo from "../../../images/email.png";

const useStyles = makeStyles(theme => ({
  socialMediaListItem: {
    display: "flex",
    // justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "1.2rem",
    cursor: "pointer"
  },
  imageStyle: {
    width: "26px",
    height: "26px"
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function OtherSocialMediaModal({ storeContactProfile }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const socialMediaDisplay = () => {
    const socialMediaArray = [];
    for (let index = 0; index < storeContactProfile.length; index++) {
      const element = storeContactProfile[index];

      //   Instagram
      if (element.type === "Instagram") {
        socialMediaArray.push(
          <div
            className={classes.socialMediaListItem}
            onClick={() => {
              window.open(`https://www.instagram.com/${element.url}`, "_blank");
            }}
          >
            <img src={instagramLogo} className={classes.imageStyle} />
            <Typography variant="p">Instagram</Typography>
          </div>
        );
      }

      //   Facebook
      if (element.type === "Facebook") {
        socialMediaArray.push(
          <div
            className={classes.socialMediaListItem}
            onClick={() => {
              window.open(element.url, "_blank");
            }}
          >
            <img src={facebookLogo} className={classes.imageStyle} />
            <Typography variant="p">Facebook</Typography>
          </div>
        );
      }

      //   Twitter
      if (element.type === "Twitter") {
        socialMediaArray.push(
          <div
            className={classes.socialMediaListItem}
            onClick={() => {
              window.open(element.url, "_blank");
            }}
          >
            <img src={twitterLogo} className={classes.imageStyle} />
            <Typography variant="p">Twitter</Typography>
          </div>
        );
      }

      //   Youtube
      if (element.type === "YouTube") {
        socialMediaArray.push(
          <div
            className={classes.socialMediaListItem}
            onClick={() => {
              window.open(element.url, "_blank");
            }}
          >
            <img src={youtubeLogo} className={classes.imageStyle} />
            <Typography variant="p">Youtube</Typography>
          </div>
        );
      }

      // Linkedin
      if (element.type === "LinkedIn") {
        socialMediaArray.push(
          <div
            className={classes.socialMediaListItem}
            onClick={() => {
              window.open(element.url, "_blank");
            }}
          >
            <img src={linkedInLogo} className={classes.imageStyle} />
            <Typography variant="p">LinkedIn</Typography>
          </div>
        );
      }
      // Zomato
      if (element.type === "Zomato") {
        socialMediaArray.push(
          <div
            className={classes.socialMediaListItem}
            onClick={() => {
              window.open(element.url, "_blank");
            }}
          >
            <img
              src={zomatoLogo}
              className={classes.imageStyle}
              style={{ borderRadius: "12px" }}
            />
            <Typography variant="p">Zomato</Typography>
          </div>
        );
      }
      // swiggy
      if (element.type === "Swiggy") {
        socialMediaArray.push(
          <div
            className={classes.socialMediaListItem}
            onClick={() => {
              window.open(element.url, "_blank");
            }}
          >
            <img src={swiggyLogo} className={classes.imageStyle} />
            <Typography variant="p">Swiggy</Typography>
          </div>
        );
      }

      // website
      if (element.type === "Website") {
        socialMediaArray.push(
          <div
            className={classes.socialMediaListItem}
            onClick={() => {
              window.open(element.url, "_blank");
            }}
          >
            <img src={websiteLogo} className={classes.imageStyle} />
            <Typography variant="p">Website</Typography>
          </div>
        );
      }
      // Phone
      // if (element.type === "Phone") {
      //   socialMediaArray.push(
      //     <div
      //       className={classes.socialMediaListItem}
      //       onClick={() => {
      //         window.open(`tel:${element.url}`, "_blank");
      //       }}
      //     >
      //       <img src={phoneLogo} className={classes.imageStyle} />
      //       <Typography variant="p">Phone</Typography>
      //     </div>
      //   );
      // }
      // Email
      if (element.type === "Email") {
        socialMediaArray.push(
          <div
            className={classes.socialMediaListItem}
            onClick={() => {
              window.open(
                `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=${element.url}`,
                "_blank"
              );
              // window.open(`mailto:${element.url}`, "_blank");
            }}
          >
            <img src={emailLogo} className={classes.imageStyle} />
            <Typography variant="p">Email</Typography>
          </div>
        );
      }
    }
    return socialMediaArray;
  };

  if (!socialMediaDisplay().length) {
    return null;
  }

  return (
    <div>
      <BottomNavigation showLabels>
        <BottomNavigationAction
          onClick={handleClickOpen}
          label="More"
          icon={<MoreHorizIcon />}
        />
      </BottomNavigation>
      <Dialog
        sx={{
          margin: "auto",
          "& .MuiPaper-root": {
            width: "94%"
          },
          "& .MuiPaper-root img": {
            marginRight: "1rem"
          }
        }}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          <Typography variant="p">Other Social Media</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {socialMediaDisplay()}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="error">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default OtherSocialMediaModal;
