import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import clsx from "clsx";

// ----------------------------------------------------------------------

const useStyles = makeStyles(theme => ({
  branchAddress: {
    lineHeight: "1.4",
    opacity: 0.78
  },
  dateSection: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0 .5rem"
  },
  greySubheading: {
    opacity: 0.78
  }
}));

// ----------------------------------------------------------------------
const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];
// ----------------------------------------------------------------------

function DateSection({ orderItem }) {
  const classes = useStyles();
  const { profile, orderTime, value } = orderItem;
  const date = orderTime.split("T")[0].split("-");
  const time = orderTime.split("T")[1].split(":");
  return (
    <div className={classes.dateSection}>
      <Typography
        variant="body2"
        className={clsx(classes.branchAddress, classes.greySubheading)}
      >
        {date[2]}&nbsp;{months[date[1] - 1]}&nbsp;{date[0]} at&nbsp;
        {`${time[0]}:${time[1]}`}
      </Typography>

      <Typography variant="body1" className={classes.branchAddress}>
        {`${profile.currency} ${value.toFixed(2)}`}
      </Typography>
    </div>
  );
}

export default DateSection;
