import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import footerLogo from "../../../images/FooterLogo.png";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  footer: {
    marginTop: "2rem",
    fontSize: "12px",
    lineHeight: "18px",
    padding: "16px 16px 10px 0px",
    width: "100%",
    whiteSpace: "normal"
  },

  footerTopDetails: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "12px"
  },
  footerLogo: {
    width: "49px",
    marginBottom: "4px",
    boxShadow: "0 2px 4px 0 rgb(0 0 0 / 18%)",
    padding: "2px",
    height: "49px"
  },
  octopuscoTitle: {
    display: "flex",
    fontWeight: "bold",
    // lineHeight: "28px",
    maxWidth: "80%",
    verticalAlign: "middle",
    fontSize: "16px"
  },
  visitWebsiteDiv: {
    fontSize: "16px",
    fontWeight: 600,
    marginTop: "0px",
    paddingTop: "1.7rem"
  },
  visitWebsite: {
    display: "inline"
  }
}));

function ExampleMenuFooter() {
  const classes = useStyles();
  return (
    <div className={classes.footer}>
      <div className={classes.footerTopDetails}>
        <p className={classes.octopuscoTitle}>
          <img src={footerLogo} className={classes.footerLogo} />
          <span style={{ paddingTop: ".8rem", paddingLeft: "8px" }}>
            Octap.in
          </span>
        </p>
        {/* <div className={classes.visitWebsiteDiv}>
          <a
            href="https://www.octopusco.in/ready-to-use-qr-code-menu"
            target="_blank"
            className={classes.visitWebsite}
            style={{ textDecoration: "none" }}
          >
            Visit Website
          </a>
        </div> */}
      </div>
      <Typography variant="subtitle2">
        Want to create page like this for your Business?
        <br />
        Visit &nbsp;
        <a
          href="https://octap.in"
          target="_blank"
          style={{ textDecoration: "none" }}
        >
          Create Digital Qr-Code Menu
        </a>
        &nbsp; to get started!
        <br />
        <a>Version 1.0.64</a>
      </Typography>
    </div>
  );
}

export default ExampleMenuFooter;
