import { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  TextField,
  Grid,
  Typography,
  MenuItem,
  Autocomplete,
} from "@material-ui/core";
import roundAddShoppingCart from "@iconify-icons/ic/round-add-shopping-cart";
import ReplayIcon from "@iconify-icons/ic/replay";
import { makeStyles } from "@material-ui/core/styles";
import { Icon } from "@iconify/react";
import { LoadingButton } from "@material-ui/lab";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";

// Internal Imports
import MyWebServices from "~/services/WebServices";
import useIsMountedRef from "~/hooks/useIsMountedRef";
import handleSuccess from "./PaymentGateway";
import {
  cleanCartItems,
  getCartItemsFromLocalDB,
  getUserObjectFromLocalDB,
  setOrderListForOrderRepeatInLocalDB,
  setUserObjectInLocalDB,
} from "~/services/LocalServices";
import countryCode from "../../common/CountryCode";

// ------------------------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {},
}));
// ------------------------------------------------------------------------------------------------

function PlaceOrderForm({
  profile,
  cartItems,
  otpSent,
  config,
  slugSelectedConfigs,
  placeOrder,
  grandTotal,
  chargesList,
  primaryColor,
  handleOtpSentState,
}) {
  const configTypePickup = "Pickup";
  const configTypeDelivery = "Delivery";
  const configTypeDineIn = "Dine-In";
  const classes = useStyles();
  const history = useHistory();
  const isMountedRef = useIsMountedRef();
  const [sentOTP, setSentOtp] = useState(
    Math.floor(1000 + Math.random() * 9000)
  );
  const [resendOtp, setResendOtp] = useState(false);
  const [timerValue, setTimerValue] = useState(30);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const userObjectFromLocalDB = getUserObjectFromLocalDB("userObject");
  const userObject = userObjectFromLocalDB
    ? JSON.parse(userObjectFromLocalDB)
    : null;

  const { slug, currency } = profile;

  //******************************************************************************************************
  const setUserObjectInLocalStorage = (data) => {
    if (userObject) {
      const userObj = {
        name: data.name === "Anonymous" ? userObject.name : data.name,
        mobile:
          typeof userObject.mobile !== "number"
            ? data.user
            : typeof data.user !== "number"
            ? userObject.mobile
            : data.user,
      };
      setUserObjectInLocalDB("userObject", JSON.stringify(userObj));
      return;
    }
    const userObj = {
      name: data.name,
      mobile: data.user,
    };
    setUserObjectInLocalDB("userObject", JSON.stringify(userObj));
  };
  //******************************************************************************************************
  const getFieldLabel = () => {
    if (slugSelectedConfigs === undefined) {
      return "Delivery Address";
    }
    if (slugSelectedConfigs.label === configTypeDelivery) {
      return "Delivery Address";
    }
    if (slugSelectedConfigs.label === configTypePickup) {
      return "Pickup Time";
      // return "";
    }
    return "Table/Room no.";
  };
  //******************************************************************************************************

  const resendOtpFunction = () => {
    // clearInterval(id);
    var secs = timerValue;
    var id = setInterval(function () {
      secs--;
      setTimerValue(secs);
      if (secs < 1) {
        clearInterval(id);
        setResendOtp(true);
        setTimerValue(30);
      }
    }, 1000);
  };
  //******************************************************************************************************
  const itemListForOrder = () => {
    const itemList = [];
    for (let index = 0; index < cartItems.length; index++) {
      const cartItem = cartItems[index];
      const {
        itemId,
        itemLabel,
        value,
        savings,
        quantity,
        images,
        itemSelectedConfigs,
      } = cartItem;
      const itemObject = {
        itemID: itemId,
        itemLabel,
        value: value * quantity,
        savings: savings * quantity,
        quantity,
        images,
        configs: itemSelectedConfigs.filter((config) => config.contents.length),
      };
      itemList.push(itemObject);
    }
    return itemList;
  };

  //******************************************************************************************************
  const isPaymentModeOnline = () => {
    if (
      profile.store_gateway_config &&
      profile.store_gateway_config.length &&
      profile.store_gateway_config[0].merchent_id &&
      profile.store_gateway_config[0].merchent_key &&
      slugSelectedConfigs.online_payment === 1
    ) {
      return true;
    }
    return false;
  };

  //************************************* POST API for Sending OTP.**************************************
  const sendOtpToUser = (phone) => {
    const countryCode = "+91";
    MyWebServices.sendOtpNodeJs(countryCode, phone, sentOTP);
    enqueueSnackbar(`OTP Sent to ${phone}`, {
      variant: "info",
    });
  };

  //************************************* POST API for Placing Order.*************************************
  const placeOrderAfterApiCall = (response) => {
    if (isPaymentModeOnline()) {
      const paymentGAtewayResponse = handleSuccess(response.data.param_dict);
      return;
    }

    history.push({
      pathname: `/status/${response.data.slug}`,
      state: {
        primaryColor: primaryColor,
        config: config,
      },
    });

    enqueueSnackbar("Order placed Successfully", {
      variant: "success",
    });
    setOrderListForOrderRepeatInLocalDB(
      "cartItemsForOrderRepeat",
      getCartItemsFromLocalDB(slug)
    );

    cleanCartItems(slug);
  };
  //******************************************************************************************************

  const FormCreateSchema = Yup.object().shape({
    phone:
      slugSelectedConfigs && slugSelectedConfigs.otpAuthentication
        ? Yup.string()
            .matches(
              /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
              "Please Enter a Valid Mobile Number."
            )
            .required("Please Enter your Mobile Number.")
            .min(10, "Please Enter a Valid Mobile Number.")
            .max(10, "Please Enter a Valid Mobile Number.")
        : Yup.string()
            .matches(
              /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
              "Please Enter a Valid Mobile Number."
            )
            .min(10, "Please Enter a Valid Mobile Number.")
            .max(10, "Please Enter a Valid Mobile Number."),

    address: Yup.string().required("This field is required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      // userObject && userObject.name !== "Anonymous" ? userObject.name : "",
      branch: "",
      phone: "",
      // userObject && typeof userObject.mobile === "number"
      //   ? userObject.mobile
      //   : "",
      remarks: "",
      address: !slugSelectedConfigs
        ? ""
        : slugSelectedConfigs.iden
        ? `${slugSelectedConfigs.idenl}: ${slugSelectedConfigs.iden}`
        : "",
      item: [],
      otp: "",
      countryCode: "+91",
    },
    validationSchema: FormCreateSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        const orderData = (values) => {
          const regularExpForMobileValidation = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
          const {
            label,
            initialOrderState,
            otpAuthentication,
          } = slugSelectedConfigs;
          const data = {
            currency,
            address: values.address,
            remarks: values.remarks,
            amount: grandTotal,
            orderType: label,
            state: initialOrderState,
            items: itemListForOrder(),
            charges: chargesList,
            user: {
              phone: otpAuthentication
                ? parseInt(values.phone)
                : values.phone.toString().match(regularExpForMobileValidation)
                ? parseInt(values.phone)
                : "",
              name: values.name,
              countryCode: values.countryCode,
            },
          };

          if (!data.user.name) {
            delete data.user.name;
          }
          if (!data.user.phone) {
            delete data.user.phone;
          }

          if (!data.user.name && !data.user.phone) {
            delete data.user;
          }

          return data;
        };
        if (!slugSelectedConfigs) {
          enqueueSnackbar("Please Select Order Type", {
            variant: "error",
          });
          return;
        }

        // Minimum Order Value Notification
        if (slugSelectedConfigs.minimumOrderValue > orderData(values).amount) {
          const message = `Minimum Cart Value is ₹ ${slugSelectedConfigs.minimumOrderValue}`;
          enqueueSnackbar(message, { variant: "error" });
          return;
        }

        // When Phone Authentication is On
        if (slugSelectedConfigs.otpAuthentication) {
          if (otpSent) {
            if (values.otp === "") {
              enqueueSnackbar("Please Enter the OTP", {
                variant: "error",
              });
              return;
            }

            if (sentOTP !== parseInt(values.otp)) {
              enqueueSnackbar("Entered OTP is not correct", {
                variant: "error",
              });
              return;
            }
            // console.log(
            //   "orderData(values)",
            //   JSON.stringify(orderData(values)),
            //   orderData(values)
            // );
            // return; //when we stop the ordering flow
            const response = await MyWebServices.placeFoodOrderNodeJs(
              profile._id,
              orderData(values)
            );
            await placeOrderAfterApiCall(response);

            if (isMountedRef.current) {
              setSubmitting(false);
            }
            return;
          }

          // call otp api
          sendOtpToUser(values.phone);
          handleOtpSentState(true);
          resendOtpFunction();
          return;
        }
        // return;
        // When Phone Authentication is Off
        // console.log(
        //   "orderData (values)",
        //   JSON.stringify(orderData(values)),
        //   orderData(values)
        // );
        // return; //when we stop the ordering flow
        const response = await MyWebServices.placeFoodOrderNodeJs(
          profile._id,
          orderData(values)
        );

        await placeOrderAfterApiCall(response);
        setUserObjectInLocalStorage(orderData(values));
      } catch (err) {
        console.error(err);
        if (isMountedRef.current) {
          setErrors({ afterSubmit: err.code });
          setSubmitting(false);
        }
      }
    },
  });

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;

  return (
    <>
      {!(cartItems === undefined || cartItems.length === 0) && (
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12} md={7}></Grid>
          <Grid item xs={12} md={5}>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Card>
                      <CardContent sx={{ p: 2 }}>
                        {otpSent ? (
                          <>
                            <Grid container spacing={2}>
                              {slugSelectedConfigs.otpAuthentication && (
                                <>
                                  <Grid item xs={12}>
                                    <TextField
                                      autoFocus
                                      fullWidth
                                      type="number"
                                      label="Enter OTP"
                                      {...getFieldProps("otp")}
                                      inputProps={{ maxLength: 4 }}
                                    />
                                  </Grid>
                                </>
                              )}

                              <Grid
                                item
                                xs={2}
                                style={{
                                  marginTop: "auto",
                                  cursor: "pointer",
                                }}
                              >
                                <ArrowBackIcon
                                  onClick={() => {
                                    handleOtpSentState(false);
                                  }}
                                />
                              </Grid>

                              <Grid item xs={10}>
                                <LoadingButton
                                  disabled={!resendOtp}
                                  fullWidth
                                  color="inherit"
                                  variant="outlined"
                                  onClick={() => {
                                    sendOtpToUser(values.phone);
                                    setResendOtp(false);
                                    resendOtpFunction();
                                  }}
                                >
                                  <Icon
                                    icon={ReplayIcon}
                                    width={16}
                                    height={16}
                                  />
                                  &nbsp; Resend OTP
                                </LoadingButton>
                              </Grid>

                              {!resendOtp && (
                                <Grid item xs={12}>
                                  <Typography
                                    align="center"
                                    color="error"
                                    style={{ fontSize: ".8rem" }}
                                  >
                                    {`Resend OTP in ${timerValue} seconds`}
                                  </Typography>
                                </Grid>
                              )}
                            </Grid>
                            <Box
                              sx={{
                                mt: 3,
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <LoadingButton
                                disabled={
                                  cartItems === undefined ||
                                  cartItems.length === 0
                                }
                                fullWidth
                                size="large"
                                variant="contained"
                                type="submit"
                                pending={isSubmitting}
                              >
                                <Icon
                                  icon={roundAddShoppingCart}
                                  width={16}
                                  height={16}
                                />
                                &nbsp; Place Order
                              </LoadingButton>
                            </Box>
                          </>
                        ) : (
                          <>
                            <Grid container spacing={1}>
                              <Grid item xs={4.5}>
                                <TextField
                                  sx={{ color: "red" }}
                                  fullWidth
                                  select
                                  // label="cc"
                                  {...getFieldProps("countryCode")}
                                  // error={Boolean(touched.phone && errors.phone)}
                                  // helperText={touched.phone && errors.phone}
                                >
                                  <MenuItem key={"+91"} value={"+91"}>
                                    +91 India
                                  </MenuItem>
                                  {countryCode.map((option, index) => (
                                    <MenuItem
                                      key={index}
                                      value={option.dial_code}
                                    >
                                      {option.dial_code} {option.name}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Grid>

                              <Grid item xs={7.5}>
                                <TextField
                                  fullWidth
                                  type="number"
                                  label="Mobile Number"
                                  {...getFieldProps("phone")}
                                  error={Boolean(touched.phone && errors.phone)}
                                  helperText={touched.phone && errors.phone}
                                  // inputProps={{ maxLength: 10 }}
                                />
                              </Grid>

                              <Grid item xs={12}>
                                <TextField
                                  fullWidth
                                  label="Name"
                                  {...getFieldProps("name")}
                                  inputProps={{ maxLength: 400 }}
                                />
                              </Grid>

                              <Grid item xs={12}>
                                <TextField
                                  fullWidth
                                  label="Remarks (Optional)"
                                  {...getFieldProps("remarks")}
                                  inputProps={{ maxLength: 400 }}
                                />
                              </Grid>
                              {slugSelectedConfigs &&
                              slugSelectedConfigs.iden ? null : (
                                <Grid item xs={12}>
                                  <TextField
                                    fullWidth
                                    // variant="filled"
                                    // required
                                    label={getFieldLabel()}
                                    error={Boolean(
                                      touched.address && errors.address
                                    )}
                                    helperText={
                                      touched.address && errors.address
                                    }
                                    type={
                                      typeof slugSelectedConfigs !==
                                        "undefined" &&
                                      slugSelectedConfigs.label ===
                                        configTypePickup
                                        ? "time"
                                        : ""
                                    }
                                    {...getFieldProps("address")}
                                  />
                                </Grid>
                              )}
                            </Grid>
                            <Box
                              sx={{
                                mt: 3,
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <LoadingButton
                                disabled={
                                  cartItems === undefined ||
                                  cartItems.length === 0
                                }
                                fullWidth
                                size="large"
                                variant="contained"
                                type="submit"
                                pending={isSubmitting}
                              >
                                <Icon
                                  icon={roundAddShoppingCart}
                                  width={16}
                                  height={16}
                                />
                                &nbsp; Place Order
                              </LoadingButton>
                            </Box>
                          </>
                        )}
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Form>
            </FormikProvider>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default PlaceOrderForm;
