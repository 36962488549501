import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Typography, Grid } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  paymentStatus: {
    padding: ".8rem 0 1rem .8rem",
    margin: ".8rem 0"
  }
}));

// *********************************************************************************************************

function OrderDetail({ orderStatus }) {
  const classes = useStyles();
  const { amount: grandTotal, charges } = orderStatus;

  return (
    <Card className={clsx(classes.paymentStatus)}>
      {charges &&
        charges.map((charge, index) => (
          <Grid container spacing={2} key={index}>
            <Grid item xs={8}>
              <Typography variant="p">
                <b>{`${charge.label}: `}</b>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="p">{`₹ ${charge.value}`}</Typography>
            </Grid>
          </Grid>
        ))}

      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Typography color="primary" variant="subtitle1">
            <b>Grand Total</b>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography color="primary" variant="subtitle1">
            <b>₹&nbsp;{grandTotal}</b>
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
}
export default OrderDetail;
