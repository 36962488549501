import clsx from "clsx";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardMedia, Typography } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

// ----------------------------------------------------------------------

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    height: "328px",
    padding: ".5rem",
    cursor: "pointer",
    "&:hover": {
      boxShadow:
        "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px"
    }
  },
  card: {
    textDecoration: "none"
  },
  cardMediaWrap: {
    paddingTop: "100%",
    position: "relative"
  },
  cardMedia: {
    top: 0,
    width: "100%",
    height: "100%",
    position: "absolute",
    borderRadius: ".5rem"
  },
  cardContent: {
    padding: "0.5rem 0 1rem 0"
  }
}));

// ----------------------------------------------------------------------

function StoreItem({ store }) {
  const classes = useStyles();
  const { slug } = store;
  const branch = store;

  return (
    <RouterLink className={classes.card} to={`${slug}`}>
      <Card className={classes.root}>
        <div className={classes.cardMediaWrap}>
          <CardMedia
            component="img"
            title={branch.name}
            src={branch.logo}
            data-sizes="auto"
            // data-src={4}
            data-srcset={`${branch.logo} 600w, ${branch.logo} 960w`}
            className={clsx(classes.cardMedia, "lazyload blur-up")}
          />
        </div>

        <div className={classes.cardContent}>
          <Typography variant="h5" align="center">
            {branch.name}
          </Typography>
          <Typography variant="body2" align="center">
            {branch.description}
          </Typography>
        </div>
      </Card>
    </RouterLink>
  );
}

export default StoreItem;
