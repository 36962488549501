import React, { memo } from "react";
import Sheet from "react-modal-sheet";
import { useSelector } from "react-redux";
import { MButton } from "~/@material-extend";
import { Icon } from "@iconify/react";
import roundKeyboardBackSpace from "@iconify-icons/ic/round-keyboard-backspace";
import roundAddShoppingCart from "@iconify-icons/ic/round-add-shopping-cart";
import {
  Box,
  Typography,
  Radio,
  Checkbox,
  Grid,
  Card
} from "@material-ui/core";
import MyIncrementer from "./Incrementer";
import { useSnackbar } from "notistack";
import veg from "../../../images/veg.png";
import nonveg from "../../../images/nonveg.png";

function MultiConfigAddToCart({
  category,
  item,
  profile,
  isOpen,
  calculatedPrice,
  handleSheetState,
  isViewMode,
  isSelected,
  handleAddToCart,
  handleSomeData
}) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { darkMode } = useSelector(state => state.theme);
  const { currency } = profile;

  const truncate = str => {
    return str.length > 34 ? str.substring(0, 30) + "..." : str;
  };

  const vegOrNonVegOrNone = () => {
    if (item.veg === null) {
      return null;
    }
    return (
      <img
        src={item.veg ? veg : nonveg}
        width="24px"
        style={{
          display: "-webkit-inline-box",
          transform: "translate(-3px, 5px)",
          borderRadius: "1rem"
        }}
      />
    );
  };

  const getItemConfig = () => {
    for (let index = 0; index < item.configs.length; index++) {
      if (!item.configs[index].contents.length) {
        item.configs.splice(index, 1);
      }
    }
    return (
      <>
        {item.configs.map((config, index) => (
          <div key={index}>
            <Card sx={{ px: 2, py: 2, mx: 1, my: 2 }}>
              <Grid item xs={12} align="left">
                <Typography align="left" variant="p">
                  {config.label !== "Information" ? (
                    <span style={{ fontSize: "1.2rem", fontWeight: "700" }}>
                      {config.label}
                      <br />
                    </span>
                  ) : null}

                  <span style={{ opacity: "0.7", fontWeight: "200" }}>
                    {getQuantityLabel(config)}
                  </span>
                </Typography>
              </Grid>

              <Grid item xs={12} align="right">
                <span
                  style={{
                    color: "#FB8C00",
                    borderRadius: ".1rem",
                    paddingLeft: ".2rem",
                    paddingRight: ".2rem"
                  }}
                >
                  {getRequiredTag(config)}
                </span>
              </Grid>

              {config.contents.map((content, index) => (
                <React.Fragment key={index}>
                  {getSelectionView(config, content, config.config_content)}
                </React.Fragment>
              ))}
            </Card>
          </div>
        ))}
      </>
    );
  };

  const getQuantityLabel = config => {
    if (config.maxSelection === 0) {
      // return null;
      return `${item.label}`;
    }
    if (config.maxSelection === 1) {
      return "Please select any 1 option";
    }
    return `Select upto ${config.maxSelection} options`;
  };

  const getRequiredTag = config => {
    if (config.required) {
      return "Required";
    }
    return null;
  };

  const getSelectionView = (config, content) => {
    if (content.state === 2) {
      return null;
    }
    if (config.maxSelection === 0) {
      return (
        <Grid container sx={{ mb: 2 }}>
          <Grid item xs={12}>
            <Typography variant="p">
              <strong>{content.label} : </strong>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="p"
              sx={{ opacity: "0.72", wordWrap: "break-word" }}
            >
              {content.value}
            </Typography>
          </Grid>
        </Grid>
      );
    }
    if (config.maxSelection === 1) {
      return (
        <label htmlFor={content._id}>
          <Grid
            container
            sx={{
              fontWeight: content.added ? "700" : "200"
            }}
          >
            <Grid item xs={1} sx={{ transform: "translate(0,-3px)" }}>
              {vegOrNonVegOrNone()}
            </Grid>
            <Grid item xs={5}>
              <Typography variant="p">{content.label}</Typography>
            </Grid>

            <Grid item xs={6} align="right">
              {getDiscountForContent(content)}
              <Radio
                id={content._id}
                value={content.value}
                checked={isSelected(content)}
                color="primary"
                onChange={() => {
                  handleSomeData(
                    config.contents.map(data => {
                      if (content._id === data._id) {
                        data.added = true;
                      } else {
                        data.added = false;
                      }
                      return data;
                    })
                  );
                }}
              />
            </Grid>
          </Grid>
        </label>
      );
    }

    let selectedItems = [];
    for (let index = 0; index < config.contents.length; index++) {
      const element = config.contents[index];
      if (element.added) {
        selectedItems.push(element._id);
      }
    }
    return (
      <label htmlFor={content._id}>
        <Grid
          container
          sx={{
            fontWeight: content.added ? "700" : "200"
          }}
        >
          <Grid item xs={1} sx={{ transform: "translate(0,-3px)" }}>
            {vegOrNonVegOrNone()}
          </Grid>
          <Grid item xs={5}>
            <Typography variant="p">{truncate(content.label)}</Typography>
          </Grid>

          <Grid item xs={6} align="right">
            {getDiscountForContent(content)}
            <Checkbox
              id={content._id}
              checked={isSelected(content)}
              onChange={event => {
                let checked = event.target.checked;
                if (checked && selectedItems.length >= config.maxSelection) {
                  const message = `Only ${config.maxSelection} options can be selected`;
                  enqueueSnackbar(message, { variant: "info" });
                  return;
                }
                handleSomeData(
                  config.contents.map(data => {
                    if (content._id === data._id) {
                      data.added = checked;
                    }
                    return data;
                  })
                );
              }}
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </Grid>
        </Grid>
      </label>
    );
  };

  const getLabelForState = () => {
    if (isViewMode) {
      return "View More";
    }
    if (item.state === "Available") {
      return "Add to Cart";
    }
    return item.state;
  };

  const getDiscountForContent = content => {
    let value = content.value;
    if (value === 0) {
      return <p>Free</p>;
    }
    if (category.discount !== 0) {
      value = value - (value * category.discount) / 100;
    }
    if (item.discount !== 0) {
      value = value - (value * item.discount) / 100;
    }
    if (value === content.value) {
      return <>{`${currency} ${content.value}`}</>;
    }
    return (
      <>
        <span
          style={{
            color: "red",
            textDecoration: "line-through"
          }}
        >
          {`${currency} ${content.value}`}
        </span>
        &nbsp; {`${currency} ${value.toFixed(2)}`}
      </>
    );
  };

  return (
    <>
      <MButton
        style={{ marginTop: ".7rem" }}
        type="button"
        disabled={
          item.state != "Available" || Math.round(calculatedPrice) === 0
        }
        color="primary"
        variant="contained"
        size="small"
        startIcon={<Icon icon={roundAddShoppingCart} />}
        onClick={() => {
          handleSheetState(true);
        }}
        sx={{ whiteSpace: "nowrap", boxShadow: "none" }}
      >
        {getLabelForState()}
        {/* {item.state} */}
      </MButton>

      {/* Bottom Sheet */}
      <Sheet
        isOpen={isOpen}
        onClose={() => handleSheetState(false)}
        snapPoints={[0.7]}
      >
        <Sheet.Container
          style={{
            borderRadius: "16px",
            backgroundColor: darkMode ? "#212B36" : "#FFF"
          }}
        >
          <Sheet.Header />

          <Sheet.Content style={{ paddingBottom: "1rem" }} disableDrag>
            <Box sx={{ m: 1 }}>
              <div style={{ display: "flex" }}>
                <MButton
                  onClick={() => {
                    handleSheetState(false);
                  }}
                  style={{ marginRight: "1rem", fontSize: "1.5rem" }}
                  type="button"
                  color="inherit"
                  sx={{ whiteSpace: "nowrap" }}
                >
                  <Icon icon={roundKeyboardBackSpace} />
                </MButton>

                <Typography align="left" variant="h4">
                  {item.label}
                </Typography>
              </div>
            </Box>

            <div
              style={{
                marginBlockEnd: "4rem"
              }}
            >
              {getItemConfig()}
            </div>

            {!isViewMode ? (
              <MyIncrementer
                profile={profile}
                name="quantity"
                price={calculatedPrice}
                handleAddCart={handleAddToCart}
              />
            ) : null}
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet>
    </>
  );
}

export default memo(MultiConfigAddToCart);
