import MenuItemTile from "./MenuItemTile";
import { Grid } from "@material-ui/core";

// ***********************************************************************************************
function MenuItemView({ category, menuConfig, isViewMode, profile }) {
  const { items } = category;
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        {items.map((item, index) => {
          return index % 2 === 0 && item.state === "Available" ? (
            <MenuItemTile
              profile={profile}
              key={index}
              category={category}
              isViewMode={isViewMode}
              item={item}
              menuConfig={menuConfig}
              index={index}
            />
          ) : null;
        })}
      </Grid>
      <Grid item xs={6}>
        {items.map((item, index) => {
          return index % 2 != 0 && item.state === "Available" ? (
            <MenuItemTile
              profile={profile}
              key={index}
              category={category}
              isViewMode={isViewMode}
              item={item}
              index={index}
              menuConfig={menuConfig}
            />
          ) : null;
        })}
      </Grid>
    </Grid>
  );
}

export default MenuItemView;
