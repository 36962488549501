import CryptoJS from "crypto-js";

const key = "cd9e3a93f405c67f6f2d6790fe9272b3c6e16965";

function encryptData(object) {
  return CryptoJS.AES.encrypt(JSON.stringify(object), key).toString();
}

function decryptData(encryptedObject) {
  var bytes = CryptoJS.AES.decrypt(encryptedObject, key);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
}

// ***************************************************************************************************************

export function getPrimaryColorFromLocalDB(key) {
  const dataFromDB = localStorage.getItem(
    `posueremalesuaucibus${key}posueremalesuaucibus`
  );
  if (dataFromDB) {
    return decryptData(dataFromDB);
  }
  return;
}

export function setPrimaryColorInLocalDB(key, primaryColor) {
  const encryptedStoreData = encryptData(primaryColor);
  localStorage.setItem(
    `posueremalesuaucibus${key}posueremalesuaucibus`,
    encryptedStoreData
  );
}

export function removePrimaryColor(key) {
  localStorage.removeItem(`posueremalesuaucibus${key}posueremalesuaucibus`);
}

// ***************************************************************************************************************

export function getOrderListForOrderRepeatFromLocalDB(key) {
  const dataFromDB = localStorage.getItem(
    `rhoncussedfringilutpatultrices${key}rhoncussedfringilutpatultrices`
  );
  if (dataFromDB) {
    return decryptData(dataFromDB);
  }
  return;
}

export function setOrderListForOrderRepeatInLocalDB(key, objectData) {
  const encryptedStoreData = encryptData(objectData);
  localStorage.setItem(
    `rhoncussedfringilutpatultrices${key}rhoncussedfringilutpatultrices`,
    encryptedStoreData
  );
}

// ***************************************************************************************************************

export function getUserObjectFromLocalDB(key) {
  const dataFromDB = localStorage.getItem(
    `aptenttacitisocubian${key}aptenttacitisocubian`
  );
  if (dataFromDB) {
    return decryptData(dataFromDB);
  }
  return;
}

export function setUserObjectInLocalDB(key, objectData) {
  const encryptedStoreData = encryptData(objectData);
  localStorage.setItem(
    `aptenttacitisocubian${key}aptenttacitisocubian`,
    encryptedStoreData
  );
}

// ***************************************************************************************************************

export function getOrdersListFromLocalDB(key) {
  const dataFromDB = localStorage.getItem(
    `puisquiermentum${key}puisquiermentum`
  );
  if (dataFromDB) {
    return decryptData(dataFromDB);
  }
  return;
}

export function setOrdersListInLocalDB(key, objectData) {
  const encryptedStoreData = encryptData(objectData);
  localStorage.setItem(
    `puisquiermentum${key}puisquiermentum`,
    encryptedStoreData
  );
}

// ***************************************************************************************************************

export function setStoreInLocalDB(key, objectData) {
  const encryptedStoreData = encryptData(objectData);
  localStorage.setItem(
    `obcaecatitenius${key}obcaecatitenius`,
    encryptedStoreData
  );
}

export function getStoreFromLocalDB(key) {
  const dataFromDB = localStorage.getItem(
    `obcaecatitenius${key}obcaecatitenius`
  );
  if (dataFromDB) {
    return decryptData(dataFromDB);
  }
  return;
}

// ***************************************************************************************************************
export function setCartItemsInLocalDB(slug, object) {
  const encryptedCartItems = encryptData(object);
  localStorage.setItem(
    `acdiamidexposueraximuslente${slug}acdiamidexposueraximuslente`,
    encryptedCartItems
  );
}

export function getCartItemsFromLocalDB(slug) {
  const dataFromDB = localStorage.getItem(
    `acdiamidexposueraximuslente${slug}acdiamidexposueraximuslente`
  );
  if (dataFromDB) {
    return decryptData(dataFromDB);
  }
  return;
}

export function cleanCartItems(slug) {
  localStorage.removeItem(
    `acdiamidexposueraximuslente${slug}acdiamidexposueraximuslente`
  );
}

// ***************************************************************************************************************
export function setMenuForSlug(slug, object) {
  const encryptedMenuData = encryptData(object);
  localStorage.setItem(
    `rmentumnequejustovitae${slug}rmentumnequejustovitae`,
    encryptedMenuData
  );
}

export function getMenuForSlugFromLocalDB(slug) {
  const dataFromDB = localStorage.getItem(
    `rmentumnequejustovitae${slug}rmentumnequejustovitae`
  );
  if (dataFromDB) {
    return decryptData(dataFromDB);
  }
  return;
}

// ***************************************************************************************************************
export default class LocalServices {
  static encryptData(object) {
    const key = process.env.DATA_ENCRYPTION_KEY;
    return CryptoJS.AES.encrypt(JSON.stringify(object), key).toString();
  }

  static decryptData(encryptedObject) {
    const key = process.env.DATA_ENCRYPTION_KEY;
    var bytes = CryptoJS.AES.decrypt(encryptedObject, key);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }

  // static setCartItemsInLocalDB(slug, object) {
  //   const encryptedCartItems = encryptData(object);
  //   localStorage.setItem(
  //     `acdiamidexposueraximuslente${slug}`,
  //     encryptedCartItems
  //   );
  // }

  // static getCartItemsFromLocalDB(slug) {
  //   const dataFromDB = localStorage.getItem(
  //     `acdiamidexposueraximuslente${slug}`
  //   );
  //   if (dataFromDB) {
  //     return decryptData(dataFromDB);
  //   }
  //   return;
  // }

  // ***************************************************************************************************************

  static setBaseUrl(workspace) {
    localStorage.setItem(
      "_user_workspace",
      `https://${workspace}.we4.io/`.toString()
    );
  }

  static getBaseUrl() {
    return localStorage.getItem("_user_workspace");
  }

  static setToken(token) {
    localStorage.setItem("_user_auth_key", `token ${token}`);
  }

  static getToken() {
    return localStorage.getItem("_user_auth_key");
  }

  static setUserRoles(roles) {
    localStorage.setItem("_user_roles", roles);
  }

  static isUserOwner() {
    return localStorage.getItem("_user_roles").includes("OWNER");
  }
}

// ***************************************************************************************************************
