import React from "react";
import { Icon } from "@iconify/react";
import plusFill from "@iconify-icons/eva/plus-fill";
import minusFill from "@iconify-icons/eva/minus-fill";
import roundAddShoppingCart from "@iconify-icons/ic/round-add-shopping-cart";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid } from "@material-ui/core";
import { MIconButton, MButton } from "~/@material-extend";
import { getCartItemsFromLocalDB } from "~/services/LocalServices";
import trashOutline from "@iconify-icons/eva/trash-outline";
import { experimentalStyled as styled } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {},
  addToCartButton: {
    marginTop: "1rem"
  },
  smallButtons: {
    paddingTop: "2.5px"
  }
}));

function SingleConfigAddToCart({
  handleAddCart,
  conditionForButton,
  itemId,
  slug,
  onIncrement,
  onDecrement,
  onRemove
}) {
  
  const classes = useStyles();
  const IncrementerStyle = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(0.5),
    padding: theme.spacing(0.5, 0.75),
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`
  }));

  const getQauntity = itemId => {
    const localCartObject = getCartItemsFromLocalDB(slug);
    if (localCartObject) {
      return localCartObject.cartItems.find(
        element => element.itemId === itemId
      ).quantity;
    }
  };

  return (
    <Grid container className={classes.addToCartButton}>
      {conditionForButton ? (
        <Grid item xs={11} style={{ textAlign: "center" }}>
          <Box
            sx={{
              width: "116px",
              height: "32px"
            }}
          >
            <IncrementerStyle style={{ transform: "" }}>
              {getQauntity(itemId) === 1 ? (
                <MIconButton size="small" color="inherit" onClick={onRemove}>
                  <Icon icon={trashOutline} width={16} height={16} />
                </MIconButton>
              ) : (
                <MIconButton size="small" color="inherit" onClick={onDecrement}>
                  <Icon icon={minusFill} width={16} height={16} />
                </MIconButton>
              )}

              <span>
                <b>{getQauntity(itemId)}</b>
              </span>

              <MIconButton size="small" color="inherit" onClick={onIncrement}>
                <Icon icon={plusFill} width={16} height={16} />
              </MIconButton>
            </IncrementerStyle>
          </Box>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <MButton
            type="button"
            color="primary"
            variant="contained"
            size="small"
            sx={{
              whiteSpace: "nowrap",
              boxShadow: "none"
            }}
            onClick={() => handleAddCart(1)}
            startIcon={<Icon icon={roundAddShoppingCart} />}
          >
            Add To Cart
          </MButton>
        </Grid>
      )}
    </Grid>
  );
}

export default SingleConfigAddToCart;
