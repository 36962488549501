import TopBar from './TopBar';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import Footer from './Footer';

// ----------------------------------------------------------------------

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    minHeight: '100%',
    overflow: 'hidden'
  }
}));

// ----------------------------------------------------------------------

MenuLayout.propTypes = {
  children: PropTypes.node
};

function MenuLayout({ children }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {/* <TopBar /> */}
      <Container
        maxWidth="md"
        className={classes.main}
        sx={{ my: 15, flexGrow: 1, overflow: 'auto', minHeight: '100%' }}
      >
        {children}
        {/* <Footer /> */}
      </Container>
    </div>
  );
}

export default MenuLayout;
