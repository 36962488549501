import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Skeleton, Grid, Typography } from "@material-ui/core";
import StoreItem from "./StoreItem";

// ----------------------------------------------------------------------

const useStyles = makeStyles(theme => ({
  root: {}
}));

// ----------------------------------------------------------------------

const SkeletonLoad = (
  <Grid container spacing={3}>
    {[...Array(12)].map((item, index) => (
      <Grid item xs={6} sm={6} md={3} key={index}>
        <Skeleton
          component={Box}
          variant="rectangular"
          sx={{ width: "100%", paddingTop: "115%", borderRadius: 2 }}
        />
      </Grid>
    ))}
  </Grid>
);

function StoreChoice({ storeList, isLoad = true }) {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h3" sx={{ py: 2 }} align="center">
        Select Your Favourite Restaurant
      </Typography>
      <Grid container spacing={3} className={classes.root}>
        {storeList.map(store => (
          <Grid key={store.id} item xs={6} sm={6} md={3}>
            <StoreItem store={store} />
          </Grid>
        ))}

        {/* {isLoad && SkeletonLoad} */}
      </Grid>
    </>
  );
}

export default StoreChoice;
