import { createSlice } from "@reduxjs/toolkit";

// ----------------------------------------------------------------------

const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
let systemDarkMode;
if (darkThemeMq.matches) {
  systemDarkMode = true;
  // Theme set to dark.
} else {
  // Theme set to light.
  systemDarkMode = false;
}
const initialState = {
  darkMode: systemDarkMode
};

const slice = createSlice({
  name: "darkMode",
  initialState,
  reducers: {
    toggleTheme(state) {
      state.darkMode = !state.darkMode;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { toggleTheme } = slice.actions;
