import Page from "~/components/Page";
import React, { memo, useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Card,
  Typography,
  Grid,
  Divider,
} from "@material-ui/core";
import arrowIosBackFill from "@iconify-icons/eva/arrow-ios-back-fill";
import { Icon } from "@iconify/react";

// Internal Imports
import EmptyContent from "~/components/EmptyContent";
import ThemeConfig from "~/theme";
import MenuCartItemTile from "./CartItemTile";
import MenuConfigSelectionTile from "~/views/placeOrder/PlaceOrderView/MenuConfigSelectionTile";
import {
  setCartItemsInLocalDB,
  getCartItemsFromLocalDB,
  cleanCartItems,
  getOrderListForOrderRepeatFromLocalDB,
} from "~/services/LocalServices";
import emptyCart from "../../../images/empty_cart.svg";
import PlaceOrderForm from "./PlaceOrderForm";
import { useSnackbar } from "notistack";
import DispatchContext from "../../menu/MenuView/menuContext/dispatchContext";

// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  root: {
    marginTop: "2rem",
  },
  cartItemsCount: {
    marginBottom: "1.2rem",
    paddingLeft: ".8rem",
    marginTop: "1rem",
  },
  configDetailCard: {
    padding: "1rem",
    marginBottom: "1rem",
    borderRadius: ".5rem",
  },
}));
// ----------------------------------------------------------------------

function PlaceFoodOrder({
  profile,
  config,
  placeOrder,
  primaryColor,
  handleShowCart,
  orderRepeat = false,
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [otpSent, setOtpSent] = useState(false);
  const dispatch = useContext(DispatchContext);
  const { slug, currency } = profile;

  const newChargesMap = new Map();
  let chargesKeys = [];

  const [slugSelectedConfigs, setSlugSelectedConfigs] = useState(
    config.length === 1 ? config[[0]] : undefined
  );

  if (orderRepeat) {
    cleanCartItems(slug);
    const cartItemsForOrderRepeat = getOrderListForOrderRepeatFromLocalDB(
      "cartItemsForOrderRepeat"
    );
    cartItemsForOrderRepeat.timestamp = new Date().getTime();
    setCartItemsInLocalDB(slug, cartItemsForOrderRepeat);
  }

  const cartItemsObject = getCartItemsFromLocalDB(slug);
  const cartItemsTime = () => {
    if (cartItemsObject) {
      const cartUpdatedTime = new Date(cartItemsObject.timestamp);
      const currentTime = new Date();
      const res = Math.abs(cartUpdatedTime - currentTime) / 1000;
      const differenceOfMinutes = Math.floor(res / 60) % 60;
      const cartRetainTimeInMinutes = 30;

      if (differenceOfMinutes >= cartRetainTimeInMinutes) {
        cleanCartItems(slug);
      }
      return differenceOfMinutes >= cartRetainTimeInMinutes;
    }
  };

  const [cartItems, setCartItems] = useState(
    cartItemsTime() ? [] : cartItemsObject ? cartItemsObject.cartItems : []
  );

  const subTotal = () => {
    let totalAmount = 0;
    for (let index = 0; index < cartItems.length; index++) {
      totalAmount += cartItems[index].price * cartItems[index].quantity;
    }
    return totalAmount;
  };

  const grandTotal = () => {
    let total = 0;
    for (const [key, value] of newChargesMap.entries()) {
      total += value;
      console.log(`${key} = ${value}`);
    }
    return (Number(total) + subTotal()).toFixed(2);
  };

  const getChargesMap = () => {
    newChargesMap.clear();
    chargesKeys = [];
    for (let index = 0; index < slugSelectedConfigs.contents.length; index++) {
      const content = slugSelectedConfigs.contents[index];
      if (content.valueType === "absolute") {
        chargesKeys.push(content.label);
        newChargesMap.set(content.label, Number(content.value));
      } else {
        const total = subTotal();
        const amountAfterCharges = (total * Number(content.value)) / 100;
        chargesKeys.push(content.label);
        newChargesMap.set(content.label, amountAfterCharges);
      }
    }
    for (let index = 0; index < cartItems.length; index++) {
      const cartItem = cartItems[index];
      const value = cartItem.price * cartItem.quantity;
      for (let index = 0; index < cartItem.itemTaxes.length; index++) {
        const eachTax = cartItem.itemTaxes[index];
        const calculatedTax = (eachTax.value * value) / 100;
        if (newChargesMap.has(eachTax.label)) {
          const existingMapValue = newChargesMap.get(eachTax.label);
          newChargesMap.set(eachTax.label, existingMapValue + calculatedTax);
        } else {
          chargesKeys.push(eachTax.label);
          newChargesMap.set(eachTax.label, calculatedTax);
        }
      }
    }

    return (
      <React.Fragment>
        {chargesKeys.map((contentLabel) => {
          return (
            <React.Fragment key={contentLabel}>
              <Grid item xs={6}>
                <Typography variant="p">
                  <b>{contentLabel}</b>
                </Typography>
              </Grid>
              <Grid item xs={6} align="right">
                <Typography variant="p">
                  {currency}&nbsp;{newChargesMap.get(contentLabel).toFixed(2)}
                </Typography>
              </Grid>
            </React.Fragment>
          );
        })}
      </React.Fragment>
    );
  };

  const chargesList = () => {
    const chargesArray = [];
    for (let index = 0; index < chargesKeys.length; index++) {
      const key = chargesKeys[index];
      chargesArray.push({
        label: key,
        value: newChargesMap.get(key),
      });
    }
    return chargesArray;
  };

  const getConfigViews = () => {
    if (typeof slugSelectedConfigs === "undefined") {
      return null;
    }
    return (
      <Card className={classes.configDetailCard}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Typography variant="p">
              <b>Cart Value</b>
            </Typography>
          </Grid>
          <Grid item xs={6} align="right">
            <Typography variant="p">
              {currency}&nbsp;{subTotal().toFixed(2)}
            </Typography>
          </Grid>
          {getChargesMap()}

          <Grid item xs={6}>
            <Typography color="primary" variant="subtitle1">
              <b>Grand Total</b>
            </Typography>
          </Grid>
          <Grid item xs={6} align="right">
            <Typography color="primary" variant="subtitle1">
              <b>
                {currency} &nbsp;{grandTotal()}
              </b>
            </Typography>
          </Grid>
        </Grid>
      </Card>
    );
  };

  const removeItemFromCart = (index) => {
    const message = `${cartItems[index].itemLabel} removed from cart`;
    enqueueSnackbar(message, { variant: "error" });
    cartItems.splice(index, 1);
    setCartItems([...cartItems]);
    const localCartObject = getCartItemsFromLocalDB(slug);
    localCartObject.cartItems = cartItems;
    dispatch({
      type: "UpdateCartLength",
      cartItemsList: localCartObject.cartItems,
    });
    setCartItemsInLocalDB(slug, localCartObject);
  };

  return (
    <ThemeConfig primaryColor={primaryColor}>
      <Page className={classes.root}>
        <Divider />
        <Box sx={{ mt: 2 }} onClick={() => handleShowCart(false)}>
          <Button
            style={{
              borderRadius: "5px",
              padding: "3px 5px",
            }}
            color={cartItems.length === 0 ? "primary" : "inherit"}
            variant={cartItems.length === 0 ? "contained" : "outlined"}
            startIcon={<Icon icon={arrowIosBackFill} />}
          >
            Back to menu
          </Button>
        </Box>
        <Typography variant="h4" className={classes.cartItemsCount}>
          {`Cart (Items: ${cartItems === undefined ? 0 : cartItems.length})`}
        </Typography>

        {!(cartItems === undefined || cartItems.length === 0) ? (
          <>
            <Grid container spacing={2}>
              {cartItems.map((item, index) => (
                <Grid
                  key={item.itemId}
                  item
                  xs={12}
                  sm={cartItems.length === 1 ? 12 : 6}
                >
                  <MenuCartItemTile
                    currency={currency}
                    cartItem={item}
                    onRemove={() => removeItemFromCart(index)}
                    onChange={(value) => {
                      cartItems[index].quantity = value;
                      setCartItems([...cartItems]);
                      const localCartObject = getCartItemsFromLocalDB(slug);
                      localCartObject.cartItems = cartItems;
                      dispatch({
                        type: "UpdateCartLength",
                        cartItemsList: localCartObject.cartItems,
                      });
                      setCartItemsInLocalDB(slug, localCartObject);
                    }}
                  />
                </Grid>
              ))}
            </Grid>

            <Grid container spacing={2} sx={{ mb: 2, mt: 1 }}>
              {config &&
                config.map((config, index) => {
                  return (
                    <Grid
                      item
                      xs={4}
                      sm={2.4}
                      key={index}
                      style={{
                        color:
                          config === slugSelectedConfigs
                            ? `#${primaryColor}`
                            : "",
                      }}
                    >
                      <MenuConfigSelectionTile
                        onSelect={async () => {
                          setSlugSelectedConfigs(config);
                          if (config.otp_on_order) {
                            setOtpSent(false);
                          }
                        }}
                        config={config}
                        slugSelectedConfigs={slugSelectedConfigs}
                        primaryColor={primaryColor}
                      />
                    </Grid>
                  );
                })}
            </Grid>

            {getConfigViews()}
          </>
        ) : (
          <EmptyContent
            title="Cart is empty"
            description="Look like you have no items in your shopping cart."
            img={emptyCart}
          />
        )}
        <PlaceOrderForm
          slug={slug}
          cartItems={cartItems}
          profile={profile}
          otpSent={otpSent}
          config={config}
          primaryColor={primaryColor}
          slugSelectedConfigs={slugSelectedConfigs}
          placeOrder={placeOrder}
          grandTotal={grandTotal()}
          chargesList={chargesList()}
          handleOtpSentState={(value) => {
            setOtpSent(value);
          }}
        />
      </Page>
    </ThemeConfig>
  );
}

export default memo(PlaceFoodOrder);
