import React from "react";
import Typography from "@material-ui/core/Typography";
import LocalDiningIcon from "@material-ui/icons/LocalDining";
import DirectionsBikeIcon from "@material-ui/icons/DirectionsBike";
import LocalMallIcon from "@material-ui/icons/LocalMall";
import RoofingIcon from "@material-ui/icons/Roofing";
import RoomServiceIcon from "@material-ui/icons/RoomService";
import { Card } from "@material-ui/core";

function MenuConfigSelectionTile({
  onSelect,
  config,
  slugSelectedConfigs,
  primaryColor
}) {
  return (
    <Card
      onClick={() => {
        onSelect();
      }}
      style={{
        cursor: "pointer",
        borderRadius: "5px",
        border:
          slugSelectedConfigs === config
            ? `2px solid #${primaryColor}`
            : "1px solid #000",
        boxShadow:
          slugSelectedConfigs === config
            ? `#${primaryColor} 2px 4px 10px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px`
            : `rgba(0, 0, 0, 0.1) 2px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px`,
        color: slugSelectedConfigs === config ? "#FFF" : "",
        backgroundColor:
          slugSelectedConfigs === config ? `#${primaryColor}` : ""
      }}
    >
      <div
        style={{
          margin: "8px 8px 0px 8px",
          textAlign: "center"
        }}
      >
        {config.label === "Pickup" && <LocalMallIcon />}
        {config.label === "Dine-In" && <LocalDiningIcon />}
        {config.label === "Delivery" && <DirectionsBikeIcon />}
        {config.label === "Roof-Top" && <RoofingIcon />}
        {config.label === "In-Room" && <RoomServiceIcon />}
        &nbsp;&nbsp;
      </div>

      <Typography
        variant="h5"
        style={{ margin: "0px 8px 8px 8px", textAlign: "center" }}
      >
        {config.label}
      </Typography>
    </Card>
  );
}

export default MenuConfigSelectionTile;
