import clsx from "clsx";
import { useState, memo, useEffect } from "react";
import { Helmet } from "react-helmet";

import { Icon } from "@iconify/react";
import { Card, Typography, Grid, CardMedia } from "@material-ui/core";
import arrowIosBack from "@iconify-icons/eva/arrow-back-fill";

// Internal Imports
import MenuProfile from "./MenuProfile";
import MenuChildViewNew from "./MenuChildViewNew";
import placeholder_item from "../../../images/placeholder_item.png";
import MenuSelectTile from "../MenuSelectTile";
import { makeStyles } from "@material-ui/core/styles";
import ScrollToTopValue from "~/views/common/ScrollToTopValue";

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    // height: "328px",
    padding: ".5rem",
    cursor: "pointer",
    boxShadow:
      "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px"
    // "&:hover": {
    //   boxShadow:
    //     "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px"
    // }
  },
  card: {
    textDecoration: "none"
  },
  cardMediaWrap: {
    paddingTop: "100%",
    position: "relative"
  },
  cardMedia: {
    top: 0,
    width: "100%",
    height: "100%",
    position: "absolute",
    borderRadius: ".5rem"
  },
  cardContent: {
    padding: "0.5rem 0 1rem 0"
  },
  images: {
    borderRadius: ".5rem",
    [theme.breakpoints.up("md")]: {}
  },
  categoryNames: {
    lineHeight: 0,
    marginTop: ".4rem"
  }
}));

function MultipleMenuChoiceView({
  slugResponseData,
  menus,

  conf,
  iden,
  idenl,
  showCartPage,
  handleShowCart
}) {
  const classes = useStyles();
  const [selectedMenu, setSelectedMenuMenu] = useState({});
  const { profile } = slugResponseData;

  useEffect(() => {
    ScrollToTopValue(0);
  }, [selectedMenu]);

  const getCategoriesImagesView = (firstFourCategories, showDownArrow) => {
    const listOfImages = [];
    if (firstFourCategories.length === 1) {
      listOfImages.push(
        <Grid item xs={6}>
          <img
            className={classes.images}
            src={
              firstFourCategories[0].image
                ? firstFourCategories[0].image
                : placeholder_item
            }
            width={showDownArrow ? "80px" : "105px"}
            height={showDownArrow ? "80px" : "105px"}
            style={{ objectFit: "cover" }}
          />
        </Grid>
      );
      return listOfImages;
    }

    firstFourCategories.map(category =>
      listOfImages.push(
        <Grid item xs={6} key={category.id}>
          <img
            className={classes.images}
            src={category.image ? category.image : placeholder_item}
            width={showDownArrow ? "40px" : "100%"}
            height={showDownArrow ? "40px" : "80px"}
            style={{ objectFit: "cover" }}
          />
        </Grid>
      )
    );

    if (listOfImages.length != 4) {
      const noOfImagesToAdd = 4 - listOfImages.length;
      for (let index = 0; index < noOfImagesToAdd; index++) {
        listOfImages.push(
          <Grid item xs={6} key={index}>
            <img
              className={classes.images}
              src={placeholder_item}
              width={showDownArrow ? "40px" : "100%"}
              height={showDownArrow ? "40px" : "80px"}
              style={{ objectFit: "cover" }}
            />
          </Grid>
        );
      }
    }
    return listOfImages;
  };

  const getMenuTile = (menu, onClick, showDownArrow = false) => {
    const firstFourCategories = menu.categories.slice(0, 4);
    if (showCartPage) {
      return null;
    }

    if (showDownArrow) {
      return (
        <Grid
          key={menu.id}
          onClick={onClick}
          item
          xs={12}
          style={{ width: "700px", margin: "auto" }}
        >
          <Card
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              height: "80px",
              cursor: "pointer",
              paddingLeft: "12px",
              borderRadius: "5px",
              boxShadow: "#A5D6A7  0px 0px 8px 0px"
            }}
          >
            <Icon icon={arrowIosBack} width={25} height={25} />
            <section style={{ maxWidth: "230px" }}>
              <Typography
                variant="h6"
                // sx={{ color: `#${menu.primary_colour}` }}
              >
                {menu.label}
              </Typography>
              {menu.remarks ? (
                <Typography variant="caption">{menu.remarks}</Typography>
              ) : (
                <div className={classes.categoryNames}>
                  {firstFourCategories.map((category, index) => (
                    <Typography key={category.id} variant="caption">
                      {category.label}
                      {index !== firstFourCategories.length - 1 ? ", " : null}
                    </Typography>
                  ))}
                </div>
              )}
            </section>
            <section
              style={{
                display: "flex",
                flexWrap: "wrap",
                width: "80px"
              }}
            >
              {getCategoriesImagesView(firstFourCategories, showDownArrow)}
            </section>
          </Card>
        </Grid>
      );
    }

    return (
      <Grid key={menu.id} item xs={6} sm={3} onClick={onClick}>
        <Card className={classes.root}>
          <Grid container spacing={0.5}>
            {getCategoriesImagesView(firstFourCategories)}
          </Grid>
          <section style={{ maxWidth: "230px", padding: "0.4rem " }}>
            <Typography variant="h6">{menu.label}</Typography>
            {menu.remarks ? (
              <Typography variant="caption">{menu.remarks}</Typography>
            ) : (
              <div className={classes.categoryNames}>
                {firstFourCategories.map((category, index) => (
                  <Typography key={category.id} variant="caption">
                    {category.label}
                    {index !== firstFourCategories.length - 1 ? ", " : null}
                  </Typography>
                ))}
              </div>
            )}
          </section>
        </Card>
      </Grid>
    );
  };

  if (JSON.stringify(selectedMenu) != "{}") {
    return (
      <>
        <div style={{ height: "75px" }}></div>
        {getMenuTile(
          selectedMenu,
          () => {
            // cleanCartItems(menus[0].branch[0].slug);
            setSelectedMenuMenu({});
          },
          true
        )}
        <MenuChildViewNew
          slugResponseData={slugResponseData}
          menu={selectedMenu}
          conf={conf}
          iden={iden}
          idenl={idenl}
          showCartPage={showCartPage}
          handleShowCart={handleShowCart}
        />
      </>
    );
  }

  return (
    <div
      style={{ boxShadow: "none", marginTop: "2rem", marginBlockEnd: "3rem" }}
    >
      <Helmet>
        <meta name="theme-color" content="#00AB55" />
      </Helmet>
      <MenuProfile profile={profile} />

      <Card sx={{ borderRadius: ".5rem", padding: "1rem 1rem 2rem 1rem" }}>
        <Typography sx={{ mb: 2 }} variant="h4" align="center">
          Select Your Favourite Menu
        </Typography>
        <Grid container spacing={1.5}>
          {menus.map(menu =>
            getMenuTile(menu, () => {
              // cleanCartItems(menus[0].branch[0].slug);
              setSelectedMenuMenu(menu);
            })
          )}
        </Grid>
      </Card>

      {/* <ExampleMenuFooter /> */}
    </div>
  );
}

export default memo(MultipleMenuChoiceView);
