import React from "react";
import { MButton } from "~/@material-extend";
import { makeStyles } from "@material-ui/core/styles";
import ReplayIcon from "@mui/icons-material/Replay";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useHistory } from "react-router";
// ----------------------------------------------------------------------

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    display: "flex",
    justifyContent: "flex-end",
    margin: "1rem 0 .4rem 0",
    padding: "0 .4rem"
  },
  viewMenuButton: {
    whiteSpace: "nowrap",
    boxShadow: "none"
  }
}));

// ----------------------------------------------------------------------

function ViewAndRepeatOrder({
  slug,
  branch,
  config,
  primaryColor,
  handleShowCart
}) {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div className={classes.root}>
      {/* <MButton
        className={classes.viewMenuButton}
        onClick={() => handleShowCart(true)}
        // onClick={() => {
        //   history.push({
        //     pathname: "/place-order",
        //     state: {
        //       branch: branch,
        //       config: config,
        //       primaryColor: primaryColor,
        //       placeOrder:true,
        //       orderRepeat: true
        //     }
        //   });
        // }}
        type="button"
        color="primary"
        variant="contained"
        size="small"
      >
        <ReplayIcon fontSize="small" />
        &nbsp; Repeat Order
      </MButton> */}

      <MButton
        className={classes.viewMenuButton}
        onClick={() => {
          history.push({
            pathname: `status/${slug}`,
            state: {
              config: config,
              primaryColor: primaryColor
            }
          });
        }}
        type="button"
        color="primary"
        variant="contained"
        size="small"
      >
        View Order &nbsp;
        <ArrowForwardIosIcon fontSize="small" />
      </MButton>
    </div>
  );
}

export default ViewAndRepeatOrder;
