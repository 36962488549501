import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Carousel from "react-material-ui-carousel";
import Paper from "~/theme/overrides/Paper";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function MenuItemMultipleImages({ open, handleClose, imagesData }) {
  function ItemMultipleImagesCarousel() {
    if (imagesData.length) {
      return (
        <Carousel
          cycleNavigation
          duration={700}
          indicators={true}
          animation="swipe"
          stopAutoPlayOnHover
          activeIndicatorIconButtonProps={{
            style: {
              color: "#FFF",
              backgroundColor: "#00AB55", // 2
            },
          }}
        >
          {imagesData.map((image, i) => (
            <Item key={i} image={image} />
          ))}
        </Carousel>
      );
    }

    function Item({ image }) {
      return (
        // <Paper
        //   style={{ cursor: "pointer" }}
        //   onClick={() => {
        //     console.log("IMAGE PE CLICK");
        //     setImagesModalOpen(true);
        //   }}
        // >
        <img
          src={image}
          className="lazyload blur-up"
          style={{
            width: "-webkit-fill-available",
            //   height: getImageHeight(),
            objectFit: "cover!important",
            backgroundRepeat: "no-repeat!important",
            backgroundPosition: "center!important",
            backgroundSize: "cover!important",
          }}
        />
        // </Paper>
      );
    }

    return (
      <img
        src={placeholder_item}
        className="lazyload blur-up"
        style={{
          width: "-webkit-fill-available",
          //   height: getImageHeight(),
          objectFit: "cover!important",
          backgroundRepeat: "no-repeat!important",
          backgroundPosition: "center!important",
          backgroundSize: "cover!important",
        }}
      />
    );
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ItemMultipleImagesCarousel />
        </Box>
      </Modal>
    </div>
  );
}

export default MenuItemMultipleImages;
