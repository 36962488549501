import axios from "axios";
import LocalServices from "~/services/LocalServices";
import MyUrlServices from "~/services/UrlServices";

const OCTAP_TOKEN = process.env.REACT_APP_OCTAP_TOKEN;

const headers = () => {
  const headers = {
    headers: {
      "Content-Type": "application/json",
      OctapToken: `${OCTAP_TOKEN}`
    }
  };
  return headers;
};

export default class MyWebServices {
  static async getHeaders() {
    const token = LocalServices.getToken();
    return {
      Authorization: token
    };
  }

  static async getHeadersWithJson() {
    const token = LocalServices.getToken();
    return {
      Authorization: token,
      "Content-Type": "application/json"
    };
  }

  // static async getBranches() {
  //   const url = await MyUrlServices.getBranchBase();
  //   const headers = await this.getHeadersWithJson();
  //   return await axios.get(url.toString(), { headers });
  // }

  // static async postBranchCreate(data) {
  //   const url = await MyUrlServices.getBranchBase("create");
  //   const headers = await this.getHeadersWithJson();
  //   return await axios.post(url.toString(), data, { headers });
  // }

  // static async getEmployees() {
  //   const url = await MyUrlServices.getEmployeeBase();
  //   const headers = await this.getHeadersWithJson();
  //   return await axios.get(url.toString(), { headers });
  // }

  // static async postFormCreate(data) {
  //   const url = await MyUrlServices.getFormBase("create");
  //   const headers = await this.getHeadersWithJson();
  //   return await axios.post(url.toString(), data, { headers });
  // }

  static async placeFoodOrder(tenant, data) {
    const url = await MyUrlServices.getFoodOrderBase(tenant);
    return await axios.post(url.toString(), data);
  }

  // static async getMenus(branch, tenant) {
  //   const url = await MyUrlServices.getMenusBaseNoAuth(branch, tenant);
  //   return await axios.post(url.toString());
  // }

  static async getMenu(id, slug, tenant) {
    const url =
      id === 0
        ? await MyUrlServices.getMenuViewBaseSlugNoAuth(slug, tenant)
        : await MyUrlServices.getMenuViewBaseNoAuth(id, tenant);
    return await axios.get(url.toString());
  }

  static async getOrderStatus(id, tenant) {
    const url = await MyUrlServices.getOrderStatusBaseNoAuth(id, tenant);
    return await axios.get(url.toString());
  }

  // static async getMenuProfile(BranchId, tenant) {
  //   const headers = {
  //     Authorization: `token ef8cd2bc0c28333c47f76652374681b4d566cef8`,
  //     "Content-Type": "application/json"
  //   };
  //   const url = await MyUrlServices.getMenuProfileBase(BranchId, tenant);
  //   return await axios.get(url.toString(), { headers });
  // }

  static async postItemAnalytics(data, tenant) {
    const url = await MyUrlServices.postItemAnalyticsBase(tenant);
    return await axios.post(url.toString(), data);
  }

  static async sendOtp(otp, phone, branch) {
    const url = await MyUrlServices.getSendOtp(otp, phone, branch);
    return await axios.post(url.toString());
  }

  // static async signUp(tenant, data) {
  //   const url = await MyUrlServices.getSignUpBase(tenant);
  //   return await axios.post(url.toString(), data);
  // }

  // ***********************************************************************Node.js APIs************************************************************************

  static async getMenuNodeJs(id, slug) {
    const url =
      id === 0
        ? await MyUrlServices.getMenuViewBaseSlugNoAuthNodeJs(slug)
        : await MyUrlServices.getMenuViewBaseNoAuthNodeJs(id);
    return await axios.get(url, headers());
  }

  static async placeFoodOrderNodeJs(profileId, data) {
    const url = await MyUrlServices.getFoodOrderBaseNodeJs(profileId);
    return await axios.post(url.toString(), data, headers());
  }

  static async getOrderStatusNodeJs(slug) {
    const url = await MyUrlServices.getOrderStatusBaseNoAuthNodeJs(slug);
    return await axios.get(url.toString(), headers());
  }

  static async sendOtpNodeJs(countryCode, phone, otp) {
    const url = await MyUrlServices.getSendOtpNodeJs(countryCode, phone, otp);
    return await axios.post(url, {}, headers());
  }

  // ***********************************************************************Node.js APIs************************************************************************
}
